@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.DateRangeDropdownPicker__filterLabel {
  padding: 4px;
}

.DateRangeDropdownPicker__overlay {
  padding: 0;

  .rc-tooltip-inner {
    background-color: white;
    padding: 0;
  }

  .rc-tooltip-content {
    margin-top: 12px;
  }

  .DateRangeDropdownPicker__buttons {
    text-align: right;
    padding: 8px;
    button {
      min-width: 60px;
      font-size: 14px;

      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
