.FormEmailParsers__container {
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 8px;
  height: 100%;
  position: relative;
  min-height: 310px;
  border-top: 1px solid #dfdfdf;

  &.loading {
    opacity: 0.65;
  }

  &:not(:first-child) {
    margin-top: 12px;
  }

  &.isSelected {
    border-color: var(--ion-color-concord);
    border-width: 2px;
  }
}

.FormEmailParsers__timezone {
  font-size: 14px;
  margin-top: 8px;
  display: inline-block;

  &.updatedAt {
    margin-left: 14px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -8px;
      top: 3px;
      width: 2px;
      height: 15px;
      background: #e5e5e5;
    }
  }
}

.FormEmailParsers__submitButton {
  font-size: 14px !important;
  position: absolute;
  bottom: 14px;
  right: 14px;
}

.FormEmailParsers__loadingOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.FormEmailParsers__sellerRegexLabel {
  color: var(--ion-color-concord);
}

.FormEmailParsers__buyerRegexLabel {
  color: lightgreen;
}

.FormEmailParsers__docTypeRegexLabel {
  color: red;
}

.FormEmailParsers__timezoneWrapper {
}

.FormEmailParsers__toggleSelectButton {
  padding-right: 8px;
}
