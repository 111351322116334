@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.StartTimeForm__splitPaneContainer {
  .Resizer {
    margin: 0;
    margin-right: 0;
    opacity: 0.6;
    width: 6px !important;
    background-color: #b1b1b1;

    &:hover {
      background: transparent !important;
      background-color: var(--ion-color-concord) !important;
      width: 6px !important;
      transition: initial;
    }

    &.vertical {
      border-right: 0;
      &:hover {
        border-left: 0 solid var(--ion-color-concord);
        border-right: 0px solid transparent;
      }
    }
  }

  .Pane2 {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    margin-top: -10px;

    @include media-breakpoint-down(md) {
      margin-left: -10px;
      margin-top: 10px;
    }

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}

.StartTimeForm__createForm {
  border: 1px solid #e5e5e5;
  padding: 8px 0 0;
  border-radius: 4px;
  margin-right: 8px;
  // width: 100%;
}

.StartTimeForm__dropdown {
  &:hover {
    border: none;
  }

  .ConcordFormDropdownV2__input-container {
    position: absolute;
  }

  .ConcordFormDropdownV2__value-container {
    padding-left: 0 !important;
  }

  .ConcordFormDropdownV2__multi-value {
    display: none;
  }

  &.DropdownWithCustomChildren__container {
    width: initial;
  }

  &.timeCreateButton {
    display: inline-block;
    vertical-align: middle;
    background: transparent;
  }
}

.StartTimeForm__button {
  height: 29px;
}

.StartTimeForm__buttonLabel {
  font-size: 12px;
  vertical-align: middle;
  margin-left: 4px;
  margin-top: 4px;
}

.StartTimeForm__header {
  padding: 0 8px 12px;
}

.StartTimeForm__driversContainer {
  padding: 0;
  position: relative;
  border-top: 1px solid #b8b8b8;
  min-height: 328px;
  max-height: 328px;
  overflow: auto;
}

.StartTimeForm__calendarDialog {
  .modal-dialog {
    max-width: 340px !important;
    padding: 0;

    .modal-body {
      padding: 0;
    }
  }
}

.StartTimeForm__shortcutBadge {
  display: inline-block;
  margin-right: 4px;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  vertical-align: middle;
  margin-top: 4px;
}

.StartTimeForm__popover {
  &::part(content) {
    min-width: 320px;
  }
}

.StartTimeForm__datePicker {
  width: auto !important;
  margin-right: 4px;
}
