.AllowedHierarchySelector__container {
  // font-size: 13px;
  // padding: 8px 4px;
  // border: 2px dashed #e5e5e5;
  // border-radius: 4px;
  // width: 100%;
}

.AllowedHierarchySelector__labelGrid {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  position: relative;

  .error.form-text {
    position: absolute;
    bottom: -20px;
  }
}
