.popoverStatus {
	background-color: #ffffff30 !important;

}

.popoverStatus::part(content) {
  margin-left: 15% !important;
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}
