.ModalDownloadProgress__root {
  position: absolute;
  right: 12px;
  bottom: 8px;

  &.isExpanded {
    border: 1px solid #e5e5e5;
    min-width: 300px;
  }

  .ModalDownloadProgress__collapseIcon {
    position: absolute;
    top: -11px;
    z-index: 9999;
    right: -5px;
    font-size: 20px;
    cursor: pointer;
  }

  .ModalDownloadProgress__downloadIcon {
    font-size: 24px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      transform: scale(1.3);
    }

    .ModalDownloadProgress__badge {
      position: absolute;
      top: 0;
      right: -5px;
      width: 16px;
      height: 16px;
      background-color: red;
      color: white;
      font-size: 10px;
      text-align: center;
      font-weight: 600;
      border-radius: 50%;
    }
  }
}
