.PdfViewer__divider {
  width: 2px;
  height: 14px;
  background-color: rgb(119, 119, 119);
  margin-left: 12px;
}

.PdfViewer__controlPageContainer {
  text-align: center;
  display: flex;
  align-items: center;
  margin-left: 8px;

  .ZoomButton__container {
    margin-left: 12px;
  }
}

.PdfViewer__butlerBlock {
  &.ButlerBlock__container {
    height: 850px;
  }
}
