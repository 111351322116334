.ViewPDF__root {
  max-height: 500px;
  min-height: 500px;
  overflow: auto;

  .rpv-core__inner-pages {
    max-height: 500px !important;
  }

  .ViewPDF__header {
    display: flex;
    align-items: center;
    padding: 0 8px;

    .ViewPDF__divider {
      width: 2px;
      height: 16px;
      background-color: rgb(119, 119, 119);
      margin: 0 16px;
    }
  }
}
