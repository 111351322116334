.RegexDropdownField__suffixRegex {
  position: absolute;
  right: 5px;
  bottom: 5px;

  select {
    border: none;
    outline: none;
    color: black;
  }
}

.RegexDropdownField__suffixDropdownToggle {
  font-size: 13px !important;
  padding: 2px !important;
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;

  &::after {
    border-top: 5px solid !important;
    border-right: 5px solid transparent !important;
    border-left: 5px solid transparent !important;
    vertical-align: 0 !important;
  }
}

.RegexDropdownField__suffixDropdownMenu {
  .dropdown-item {
    font-size: 13px !important;

    &.selected {
      background-color: #e5e5e5;
    }
  }
}
