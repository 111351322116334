.OverlayCreateTag__container {
  // margin-top: 8px;
}

.OverlayCreateTag__field {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .form-label {
    width: 30%;
  }

  .form-control,
  .ConcordFormColorPicker_wrapInput {
    width: 70%;
  }
}

.OverlayCreateTag__footer {
  text-align: right;

  button {
    font-size: 14px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
