.TerminalFormOnboarding__terminalCard {
  font-size: 13px;

  & > .description {
    .name {
      font-size: 14px;
      font-weight: 600;
    }

    .address {
      color: grey;
      font-style: italic;
    }
  }

  &.isSelected {
    border-color: var(--ion-color-success);
    box-shadow: rgb(192 255 192) 0px 2px 8px 0px;
  }
}

.TerminalFormOnboarding__addIcon {
  margin: 8px 0;

  & > .icon {
    font-size: 24px;
    color: var(--ion-color-concord);
  }
}

.TerminalFormOnboarding__googleMap {
  margin-top: 8px;
  .mapElementContainer {
    height: 250px !important;
  }
  .MapView__containerElement {
    height: 100% !important;
  }
}

.TerminalFormOnboarding__companyInfo {
  margin-right: 8px;
}
