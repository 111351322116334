.InfoSection__container {
  font-size: 14px;
  margin-bottom: 4px;
  position: relative;

  .key {
    font-weight: 600;
  }

  .editIcon {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

.InfoSection__loading {
  margin-bottom: 8px;

  .item {
    margin-bottom: 4px;
  }
}
