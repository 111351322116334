.RegexpItem__container {
  font-size: 13px;
  padding: 0 8px 8px;
  border-bottom: 1px dashed #e5e5e5;
  position: relative;

  &:not(:first-child) {
    padding-top: 8px;
  }
}

.RegexpItem__editIcon {
  position: absolute;
  top: 0;
  right: 8px;
}
