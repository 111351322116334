.ReusableDatePicker__datePicker {
  display: block;
  width: 100%;
  position: relative;

  .react-datepicker__calendar-icon {
    position: absolute;
    top: 4px;
    right: 0;
  }
}

.ReusableDatePicker__dateInput {
  font-size: 14px !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  max-height: 41px;
}

.ReusableDatePicker__popperClassName {
  max-width: 230px;

  .react-datepicker__day-names,
  .react-datepicker__month {
    font-size: 13px;
  }
  .react-datepicker__header {
    padding: 8px !important;
    border-bottom-width: 0;
    background-color: white;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 14px;
    margin: 4px;
  }

  .react-datepicker__month-container {
    float: none !important;
  }

  .react-datepicker__children-container {
    margin: 12px 16px 16px;
    padding-left: 8px;
    padding-right: 8px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    color: var(--ion-color-concord);
  }
}
