.LoadGridCard__header {
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  padding: 8px 4px;
}

.LoadGridCard__checkbox {
  margin: 0 8px;
}

.LoadGridCard__buttonActions {
  // margin-left: 8px;
  // display: flex;
  // max-width: 620px;
  // overflow: auto;
  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */

  @media screen and (max-width: 1130px) and (min-width: 991px) {
    max-width: calc(100vw - 400px);
  }

  @media screen and (max-width: 991px) and (min-width: 960px) {
    max-width: calc(100vw - 125px);
  }

  @media screen and (max-width: 960px) {
    max-width: calc(100vw - 70px);
  }
}

.LoadGridCard__companyTopSection {
  display: flex;
  justify-content: space-between;
  padding: 8px;

  @media (max-width: 650px) {
    .CompanyInfo__container {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.LoadGridCard__headerButton {
  display: inline-block;
}
