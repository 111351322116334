.EditInlineDatePicker__container {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  height: 100%;
  font-size: 13px !important;
  color: #1090ff !important;
}

.EditInlineDatePicker__wrapper {
  height: 100%;

  .react-datepicker-wrapper {
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
  }
}
