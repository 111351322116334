.DatePickerWithTimeInput__timeInput {
  min-width: 120px;
}

.Storybook__DatePickerWithTimeInput__root {
  min-width: 300px;
}

.Storybook__DatePickerWithTimeInput__customCss {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
  padding: 8px;
  min-width: 200px;
}
