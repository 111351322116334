.OrderExtraCard__container {
  font-size: 13px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  padding-right: 86px;
  position: relative;
}

.OrderExtraCard__buttons {
  margin-top: 4px;
  position: absolute;
  right: 8px;
  top: 2px;
}
