.ConcordFormNumberRangeField__root {
  .ConcordFormLayout__label {
    display: block;
  }

  .form-control {
    display: inline-block;
    width: 60px;
  }

  .ConcordFormNumberRangeField__space {
    margin: 0 4px;
    font-size: 18px;
    font-weight: 600;
  }
}
