.TruckNumberSection__container {
  font-size: 16px;
  font-weight: 600;
}

.TruckNumberSection__overlay {
  padding: 0;
  opacity: 1;

  .popover-arrow {
    display: none !important;
  }

  .rc-tooltip-inner {
    background-color: transparent;
    padding: 0;
  }
}

.TruckNumberSection__tooltipOverlay {
  .popover-body {
    font-size: 13px;
  }
}
