.ImageLabelItem__companyAvatar {
  margin-right: 8px;
  padding: 12px 0;

  &.activeItem {
    .CompanyInfo__companyName {
      display: flex;
    }
  }

  @media (max-width: 556px) {
    .CompanyInfo__companyName {
      display: none;
    }
  }
}
