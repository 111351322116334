.BundleBadge__container {
  font-size: 13px;
  border: 1px solid #929292;
  border-radius: 2px;
  margin-right: 6px;
  margin-bottom: 6px;
  // display: inline-block;
  vertical-align: top;
  padding: 8px;
  position: relative;
  width: 100%;
  position: relative;
  // max-width: 280px;

  .loadingSpinner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(215, 215, 215, 0.85);
    z-index: 10;
  }

  // .badgeItem {
  //   display: inline-block;
  //   vertical-align: top;
  //   margin-right: 4px;
  //   margin-top: 4px;
  // }

  .removeIcon {
    position: absolute;
    right: -6px;
    top: -7px;
    z-index: 1;
    background-color: red;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .locationIcon {
    vertical-align: middle;
    font-size: 16px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    color: var(--ion-color-medium);
    margin-right: 2px;

    &.selected {
      color: #f15523;
      background-color: transparent !important;
    }
  }

  .autoWidthInput {
    display: inline-block;
    vertical-align: middle;

    input {
      background-color: white;
      color: black;
      outline: none;
      font-size: 12px;
      padding: 4px;
      padding-left: 2px;
      font-weight: 400;
      border-radius: 2px;
    }
  }

  .submitBundleIcon,
  .closeBundleInputs {
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    cursor: pointer;
    margin-left: 4px;
  }
}
