.ShowingPdfViewerContext__modal {
  .ViewPDF__root {
    max-height: unset;
    min-height: unset;
    height: 100%;

    .rpv-core__inner-pages {
      max-height: unset !important;
    }
  }
}
