.CompanyAvatar__container {
  display: flex;
  align-items: center;
  justify-content: center;

  .CompanyAvatar__wrapAvatar {
    border: 2px solid var(--ion-color-concord);
    border-radius: 50%;

    &.seller {
      border: 2px solid var(--ion-color-seller);
    }

    &.buyer {
      border: 2px solid var(--ion-color-buyer);
    }

    &.fleet {
      border: 2px solid var(--ion-color-fleet);
    }

    &.showImagePopup {
      cursor: pointer;
    }

    &.noBorder {
      border-width: 0;
    }

    .CompanyAvatar__avatar {
      width: 35px;
      height: 35px;
    }
  }

  .CompanyAvatar__wrapInitialName {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background: var(--ion-color-concord);
    color: #ececec;
    font-weight: 600;
    font-size: 14px;

    &.seller {
      background-color: var(--ion-color-seller);
    }

    &.buyer {
      background-color: var(--ion-color-buyer);
    }

    &.fleet {
      background-color: var(--ion-color-fleet);
    }

    &.missing {
      background-color: white;
      color: black;
    }

    .CompanyAvatar__initialName {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.StoryBook__CompanyAvatar__OverrideClasses {
  background-color: red;
}
