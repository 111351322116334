@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.CollectEmployeeInfo__container {
  .inputItemContainer {
    margin-bottom: 8px;

    .inputs {
      display: flex;
      align-items: center;

      .effectiveStart {
        margin-left: 8px;

        @include media-breakpoint-down(md) {
          min-width: 50%;
        }
      }
    }

    .hintText {
      margin-top: 4px;
      font-size: 11px;
      color: #4e4e4e;
    }
  }
}
