@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.CompanyPayrolls__workplacesContainer {
  // .CompanyTable__header {
  //   display: none;
  // }
  // .CompanyTable__filterRoot {
  //   display: none;
  // }

  .CompanyTable__container {
    margin: 0;
  }
}

.CompanyPayrolls__badgeCounter {
  margin-right: 4px;
}

.CompanyPayrolls__tooltip {
  .tooltip-inner {
    max-width: 400px;
    text-align: left;
  }
}

.CompanyPayrolls__signatoryForm {
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.CompanyPayrolls__paySchedules {
  margin: 0;
}

.CompanyPayrolls__workerBadges {
  display: inline-block;
  position: relative;
  font-size: 13px;
  vertical-align: middle;
  color: var(--ion-color-fleet);
  padding-right: 12px;
  &:not(:last-child) {
    &::after {
      position: absolute;
      content: '';
      top: 2px;
      right: 5px;
      width: 2px;
      height: 14px;
      background: #e5e5e5;
    }
  }
}

.PayrollsContainer__sliderContainer {
  margin: 0 48px 28px;

  @include media-breakpoint-down(md) {
    margin-top: 12px;
  }

  .rc-slider-mark-text {
    min-width: 120px;
    font-size: 13px;
    color: var(--ion-color-fleet);
  }

  .rc-slider-mark-text-active {
    color: var(--ion-color-fleet);
    font-weight: 600;
  }

  .rc-slider-track {
    background-color: var(--ion-color-success);
  }

  .rc-slider-dot,
  .rc-slider-handle {
    border-color: var(--ion-color-success);
  }
}
