@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.RTCompanyToolbar__container {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 0;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.RTCompanyToolbar__contextMenu {
  .ContextMenu__menuItem {
    max-height: 420px;
    overflow: auto;
  }
}

.RTCompanyToolbar__heading {
  align-items: center;
  display: flex;
  font-size: 19px;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.RTCompanyToolbar__headingIcon {
  margin-left: 4px;
  transform: rotate(90deg);
  color: #717171;
}

.RTCompanyToolbar__description {
  color: #6f767e;
  font-size: 14px;
  text-transform: none;
  margin-top: 4px;
}

.RTCompanyToolbar__chip {
  height: 24px;
  margin: 0;
  cursor: default;
}
