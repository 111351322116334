.ConcordFormMultipeNumberRange__root {
  .ConcordFormLayout__label {
    display: block;
  }
}

.ConcordFormMultipeNumberRange__numberRange {
  margin: 8px;
  margin-bottom: 20px;
}

.ConcordFormMultipeNumberRange__labelRoot {
  display: flex;
  align-items: center;
}

.ConcordFormMultipeNumberRange__removeNumberRange {
  margin-left: 8px;
}

.ConcordFormMultipeNumberRange__slider {
  display: inline-block;
  width: 90%;
}

.ConcordFormMultipeNumberRange__labelRange {
  color: rgb(162, 161, 161);
  font-size: 12px;
}
