.RTEditCellNumberField__container {
  width: 100%;
  outline: none;
  border: none;

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }
}
