.DocumentReview__container {
  min-width: 180px;
  margin: 4px;
  display: inline-block;
}

.DocumentReview__document {
  // style={{ textAlign: 'center', maxHeight: '209px', minWidth: '160px' }}
  text-align: center;
  // max-width: 209px;
  // min-width: 160px;
  padding: 0;
}

.DocumentReview__actionButtons {
  padding: 8px 0;
}

.DocumentReview__customViewContainer {
  width: 250px;
  padding-left: 8px;

  .DocumentReview__customViewImage,
  .DocumentReview__customViewPDF {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .DocumentReview__customViewImage {
    margin-left: 4px;
  }
}
