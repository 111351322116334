.ContainerFilterTooltipItem__container {
  font-size: 15px;
  list-style-type: disc;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &.isAllOption {
    opacity: 0.7;
    list-style-type: circle;

    .ContainerFilterTooltipItem__label {
      font-weight: normal;
    }
  }
}

.ContainerFilterTooltipItem__label {
  text-transform: uppercase;
  margin-right: 8px;
  font-weight: bold;
}

.ContainerFilterTooltipItem__value {
}
