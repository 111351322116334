.ContextMenu__container {
  cursor: pointer;
}

.ContextMenu__itemContainer {
  font-size: 14px;
  svg,
  ion-icon {
    margin-right: 8px;
  }
}

.ContextMenu__loadingItem {
  height: 16px;
  width: 16px;
}
