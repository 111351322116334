.TagLinkModal__container {
  border: 1px solid #e2e2e2;
  width: 176px;
  // margin-left: 16px;
  background-color: #fafafa;
  position: relative;
  max-height: 150px;
  overflow: auto;

  .TagLinkModal__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e5e5e552;
  }
}
