.RTTableFooterCell__container {
  vertical-align: top;

  color: #64748b;
  padding: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }
}
