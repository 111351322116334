.CommonDialog__container {
  background-color: rgba($color: #000000, $alpha: 0.6);
  .headerContainer {
    &.isHeaderAddedPaddingOnMobile {
      padding-top: calc(env(safe-area-inset-top) + 8px);
    }

    .headerTitle {
      font-size: 15px;
    }
  }
}
