.qty-loads-input {
  display: flex;
  align-items: center;
  flex-direction: row;
  .form-floating {
    .form-control {
      width: 80px;
    }
  }
}
input:disabled {
  background-color: #f1f1f154;
}
