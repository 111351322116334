.CompanyChip__container {
  display: inline-block;
  vertical-align: middle;

  &.hasAvatar {
    .CompanyAvatar__wrapAvatar {
      border: none !important;
    }
  }

  .CompanyAvatar__initialName {
    font-size: 12px;
  }
}
