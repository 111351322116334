.ConcordFormMultipleCheckbox__container {
  .form-check {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.Storybook__ConcordFormMultipleCheckbox__root {
  .ConcordFormLayout__label {
    color: green;
    font-weight: 600;
  }
}
