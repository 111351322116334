.ConcordFormDropdownV2__dropdown-indicator {
  padding: 0 !important;
  margin-right: 4px;
}

.ConcordFormDropdownV2__indicator-separator {
  display: none;
}

.ConcordFormDropdownV2__control {
  min-height: 30.5px !important;
  height: 30.5px !important;
}

.ConcordFormDropdownV2__value-container {
  padding: 0 !important;
  padding-left: 4px !important;
}

.ConcordFormDropdownV2__menu {
  font-size: 13px;
}

.ConcordFormDropdownV2__clear-indicator {
  padding: 0 !important;
}