.ConcordFormDateRangePicker__root {
  .DateRangeDropdownPicker__filterLabel {
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: text;

    .DateRangeDropdownPicker__labelButton {
      width: 100%;
    }
  }
}
