.ReusableButton__container {
  position: relative;

  &.isButtonDisabled {
    opacity: 0.785;
    cursor: not-allowed;
  }

  .ReusableButton__spinner {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ReusableButton__tooltip {
  .tooltip-inner {
    text-align: left;
  }
}
