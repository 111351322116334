.DialogDatePicker__dialog {
  --width: 350px;
  --height: 450px;

  .DialogDatePicker__calendar {
    width: 100%;

    .rdrMonth {
      width: 100%;
    }
  }
}
