.CompanyInfo__container {
  display: flex;
  align-items: center;
  font-size: 14px;

  .CompanyInfo__info {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
  }
}

.CompanyInfo__companyName {
  display: flex;
  align-items: center;
}
