.FloatingSelectV2_root {
  background-color: #f1f1f154;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1.5px solid lightgray;
  border-radius: 4px;
  // min-width: 200px;

  .form-control {
    font-size: 13px;
  }
}

.FloatingSelectV2_text {
  color: #0000009e;
  font-weight: 500;
  border-right: 1.5px solid lightgray;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 8px;
  background-color: #f1f1f1;
  padding-left: 8px;
  min-height: 38px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
  position: relative;

  .asterisk {
    position: absolute;
    top: 32%;
    right: 2px;
  }
}

.FloatingSelectV2_line {
  width: 1.5px;
  height: 100%;
  background-color: lightgray;
}
