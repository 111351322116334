.HeaderCompanyChip__initialNameAvatar {
  font-size: 10px;
  // background-color: blue;
  // padding: 5px;
  border-radius: 50%;
  margin-right: 4px;
  font-weight: 600;
  color: white;
  min-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.seller {
    background-color: var(--ion-color-seller);
  }

  &.buyer {
    background-color: var(--ion-color-buyer);
  }

  &.fleet,
  &.driver_fleet {
    background-color: var(--ion-color-fleet);
  }
}

.HeaderCompanyChip__scopeBadge {
  font-size: 11px !important;

  &.buyer {
    background-color: var(--bs-primary) !important;
  }
  &.seller {
    background-color: var(--bs-dark) !important;
  }
  &.fleet {
    background-color: var(--bs-purple) !important;
  }
}
