.RangeSelectFilter__numberLabelField {
  text-align: center;
}

.RangeSelectFilter__numberField {
  width: 100%;
  background-color: #dbdbdb;
  border: none;
  height: 35px;
  outline: none;
  padding-left: 8px;
}
