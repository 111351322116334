.OrderExtrasDialog__list {
}

.OrderExtrasDialog__item {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
  padding: 6px;
  font-size: 13px;
  border-radius: 4px;

  &.selected {
    border-color: var(--ion-color-concord);
  }
}
