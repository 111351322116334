@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.SocialMediaField__textField {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    margin-bottom: 8px;
  }

  .ConcordFormLayout__container {
    width: 95%;
  }

  .icon {
    @include media-breakpoint-up(md) {
      margin-right: 8px;
    }
  }
}
