.TimeRange__container {
  label {
    font-size: 14px;
    margin-right: 4px;
  }

  input {
    padding: 4px 8px;
  }
}
