.KeyValueFormCustomField__wrap {
  // padding-left: 6px;
  width: 100%;
  border-radius: 4px;

  &.error {
    border: 1px solid red;
    color: black;
  }
}
