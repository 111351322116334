.RTTableBodyCell__container {
  text-overflow: clip;

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.canEdit {
    &:hover {
      background-color: white;
      border: 1px solid var(--ion-color-concord);
    }
  }

  &.hasError {
    background-color: #f6685e !important;

    &:hover {
      border-width: 0;
    }
  }
}
