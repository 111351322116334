.OrderTrackingCard__container {
  position: relative;

  .progress-bar {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 4px;
  }

  .OrderTrackingCard__body {
    border-radius: 4px;
    background-color: white !important;
    border: 1px solid #e5e5e5;

    .CompanyAvatar__initialName {
      font-size: 14px;
    }

    .header {
      font-size: 14px;
      padding: 12px;
      border-top: none;
      border: none;
      border-radius: 4px;
      width: 100%;
      cursor: pointer;

      .title {
        font-size: 16px;
      }

      .description {
        color: #797979;

        .tags {
          margin-top: 4px;
          .item {
            position: relative;

            &.status {
              font-weight: 600;
            }

            &:not(:last-child) {
              padding-right: 16px;
              &::after {
                position: absolute;
                content: '';
                top: 1px;
                right: 6px;
                width: 2px;
                height: 16px;
                background-color: #e5e5e5;
              }
            }
          }
        }
      }

      .tags {
        .badge {
          margin-top: 8px;
          margin-right: 8px;
          cursor: pointer;
          font-size: 11px;

          ion-icon,
          ion-label {
            vertical-align: middle;
          }

          ion-icon {
            margin-right: 4px;
          }
        }
      }
    }

    .body {
      .tabsContainer {
        .nav-link {
          // border-right: 0;
          // border-left: 0;
          // border-radius: 0;
        }
      }
    }
  }

  .OrderTrackingCard__row {
    display: flex;
    flex-direction: row;
  }

  .rowItem {
    display: flex;
    flex-direction: row;
  }

  .statusCard {
    display: flex;
    border: 1px solid black;
    padding: 0px 4px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }

  .OrderTrackingStatusCard__container {
    .CompanyAvatar__initialName {
      font-size: 9px !important;
    }
  }
}
