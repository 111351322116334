.SellerProductForm__container {
}

.SellerTerminalProductsSection__container {
  .dropdownSelector {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 4px;
    padding-left: 8px;
    margin-top: 8px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      width: 4px;
      height: 22px;
      background-color: red;
    }
    .headingLabel {
      font-size: 16px;
      margin-left: 6px;
      margin-right: 8px;
      font-weight: 600;
    }
  }

  .bundleItemContainer {
    .formContainer {
      position: relative;
      margin-left: 4px;
      padding-left: 8px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 11px;
        width: 4px;
        height: 22px;
        background-color: red;
      }

      .dropdown-toggle {
        background-color: transparent !important;
        border: none !important;
      }

      .headingLabel {
        font-size: 16px;
        margin-left: 6px;
        margin-right: 8px;
        font-weight: 600;
      }
    }
  }
}

.SellerTerminalProductCard__container {
  font-size: 13px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 8px;
  margin-left: 8px;
  cursor: pointer;

  &.isSelected {
    background-color: var(--ion-color-darkice);
  }

  &.Inactive {
    background-color: lightgray;
    opacity: 0.875;
  }

  span {
    vertical-align: middle;
  }

  .terminalName {
    margin-right: 8px;
  }

  .productStatus {
    margin-right: 8px;
    background-color: var(--ion-color-concord);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    padding: 4px;

    &.Inactive {
      background-color: var(--ion-color-crimson);
    }
  }

  .deleteIcon {
    height: 14px;
    display: inline-block;
    vertical-align: middle;
  }
}

.DialogSellerProductForm__container {
  .modal-dialog {
    max-width: 620px;
  }
}

.SellerProductForm__keyValuePair {
  padding: 0;
  .keyValueItem {
    font-size: 13px;
    margin-bottom: 6px;

    .keyValueLabel {
      font-weight: 600;
    }
  }
}

.SellerProductForm__mixDetailSection {
  border-top: 1px dashed #e5e5e5;
  padding-top: 8px;

  .headerContainer {
    margin-bottom: 8px;
    .titleHeading {
      font-size: 18px;
      font-weight: 600;
      vertical-align: middle;
    }

    .icon {
      font-size: 20px;
      height: 26px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  .mixDetailFormContainer {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-bottom: 8px;
  }
}

.DialogSellerProductForm__headerContainer {
  display: flex;
  align-items: center;

  .CompanyInfo__container {
    border-right: 1px solid #d5d5d5;
    padding-right: 8px;
  }
}
