$prefix: 'DocumentName';

.DocumentName__textContainer {
  font-size: 16px;
  font-weight: 600;
  width: 75%;
}

.DocumentName__formContainer {
  width: 75%;
  margin-right: 12px;
}

.DocumentName__formInput {
  width: 100%;
}
