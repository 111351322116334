.TicketModal__container {
  .modal-dialog {
    transition: all 0.3s ease-in-out !important;
  }

  &.fullWidth {
    .modal-dialog {
      max-width: 100vw !important;
      margin-right: auto;
      margin-left: auto;
      padding: 0;
    }
  }
}
