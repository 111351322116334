.ContainerSearchBar__container {
  position: relative;
  display: inline-block;
}

.ContainerSearchBar__badge {
  position: absolute;
  right: 0;
  top: -8px;
}

.ContainerSearchBar__searchBar {
  position: relative;

  .react-search-field-input {
    padding-right: 20px !important;
  }
}

.ContainerSearchBar__clearIcon {
  position: absolute;
  right: 38px;
  top: 8px;
  font-size: 14px;
}
