.SentEmailCard__container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 16px 0;
  position: relative;

  .counter {
    position: absolute;
    top: -6px;
    right: -6px;
    font-size: 9px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    .status {
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      &.open {
        background-color: #d4edda;
        color: var(--bs-green);
      }
      &.closed {
        background-color: #f8d7da;
        color: var(--bs-danger);
      }
    }
  }

  .email-details {
    margin-top: 12px;
    p {
      margin: 4px 0;
      font-size: 14px;
      color: #555;

      strong {
        color: #333;
      }
    }
  }

  .email-content {
    margin: 16px 0;
    padding: 12px 16px;
    background-color: #f9f9f9;
    border-left: 4px solid #007bff;
    font-style: italic;
    color: #555;
    font-size: 14px;

    p {
      margin: 0;
    }
  }

  .email-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .view-content {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }

    .cc-emails {
      font-size: 12px;
      color: #777;
    }
  }
}
