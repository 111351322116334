.FileField__container {
  flex: 1 1;
  display: flex;
  // flex-direction: column;
  align-items: center;
  padding: 8px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #c5c5c5;
  border-style: dashed;
  background-color: #fafafa;
  color: #838383;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  .FileField__placeholder {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.FileCard__container {
  width: 180px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  margin-right: 8px;
  border-radius: 4px;
  margin-top: 8px;

  .imageContainer {
    width: 100%;
    max-height: 150px;

    img {
      width: 100%;
      height: 150px;
    }
  }

  .pdfContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }

  .inputContainer {
    width: 100%;
    border-radius: 0;
    border-width: 0;
    outline: none;
    background-color: #e5e5e5;
    padding: 4px 8px;
  }

  .fileHeaderContainer {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e5e5e5;
  }
}
