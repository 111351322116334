.ConcordFormDropdown__dropdownContainer {
  overflow: hidden !important;

  .ConcordFormDropdown__toggleButton {
    font-size: 13px;
    width: 100%;
    background: transparent !important;
    border: 1px solid #ced4da !important;
    box-shadow: none;
    color: #000000 !important;
    min-height: 30px;
    text-align: left;
    position: relative;

    &:after {
      display: none;
    }

    &:hover {
      background-color: transparent;
      color: #000000;
    }

    &.error {
      border-color: red !important;
    }
  }

  .ConcordFormDropdown__menu {
    background-color: transparent;
    border: none;
    min-width: 220px;

    .ConcordFormDropdown__selectContainer {
      .ConcordFormDropdown__indicator {
        cursor: pointer;

        &.closeMenu {
          rotate: 180deg;
        }

        .ConcordFormDropdown__dropdownIndicator {
          padding: 0;
        }
      }
    }
  }
}

.ConcordFormDropdown__indicatorEditCompany {
  display: flex;
  align-items: center;
  padding: 0 4px;

  .ConcordFormDropdown__editCompanyIcon {
    font-size: 15px;
    margin-top: 5px;
  }
}

.Storybook__ConcordFormDropdown__root {
  .ConcordFormLayout__label {
    color: green;
  }
}

.ConcordFormDropdown__spinner {
  position: absolute;
  right: 0;
  top: 0;
}
