.TerminalMarker__infoboxContent {
  background-color: white;
  padding: 4px 8px;
  border-radius: 4px;

  .CompanyAvatar__initialName {
    font-size: 12px;
  }

  .terminalInfo {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;

    .code {
      font-size: 11px;
      margin-top: 2px;
    }
  }

  .address {
    margin-top: 6px;
    max-width: 150px;
    font-style: italic;
  }
}
