@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.ReciprocityInput__container {
  .jurisdiction,
  .effectiveStart {
    display: inline-flex;
    vertical-align: middle;
    font-size: 12px;

    .form-control {
      font-size: 14px;
    }

    .FloatingSelectV2_text {
      font-size: 12px;
    }
  }

  .jurisdiction {
    width: 40%;
  }

  .effectiveStart {
    margin-left: 8px;
    width: 55%;

    .FloatingSelectV2_text {
      min-width: 90px;
    }
  }

  .optIn {
    margin-top: 12px;
  }

  @include media-breakpoint-up(md) {
    .jurisdiction {
      max-width: 20%;
    }

    .effectiveStart {
      max-width: 30%;
    }
    .optIn {
      display: inline-flex;
      margin-left: 8px;
    }
  }
}
