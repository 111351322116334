.LoadForm__container {
  .alertContainer {
    margin-top: 8px;
    padding: 4px;
  }
}

.LoadForm__customDropdown {
  display: inline-block;
  width: 100px !important;
  vertical-align: middle;
}

.LoadForm__errorItem {
  color: var(--ion-color-concord);
  font-size: 12px;
  cursor: pointer;
  vertical-align: middle;
  &:hover {
    text-decoration: underline;
  }
}

.form-check-label {
  font-size: 14px;
}
