.NoteItem__container {
  font-size: 13px;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e6e6;
  }

  .header {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 4px;
    }

    .timestamp {
      color: #737373;
      // font-weight: 600;
      font-style: italic;
    }
  }

  .content {
    margin-top: 4px;
  }
}
