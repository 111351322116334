input[type="date"] {
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  max-height: 41px;
  border-radius: 4px;
  border-width: 1px;
}

input[type="time"] {
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  max-height: 41px;
  border-radius: 4px;
  border-width: 1px;
}

input:disabled {
  background-color: #f1f1f154;
}
