.KeyValueFormDropdownField__noneDropdown {
  border: none !important;
}

.KeyValueFormDropdownField__wrap {
  width: 100%;
  border-radius: 4px;

  &.error {
    border: 1px solid red;
    color: black;
  }
}
