.ConcordForm__label {
  font-size: 14px;
}

.Storybook__ConcordFormTextField__root {
  .form-control {
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 8px;
  }
}
