.NewTableRow {
  .BillLinesAddButton {
    font-weight: 800;
    font-size: 0.8em;
    margin: 0;
    padding: 0;
    width: 100%;
    color: var(--ion-color-concord);
  }
  .BillLinesInput {
    width: 80px;
  }
  .productName {
    width: 100%;
  }
  .ActionButton {
    display: flex;
    align-items: center;
    width: 38px;
    height: 28px;
  }
  .NumberButton {
    padding: 0 !important;
    margin: 0 !important;
  }
  .BillLinesColumn {
    display: 'none' !important;
  }
  .ion-card {
    justify-content: 'flex-start';
    align-items: 'center';
  }
}
.BillLinesTable {
}

.BillLinesTable
  ion-grid
  ion-row.EditableIonRow
  ion-card
  ion-col:nth-of-type(9) {
  max-width: 15rem !important;
  justify-content: flex-end;
}

@mixin CompareLine($color) {
  border: 1px solid var(--ion-color-#{$color});

  ion-grid {
    ion-row {
      background-color: var(--ion-color-#{$color}-light);

      ion-card {
        background: transparent;

        ion-col {
          position: relative;
          min-width: 5rem !important;
          cursor: default !important;
          color: var(--ion-color-#{$color});
          font-weight: 400;

          &:nth-of-type(2) {
            font-weight: 400;

            ion-text {
              color: var(--ion-color-#{$color});
            }
          }
          &:nth-of-type(9) {
            max-width: 10rem !important;
          }
        }
      }
    }
  }
}
