.SellerBuyerAvatar__initialName {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: var(--ion-color-concord);
  color: #ececec;
  font-weight: 600;
  font-size: 16px;

  &.buyer {
    background-color: lightgreen;
  }

  .SellerBuyerAvatar__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.SellerBuyerAvatar__container {
  display: flex;
  align-items: center;
}

.SellerBuyerAvatar__image {
  height: 40px;
  width: 40px;
  border: 2px solid var(--ion-color-concord);
  display: inline-block;

  &.buyer {
    border-color: lightgreen;
  }
}

.SellerBuyerAvatar__companyInfo {
  font-size: 13px;
  margin-left: 6px;
}

.SellerBuyerAvatar__companyType {
  color: var(--ion-color-concord);

  &.buyer {
    color: lightgreen;
  }
}
