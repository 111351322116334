.DropdownWithCustomChildren__container {
  height: 100%;
  width: 100%;

  &:hover {
    background-color: white;
    border: 1px solid #1090ff;

    .wrapChildren {
      color: black;
    }
  }

  &.noHover {
    &:hover {
      border: none;
      background-color: transparent;
    }
  }

  .ConcordFormDropdownV2__control {
    min-height: initial !important;
    height: 100% !important;
    background-color: transparent !important;
  }

  .wrapChildren {
    display: flex;
    align-items: center;
  }

  .ConcordFormDropdownV2__input-container {
    position: absolute;
  }
}
