.ConcordFormGroupStructure__container {
}

.ConcordFormGroupStructure__headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  padding: 0 6px 6px;
  border-bottom: 1px dashed #e1e1e1;
}

.ConcordFormGroupStructure__labelContainer {
  display: flex;
  align-items: center;
}

.ConcordFormGroupStructure__label {
  font-weight: 600;
}

.ConcordFormGroupStructure__badge {
  margin-left: 8px;
  display: none;
}
