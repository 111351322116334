.TablePhoneNumbers__toolbarRoot {
  margin-bottom: 8px;
  margin-left: 8px;
}


.TablePhoneNumbers__root {
  .DataGrid__row {
    border-bottom: none !important;
  }
}
