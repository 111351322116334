.RTTableHeadCell__container {
  background-color: white !important;
  color: rgb(100, 116, 139);
  padding: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  &.isPinned {
    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }
}
