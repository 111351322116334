@mixin filter-icon {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  font-size: 20px !important;
}

@mixin for-small-screen {
  @media (max-width: 650px) {
    @content;
  }
}

.ConcordToolbar__root {
  width: 100%;
  display: flex;
  align-items: center;

  //@include for-small-screen() {
  //  margin: 8px;
  //  display: block;
  //}

  .ContainerSearchBar__container {
    margin-top: 4px;
  }
}

.ConcordToolbar__horizontalFilter {
  border: 2px solid #d0d5dd;
  border-radius: 12px;
  &.active {
    border: 2px solid var(--ion-color-lightgreen);
  }
}

//.ConcordToolbar__wrapFilterSort {
//  width: 100%;
//  margin-top: 16px;

//  @include for-small-screen() {
//    margin-top: 0;
//  }
//}

.ConcordToolbar__rootSortOptions,
.ConcordToolbar__rootColumnOptions,
.ConcordToolbar__exportButton {
  //display: inline-block;

  &.focus {
    .btn-outline-primary {
      border-color: var(--ion-color-concord);
      color: var(--ion-color-concord);

      &:hover {
        color: var(--ion-color-concord);
      }
    }
  }

  &.active {
    .btn-outline-primary {
      border-color: var(--ion-color-lightgreen);
      color: var(--ion-color-lightgreen);

      &:hover {
        color: var(--ion-color-lightgreen);
      }
    }
  }

  .btn-outline-primary {
    border-radius: 0;
    color: #92949c;
    border-color: #d0d5dd;
    &:hover {
      background-color: transparent !important;
      color: #92949c;
    }

    ion-icon {
      @include filter-icon();
    }
  }
}

.ConcordToolbar__exportButton {
  .btn-outline-primary {
    border-color: var(--ion-color-export);
    &:hover {
      border-color: var(--ion-color-export);
    }
  }
}

//.ConcordToolbar__wrapSortColumnsOptions {
//  display: flex;
//  margin-left: 8px;
//  // min-width: 84px;
//}

.ConcordToolbar__rootHorizontalFilter {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.ConcordToolbar__verticalFilterButton {
  &.btn-outline-primary {
    border-color: #d0d5dd;
    color: #92949c;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: #92949c;
      border-color: #d0d5dd;
    }

    &.active {
      background-color: transparent;
      border-color: var(--ion-color-lightgreen);
      color: var(--ion-color-lightgreen);
    }

    .ConcordToolbar__verticalFilterIcon {
      @include filter-icon();
    }
  }
}

.ConcordToolbar__wrapFilterSort {
  border: 2px solid var(--ion-color-export);
  border-radius: 12px;
  margin: 0px 4px;
  padding: 1px;

  .btn-outline-primary {
    border: none;
    border-radius: 12px;
    padding: 4px 8px;

    &:hover {
      background-color: #fbfbfb;
      border-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }

    ion-icon {
      color: #92949c;
      margin-top: 4px;
      font-size: 20px !important;
    }
  }

  .ContainerFilter__buttonGroup {
    border: 1px solid #cfcfcf;
    box-shadow: none !important;

    &:not(:last-child) {
      border-right: none;
    }

    &.export-button {
      border: 1px solid var(--ion-color-export) !important;

      .Filter__icon {
        color: var(--ion-color-export);
      }
    }

    &.trigger {
      border: 1px solid var(--ion-color-lightgreen) !important;

      .Filter__icon {
        color: var(--ion-color-lightgreen);
      }
    }

    &.focus {
      border: 1px solid var(--ion-color-concord) !important;

      .Filter__icon {
        color: var(--ion-color-concord);
      }
    }
  }
}

.ConcordToolbar__wrapFilterSortAndExtra {
  width: 100%;
  display: flex;
  align-items: center;

  .exportButton {
    --bs-btn-active-bg: white;
    --bs-btn-hover-bg: white;
  }
}

.ConcordToolbar__container {
  display: inline-flex;
}
