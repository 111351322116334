.CustomScheduleStatusOption__container {
  color: black;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  &.text-light {
    .ConcordFormDropdownV2__option {
      color: white;
    }
  }

  .ConcordFormDropdownV2__option {
    background-color: transparent !important;
    color: black;
    cursor: pointer;
  }
}

.CustomMenuList__cursorInSearchField {
  height: 18px;
  width: 1px;
  background: black;
  z-index: 123;
  position: absolute;
  top: 14px;
  left: 12px;
  animation: cursor-blink 0.8s infinite;
}

@keyframes cursor-blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.SearchFieldWithCursor__container {
  .SearchFieldWithCursor__box {
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
  }
}
