.NotFoundPage__container {
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.NotFoundPage__bodyContainer {
  text-align: center;
  font-size: 24px;
}

.NotFoundPage__icon {
  font-size: 48px;
  color: red;
}
