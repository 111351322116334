.ConcordFormTagsInput__inputContainer {
  font-size: 14px;
  height: auto;
  padding: 4px;

  &.token-input-container.token-input-container--focused {
    border-color: #86b7fe;
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  .token-input-token {
    height: 28px !important;
  }

  input {
    min-width: 120px;
    &::placeholder {
      color: #d4d1d1 !important;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: #d4d1d1 !important;
    }
  }
}
