.TruckInfoBox__truckName {
  background-color: #1090ff;
  opacity: 1;
  padding: 2px 4px;
  border-radius: 8px;
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  font-weight: bold;
  border: 2px solid black;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.19);
}

.TruckInfoBox__truckInfo {
  background-color: white;
  border: 1px solid #e5e5e5;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px;
  min-width: 200px;
  position: relative;

  .closeIcon {
    position: absolute;
    font-size: 16px;
    top: 0;
    right: 6px;
  }

  .driverInfoContainer {
    .driverAvatarAndName {
      background-color: var(--ion-color-concord);
      border-radius: 50%;
      margin-right: 8px;

      .driverInitialName {
        width: 20px;
        height: 20px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
      }
    }
  }
}
