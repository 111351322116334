.NotesButton__container {
  .dropdown-toggle {
    background: transparent;
    box-shadow: none;
    border: none;
    color: black;
    font-size: 20px;
    padding: 0;

    &:after {
      display: none;
    }

    ion-icon {
      vertical-align: middle;
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
  }
}
