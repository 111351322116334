.BuyerSellerForm__container {
}

.BuyerSellerForm__radioContainer {
  border: 1px solid #e5e5e5;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 8px;
  cursor: pointer;

  // &:hover {
  //   opacity: 0.65;
  // }

  &.isSelected {
    border-color: var(--ion-color-concord);
  }
}

.BuyerSellerForm__searchbar {
  padding: 0;

  .searchbar-input {
    padding-inline-start: 35px;
    padding-inline-end: 0px;
  }

  .searchbar-search-icon {
    inset-inline-start: 0;
  }
}

.BuyerSellerForm__companyList {
  font-size: 13px;
  margin-top: 8px;
  max-height: 320px;
  overflow: auto;
}
