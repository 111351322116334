@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.ConfirmationProvider__dialog {
  @include media-breakpoint-up(md) {
    .modal-dialog {
      width: 300px;
    }
  }

  .modal-content {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }

  .contentMessage {
    font-size: 14px;
  }

  .buttonsContainer {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 4px;
      font-size: 13px;
    }
  }
}

.ConfirmationProvider__input {
  font-size: 13px;
  margin-top: 8px;
  width: 100%;
  padding: 4px;
  outline: none;
}
