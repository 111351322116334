.CommonDialog__content {
  padding: 16px;
  height: 300px;
}

.CommonDialog__header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    calc(env(safe-area-inset-bottom) - 10px) env(safe-area-inset-left);
}

.CommonDialog__title {
  margin-left: 12px;
  font-weight: normal !important;
  letter-spacing: 0.25px;
  color: rgb(66, 66, 66);
}

.CommonDialog__icon {
  display: flex;
  align-items: center;
}
