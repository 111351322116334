.SelectionField__containerModal {
  .modal-body {
    max-height: 350px;
    overflow: auto;
  }

  .modal-content {
    max-width: 350px;
  }

  .modal-dialog {
    justify-content: center;
  }
}
