.TesseractTextSection__section {
  padding: 0 8px;
}

.TesseractTextSection__regex {
  background: var(--highlight-text-background);
}

.TesseractTextSection__regexSearchVal {
  background: yellow;
}

.TesseractTextSection__regexContent {
  border-bottom: 1px dashed rgb(156, 156, 156);
  padding: 4px;
  font-size: 13px;
  max-height: 250px;
  overflow: auto;
}
