@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

$mobile-width: 600px;

@mixin for-small-screen {
  @media (max-width: $mobile-width) {
    @content;
  }
}

.DataGridFooter__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  position: relative;
}

.DataGridFooter__actionButtons {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;

  &.collapsed {
    background-color: transparent;
    z-index: 0;
  }

  .DataGridFooter__collapseExpand,
  .DataGridFooter__buttonActions {
    height: 100%;
  }

  .DataGridFooter__collapseExpand {
    .DataGridFooter__collapseExpandIcon {
      transition: all 0.2s ease-in-out;

      &.isOpenButtons {
        transform: rotate(180deg);
      }
    }
  }

  .DataGridFooter__buttonActions {
    overflow: auto;
    position: relative;
    width: 100%;
    padding-bottom: 0;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .DataGridFooter__toolbarSelectedRows {
      margin-top: 4px;
      margin-left: 4px;
      transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

      position: absolute;
      z-index: 999999;
      top: 0;
      left: 0;

      &.collapsed {
        left: -3000px;
        background: transparent;
      }
    }
  }

  .DataGridFooter__buttonActions::-webkit-scrollbar {
    display: none;
  }

  .DataGridFooter__collapseExpand {
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    opacity: 0.456;

    &:hover {
      opacity: 1;
    }
  }
}

.DataGridFooter__rightSection {
  font-size: 12px;
  font-weight: bold;

  &.isOpenActionButton {
    padding-left: 16px;
  }

  @include for-small-screen() {
    display: none;
  }
}

.DataGridFooter__leftSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include for-small-screen() {
    width: 100%;
  }
}

.DataGridFooter__perPage {
  // margin-right: 8px;
  .dropdown-toggle {
    font-size: 12px;
  }
}

.DataGridFooter__paginate {
  @include for-small-screen() {
    &.isOpenActionButton {
      padding-left: 16px;
    }
  }

  .Pagination__container {
    font-size: 12px;
  }
}
