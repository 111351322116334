.PdfViewer__pdfContainer {
  display: flex;
  justify-content: center;
  overflow: auto;
  max-height: 360px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .react-pdf__Page__canvas {
    width: 100% !important;
  }
}

.DocumentPdfViewer__header {
  text-align: center;
  display: flex;
  align-items: center;
  margin-left: 8px;

  .ZoomButton__container {
    margin-left: 12px;
  }
}

.DocumentPdfViewer__divider {
  width: 2px;
  height: 14px;
  background-color: rgb(119, 119, 119);
  margin-left: 12px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.PdfViewer__pdfContainer::-webkit-scrollbar {
  display: none;
}
