.LoadFormScheduleSelect__container {
  &.hasSchedule {
    .accordion-button {
      padding: 0;

      &:after {
        display: none;
      }
    }

    .LoadFormScheduleSelect__scheduleContainer {
      width: 100%;
    }
  }
}

.LoadFormScheduleSelect__scheduleContainer {
  border: 1px solid var(--ion-color-concord);
  background-color: #e7f1ff;
  padding: 8px;
  border-radius: 4px;
  font-size: 13px;
  color: black !important;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerLabel {
      font-weight: 600;
    }
  }

  .bodyContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .footerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.LoadFormScheduleSelect__orderForm {
  .modal-content {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
}
