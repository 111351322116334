.Button__container {
  position: relative;

  .badgeContainer {
    position: absolute;
    right: -8px;
    top: -6px;
    font-size: 10px;
    background: red;
    color: white;
    padding: 2px 4px;
    border-radius: 14px;
  }
}
