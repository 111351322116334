@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.ContainerSubtab__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 2px;

  @include media-breakpoint-down(xl) {
    padding: 16px 8px !important;
    padding-top: 0 !important;
  }
}

.ContainerSubtab__item {
  border-left: 1px solid #d0d5dd;
  border-top: 1px solid #d0d5dd;
  border-bottom: 1px solid #d0d5dd;
  color: #344054;
  background-color: #ffffff;
  font-size: 14px;
  text-transform: capitalize;
  padding: 8px;

  &.active {
    text-transform: capitalize;
    color: #f9fafb;
    background-color: var(--ion-color-concord);
  }
}

.ContainerSubTab__dropdownContainer {
  width: 100%;
  font-size: 14px;
  min-width: 200px;
}
