.NoteButton__overplay {
  padding: 0 !important;
  opacity: 1;

  form {
    textarea {
      font-size: 13px;
      padding: 4px;
      border: none;
      outline: none;
      box-shadow: none;
    }
    button {
      width: 100%;
      font-size: 13px;
      margin-top: 4px;
    }
  }
}

.NoteButton__dot {
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: var(--ion-color-danger);
  top: 2px;
  right: 2px;
  border-radius: 50%;
}

.NoteButton__menuContainer {
  ul {
    padding: 0;
  }
}
