.StaticMenu__collapseArrow {
  position: absolute;
  top: 50%;
  right: -12px;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;
  opacity: 0.625;
  font-size: 20px;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}
