@media all and (device-width: 768px) and (device-height: 1024px) {
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }

  .order-item ion-card-title {
    font-size: 20px;
  }

  .conversation-list ion-card-title {
    font-size: 17px;
  }

  .terminals-setting h2 {
    font-size: 18px;
  }

  ion-label {
    font-size: 16px;
  }
}

@media all and (max-device-width: 480px) {
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  .order-item ion-card-title {
    font-size: 20px;
  }

  .conversation-list ion-card-title {
    font-size: 17px;
  }

  ion-label {
    font-size: 16px;
  }
}
