.FormatOptionSellerLabel__container {
  display: flex;
  align-items: center;

  &.notSet {
    .FormatOptionSellerLabel__avatar {
      background-color: transparent;
    }
  }
}

.FormatOptionSellerLabel__avatar,
.FormatOptionSellerLabel__name {
  width: 4rem;
  height: 4rem;
  white-space: nowrap;
  border-width: 1px;
  display: inline-block;
}

.FormatOptionSellerLabel__avatar {
  background-color: white;

  &.notSet {
    background-color: transparent;
  }
}

.FormatOptionSellerLabel__name {
  text-align: center;
  line-height: 4rem;
  background-color: lightgreen;
  font-size: 15px;
  color: white;
}

.FormatOptionSellerLabel__label {
  padding-left: 12px;
  max-width: 180px;
  display: inline-block;
  vertical-align: middle;
}
