.ImageView__imageContainer {
  position: relative;
  cursor: pointer;
  margin-right: 4px;
}

.ImageView__imageOverlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImageView__content {
  padding: 0 !important;
}

.ImageView__button {
  margin: 0;
  margin-top: 4px;
  margin-right: 4px;
}
