.DataSorTool__savingData {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  // border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: none;
  padding-bottom: 4px;
  background-color: white;
}

.DataSortTool__wrapReorderCheckbox {
  min-width: 300px;
}

.DataSortTool__reorderWrap {
  z-index: 999;
  width: 280px;

  // ion-button {
  //   --background: transparent !important;
  //   --box-shadow: none !important;
  //   --color: black;
  // }

  .ReoderCheckbox__arrowIcon {
    --padding: 0 !important;
    // width: 30px;
    height: 24px;
  }
}

.DataSortTool__wrapContainer {
  position: relative;
}
