.BundleItemsOverlay__overlay {
  padding: 0;
  opacity: 1;

  .popover-arrow {
    display: none !important;
  }

  .rc-tooltip-arrow {
    display: none;
  }

  .rc-tooltip-inner {
    background-color: white;
    padding: 0;
    box-shadow: none;
    border: 1px solid #e5e5e5;

    form {
      textarea {
        font-size: 13px;
        padding: 4px;
        border: none;
      }
      button {
        width: 100%;
        font-size: 13px;
      }
    }
  }
}

.BundleItemsOverlay__popover {
  min-width: 480px;

  .popover-body {
    padding: 8px;
  }
}
