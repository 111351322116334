.RTEditCellTextField__container {
  width: 100%;
  outline: none !important;
  border: none !important;
  max-height: initial !important;
  border-radius: 0 !important;

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }
}
