.RTTableBodyRow__container {
  font-size: 13px;
  border-bottom: 1px solid rgb(224, 224, 224);
  color: rgb(17, 24, 39);
  font-weight: 500;
  background-color: rgb(255, 255, 255);

  &:hover {
    background-color: #f4f4f4;
  }

  &.isRowSelected {
    background-color: #f4f4f4;
  }

  &.isHighlighted {
    // border: 1px solid #e5e5e5 !important;
    background-color: #cde8ff;
  }
}
