.LoadPrint__signatureBox {
  transition: all 0.2s linear;
  border: 1px solid #e5e5e5;
  &:hover {
    background-color: #e5e5e5;
  }

  &.hasSignature {
    border-width: 0;
  }
}

.LoadPrint__signalNameTextbox {
  margin-top: 4px;

  .form-control {
    border: none;
  }
}

.LoadPrint__orderFormDialog {
  .modal-content {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
}

.EditableTimeCell__cellValue {
  position: relative;

  .EditableTimeCell__deleteIcon {
    position: absolute;
    top: -2px;
    right: 4px;
  }
}
