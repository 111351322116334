.SellerTerminalProductsList__container {
  font-size: 13px;
  .dropdownLabel {
    width: 265px;
  }

  .terminalDropdown {
    &:hover {
      border: none;
    }
  }

  .mapContainer {
    .MapView__containerElement {
      height: 250px;
    }
  }
}
