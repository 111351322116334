.BreadcrumbDot__container {
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.BreadcrumbDot__infoBox {
  background-color: white;
  border: 1px solid #e5e5e5;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px;
  min-width: 200px;
  position: relative;
}
