.DatePicker__dialog {
  .modal-dialog {
    max-width: 360px !important;
    padding: 0;

    .modal-body {
      padding: 0;
    }
  }
}
