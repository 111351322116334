
.colorPickerInput_input {
	width: 64px;
	height: 22px;
	border-radius: 2px;
	border: 1px solid black;
}

.popupClass {
  background-color: transparent;
}

.sketchPicker {
  width: auto !important
}
