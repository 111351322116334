// .DocumentsTableView__expandRow {
//   height: 100%;
//   width: 100%;
//   padding: 16px;
//   background-color: #e5e5e5;
// }

.newRow {
  background-color: rgba(102, 181, 250, 0.3);
}

.DocumentsTableView__initialName {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: var(--ion-color-concord);
  color: #ececec;
  font-weight: 600;

  .DocumentsTableView__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.DocumentsTableView__actionsColumn {
  width: 100%;
  display: flex;
  align-items: center;

  .icon {
    height: 18px;
  }
}

.DocumentsTableView__actionColumnIcon {
  font-size: 17px;
  cursor: pointer;

  &.splitIcon {
    color: red;
  }

  &.expandIcon {
    color: blue;
  }
}

.DocumentsTableView__menuIcon {
  font-size: 23px;
  color: var(--ion-color-concord);
  cursor: pointer;
}

// .checkbox {
//   transition: all 0.5s ease-in;
//   opacity: 0;
// }

// .checkbox:hover {
//   transition: all 0.1s ease-in;
//   opacity: 1;
// }

.SellerCol__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DocumentsTableView__avatar {
  width: 35px;
  height: 35px;
}

.DocumentsTableView__tooltip {
  .tooltip-inner {
    max-width: 280px;
    text-align: left;
  }
}

.BaseTable__row {
  &:hover {
    .DocumentTableView__orderNumberCell {
      display: none;
    }

    .DocumentTableView__checkboxCell {
      display: block;
    }
  }
}

.DocumentTableView__orderNumberCell {
  margin-left: 4px;
}

.DocumentTableView__checkboxCell {
  display: none;

  &.isSelected {
    display: block;
  }
}

.DocumentTableView__splitInvoiceButton {
  .tooltip-inner {
    min-width: 350px;
  }
}
