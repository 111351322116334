@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.CompanyHomeContainer__container {
  .CompanyHomeContainer__searchBar {
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .CompanyHomeContainer__itemsContainer {
    margin: 24px;
  }
}

.CompanyHomeContainer__anchor {
  text-decoration: none;
}

.CompanyHomeContainer__menuItem {
  @include media-breakpoint-down(md) {
    border-radius: 0;
    border-bottom: 1px solid #e5e5e5;
    min-height: initial;
  }
}

.CompanyHomeContainer__sortIcons {
}
