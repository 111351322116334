.PdfSplittingView__container {
  &:not(:first-child) {
    margin-top: 12px;
  }

  &:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
  }

  &.isDeleted {
    .PdfSplittingView__headerLeft,
    .PdfSplittingView__body {
      opacity: 0.35;
      pointer-events: none;
    }
  }
}

.PdfSplittingView__header {
  margin-bottom: 6px;
  // border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  padding-bottom: 4px;
}

.PdfSplittingView__headerLeft {
  display: flex;
  align-items: center;
}

.PdfSplittingView__headerRight {
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 14px;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.PdfSplittingView__hideChildPDFs {
  font-size: 13px;
}

.PdfSplittingView__label {
  margin-left: 8px;
}

.PdfSplitting__arrowIcon {
  font-size: 14px;
  padding: 4px;
  border: 1px solid var(--ion-color-concord);
  color: var(--ion-color-concord);
  border-radius: 50%;
  cursor: pointer;
}
