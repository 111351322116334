.RTActionOcrButton__tooltip {
  padding: 0;
  opacity: 1;

  .popover-arrow {
    display: none !important;
  }

  .rc-tooltip-inner {
    background-color: white;
    padding: 0;
  }
}

.RTActionOcrButton__overlay {
  border: none;
  font-size: 13px;
  overflow: auto;
  max-height: 350px;
  min-height: 350px;
  min-width: 500px;
  .popover-body {
    padding: 8px;

    .ContainerSearchBar__container {
      margin-bottom: 8px;
    }
  }

  .jer-edit-buttons {
    display: none;
  }
}
