.RTEditCellDateField__container {
  &.ReusableDatePicker__dateInput {
    width: 100% !important;
    height: 100% !important;
    outline: none !important;
    border: none !important;
    max-height: initial !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding-left: 0 !important;

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }
  }
}
