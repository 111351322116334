.ButlerBlock__container {
  height: 100%;
  overflow: hidden;

  .FieldsPanelDisplayRow__root {
    padding: 0;
    padding-left: 8px;
  }

  .FieldsPanelDisplayRow__fieldActionContainer {
    align-items: center;
  }

  .DocumentPanelToolbar__zoomIcon {
    font-size: 20px;
  }

  .DocumentPanelToolbar__root {
    font-size: 16px;
  }
}
