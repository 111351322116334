// .CompanyForm__container {
//   background: white;
//   margin: 4px;
//   padding: 4px;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   border-radius: 8px;
// }

.CompanyCreateEditForm__addNewCompanyBtn {
  width: 100%;
}
