.ContainerFilter__container {
  display: inline-flex;
  border: solid 2px #d0d5dd;
  border-radius: 12px;

  &.active {
    border-color: var(--ion-color-lightgreen);
  }
}

.ContainerFilter__filterOverlay {
  .szh-menu {
    padding: 12px;
    background-color: #262626;
    color: white;
    opacity: 0.9;
  }
}

.ContainerFilter__wrapFilterItem {
  max-width: 300px;
  overflow: auto;
}

.ContainerFilter__exportButton {
  border: 2px solid var(--ion-color-export);
  border-radius: 10px;
  margin-right: 8px !important;
  font-size: 13px;
  padding: 4px;
}

.ContainerFilter__buttonGroupContainer {
  border: 2px solid var(--ion-color-export);
  border-radius: 12px;
  margin: 0px 4px;
  padding: 1px;

  .btn-outline-primary {
    border: none;
    border-radius: 12px;
    padding: 4px 8px;

    &:hover {
      background-color: #fbfbfb;
      border-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }

    ion-icon {
      color: #92949c;
      margin-top: 4px;
      font-size: 20px !important;
    }
  }

  .ContainerFilter__buttonGroup {
    border: 1px solid #cfcfcf;
    box-shadow: none !important;

    &:not(:last-child) {
      border-right: none;
    }

    &.export-button {
      border: 1px solid var(--ion-color-export) !important;

      .Filter__icon {
        color: var(--ion-color-export);
      }
    }

    &.trigger {
      border: 1px solid var(--ion-color-lightgreen) !important;

      .Filter__icon {
        color: var(--ion-color-lightgreen);
      }
    }

    &.focus {
      border: 1px solid var(--ion-color-concord) !important;

      .Filter__icon {
        color: var(--ion-color-concord);
      }
    }
  }
}

.sortFilters {
  .DataSortTool__reorderWrap {
    width: 280px;
    border: 1px solid #e5e5e5;
  }

  // .DataSorTool__savingData {
  //   justify-content: flex-start !important;
  //   div {
  //     padding: 0 !important;
  //   }
  // }

  .DataSorTool__savingData {
    width: 275px;
    border: none;
  }
}

.VerticalCommonFilter__container {
  margin-top: 8px;
  padding: 8px;

  .ContainerSearchBar__container {
    margin-left: 8px;
  }
}

.ContainerFilterV3__dropdownContainer {
  > .dropdown-menu {
    color: white;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 8px;
  }

  .dropdown-toggle {
    padding: 0;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;

    &::after {
      display: none;
    }
  }

  .dropdown {
    button {
      background: transparent !important;
    }
  }
}
