.PopupResultCheckout__container {
  .PopupResultCheckout__body {
    font-size: 16px;
    padding: 0;

    .PopupResultCheckout__content {
      margin: 0;
      max-width: 250px;
      text-align: center;

      .PopupResultCheckout__icon {
        font-size: 50px;
      }

      .PopupResultCheckout__button {
        font-size: 15px;
      }
    }
  }
}
