.SensorsList__container {
  .sensorItem {
    // transition: all 0.3s ease;

    &.isExpanded {
      display: block !important;
      width: 100%;
    }

    &.isHidden {
      display: none;
    }
  }
}

.SensorsList__chartHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SensorsList__topLeftChartHeader {
  display: flex;
  align-items: center;
  font-size: 13px;

  .backIcon {
    font-size: 22px;
    margin-right: 8px;
    margin-left: 4px;
    display: inline-block;
    height: 26px;
    vertical-align: middle;
  }
}

.SensorsList__dataChangesOnlyChk {
  .form-check-input {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
