.ImageZoom__container {
  .ImageZoom__buttonGroup {
    font-size: 16px;
    display: flex;
    align-items: center;

    .ImageZoom__checkboxButton {
      margin-right: 12px;
    }
  }

  .ImageZoom__zoomControl {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
