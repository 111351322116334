@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.hide-xs-down {
  @include media-breakpoint-down(xs) {
    display: none;
  }

  @include media-breakpoint-up(xs) {
    display: block;
  }
}
.hide-xs-up {
  @include media-breakpoint-down(xs) {
    display: block;
  }

  @include media-breakpoint-up(xs) {
    display: none;
  }
}

.hide-sm-down {
  @include media-breakpoint-down(sm) {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    display: block;
  }
}
.hide-sm-up {
  @include media-breakpoint-down(sm) {
    display: block;
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.hide-md-down {
  @include media-breakpoint-down(md) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}
.hide-md-up {
  @include media-breakpoint-down(md) {
    display: block;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hide-lg-down {
  @include media-breakpoint-down(lg) {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }
}
.hide-lg-up {
  @include media-breakpoint-down(lg) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.hide-xl-down {
  @include media-breakpoint-down(xl) {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    display: block;
  }
}
.hide-xl-up {
  @include media-breakpoint-down(xl) {
    display: block;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.underline {
  text-decoration: underline;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent;
  // display: none;
  /* for Chrome, Safari, and Opera */
}

.BaseTable__row-cell {
  overflow: inherit !important;
}

.BaseTable__row--depth-0 {
  height: 50px;
}

.BaseTable__row--depth-0 .BaseTable__row-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.BaseTable--dynamic .BaseTable__row {
  overflow: inherit !important;
}

.BaseTable__sort-indicator {
  display: none !important;
}

@mixin disabled {
  opacity: 0.585 !important;
  user-select: none !important;
  pointer-events: none !important;
}

.disabled {
  @include disabled();
}

.isDisabled {
  @include disabled();
}

.isReadOnly {
  user-select: none !important;
  pointer-events: none !important;

  .form-control {
    border-color: #f4f4f4 !important;
  }
}

.text-gray-out {
  opacity: 0.6 !important;
}

.editable {
  cursor: pointer;
}

.hide-scroll {
  overflow: hidden;
}

.hide-scroll-x {
  overflow-x: hidden;
}

.hide-scroll-y {
  overflow-y: hidden;
}

.icon {
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }

  &.disabled {
    @include disabled();
  }
}

.button-toggle {
  border: 2px solid #d0d5dd;
  border-radius: 12px;

  &.active {
    border-color: lightgreen;
    color: black;
  }

  &.focus {
    border-color: var(--ion-color-concord);
  }
}

.hide {
  visibility: hidden !important;
}

ion-icon {
  pointer-events: none;
}

.ion-icon-hide-title {
  pointer-events: none;
}

.error {
  color: red !important;
}

.color-red {
  color: red;
}

.color-green {
  color: var(--bs-green);
}

.red-ion-button {
  span {
    color: red;
  }
}

.bg-light-red {
  background-color: #ffd6d7;
}

.w-100 {
  width: 100%;
}

.remove-border-from-common-seller-dropdown {
  & > .ConcordFormDropdown__dropdownContainer {
    & > .ConcordFormDropdown__toggleButton {
      box-shadow: none !important;
      border: none !important;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.LoadingOverlay__root {
  background-color: rgba(251, 251, 251, 0.35);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rawData {
  background-color: #ffe8e8;
}

.menu-content-open {
  pointer-events: auto;
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.65;
  }
}

ion-select {
  width: unset;
  min-height: 46px;
}

.dropdown-item {
  &.isSelected {
    background-color: #e5e5e5 !important;
  }
}

.mobileApp {
  margin-bottom: calc(env(safe-area-inset-bottom) - 10px) !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.rcTooltip {
  padding: 0;
}

.no-border {
  border-width: 0 !important;
}

.isNotAllowed {
  cursor: not-allowed !important;
}

.isDraggable {
  cursor: move;
}

.hyperLink {
  color: var(--ion-color-concord);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.green-switch-toggle {
  .form-check-input {
    box-shadow: none !important;
    background-color: #dddddd !important;
    border-color: #dddddd !important;
    cursor: pointer;
    &:checked {
      background-color: var(--ion-color-buyer) !important;
      border-color: var(--ion-color-buyer) !important;
    }
  }
}

.selected {
  background-color: #e5e5e5 !important;
}

.greenBackground {
  background-color: var(--ion-color-lightgreen) !important;
}

.blueBackground {
  background-color: var(--ion-color-darkice) !important;
}

.grayBackground {
  background-color: var(--ion-color-medium) !important;
}

.redBackground {
  background-color: var(--ion-color-danger) !important;
}

.no-hover {
  &:hover {
    border: none;
  }
}

.stripeButton {
  &.btn-secondary {
    background-color: #5433ff;
    border: 1px solid #5433ff;

    &:hover {
      background-color: #4824f7;
    }
  }
}

// .btn {
//   &.btn-primary,
//   &.btn-secondary,
//   &.btn-danger,
//   &.btn-info {
//     svg {
//       fill: white;

//       path {
//         fill: white;
//       }
//     }
//   }
// }

.tooltip-inner {
  text-align: left;
}

.pull-content-in-tooltip-left {
  .tooltip-inner {
    text-align: left;
  }
}

.make-custom-dropdown-inline {
  width: auto !important;
  background-color: transparent !important;
  display: inline-block !important;
  vertical-align: middle;

  .ConcordFormDropdownV2__control {
    height: auto !important;
  }

  .ConcordFormDropdownV2__value-container {
    padding-left: 0 !important;
  }
}

.terminal-icon {
  font-weight: bold;
  color: white;
  opacity: 1;
  padding: 2px;
  border-radius: 15px;
  width: 20px;
  height: 20px;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;

  &.pickup {
    background-color: black;
  }

  &.dropoff {
    background-color: blue;
  }
}

.seller-terminal-chip-bg-color {
  background-color: #545454 !important;
}

.ReusableTable__pdfFormat {
  box-shadow: none;
  border: 1px solid var(--bs-black);
  border-radius: 0;
  border-bottom: 1px solid var(--bs-black);

  .RTTableHead__container {
    box-shadow: none;
  }

  .RTTableHeadRow__container {
    border-bottom-color: var(--bs-black) !important;
    color: white;
  }

  .RTTableHeadCell__container {
    color: white;
    background-color: var(--ion-color-concord) !important;
    font-size: 14px !important;
  }

  .RTTableHeadCellSortLabel__sortIcon {
    display: none;
  }

  .RTTableBodyCell__container {
    &:not(:last-child) {
      border-right: 1px solid var(--bs-black);
    }
  }

  .RTTableBodyRow__container {
    border-bottom-color: var(--bs-black);
  }
}
