@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.documents-dropdown-filter {
  .btn-group-lg > .btn,
  .btn-lg {
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    ion-icon {
      margin-right: 5px;
    }
  }
  .dropdown-item {
    font-size: 1.4rem;
    text-transform: none !important;
  }
  .popover-document {
    width: auto;
    top: 10px;
    left: 10px;
  }
}
.documents-buttonfilter-label {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.documents-sortby-container {
  // border: solid 2px #30BB50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 2px;
  padding-left: 1.25rem;
  position: relative;
}
.documents-sortby-filter {
  .btn-group-lg > .btn,
  .btn-lg {
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdown-item {
    font-size: 1.4rem;
    text-transform: none !important;
  }
  .popover-document {
    width: auto;
    top: 10px;
    left: 10px;
  }
}

.documents-container-clear {
  border: solid 2px #ff2f0f;
  border-radius: 12px;
  margin-left: 4px;
  color: #ff2f0f;
  padding: 2px;

  .save-button {
    color: var(--ion-color-concord);
    border-color: var(--ion-color-concord);

    ion-icon {
      margin-left: 0 !important;
    }
  }

  .save-filter-button {
    color: #2e2e2e;
    border-color: #d0d5dd;
    border-radius: 12px;

    ion-icon {
      margin-left: 0 !important;
    }

    &.isActive {
      color: var(--ion-color-concord);
      border-color: var(--ion-color-concord);
    }
  }
}

.DocumentList__container {
  position: relative;

  &.tableView {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.DocumentList__listContainer {
  position: relative;
}

.DocumentList__footer {
  position: absolute;
  bottom: 14px;
  width: 100%;
  background-color: white;
}

.DocumentList__wrapItem {
  padding-bottom: 70px;
}
