.BundleFormWithBadges__form {
  border: 1px solid #e5e5e5;
  margin-top: 8px;
  margin-bottom: 8px;
}

.BundleFormWithBadges__badges {
  margin-top: 8px;

  .label {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #646464;

    .icon {
      margin-left: 6px;
      vertical-align: middle;
      font-size: 18px;
      height: 24px;
      display: inline-block;
    }
  }
}

.BundleFormWithBadges__divide {
  width: 100%;
  height: 1px;
  margin-top: 6px;
  border-top: 1px dashed #363636;
}
