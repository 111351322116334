.DatePickerHeader__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

// .DatePickerHeader__selector {
//   font-size: 12px;
//   font-weight: 600;
//   border: none;
//   outline: none;
// }

.DatePickerHeader__buttons {
  margin-left: 24px;

  button {
    background-color: transparent;
  }
}

.DatePickerHeader__yearMonthSelectors {
  display: flex;

  .ConcordFormDropdownV2__control {
    border-width: 0;
    box-shadow: none;
  }

  .ConcordFormDropdownV2__indicators {
    display: none;
  }

  .ConcordFormDropdownV2__menu-list {
    width: 200px !important;
  }
}
