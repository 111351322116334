.RegexField__container {
  position: relative;
  width: 90%;
  height: 100%;

  &.error {
    border: 1px solid red !important;
  }

  .form-control {
    border: none;
    font-size: 14px;
    height: 100%;
  }
}
