.ScheduleLoadsRow__datePicker {
  width: 110px !important;

  .ReusableDatePicker__dateInput {
    height: 41px;
    font-size: 13px !important;
  }

  .react-datepicker__calendar-icon {
    top: 10px !important;
  }
}

.ScheduleLoadsRow__timeField {
  width: 90px !important;

  .ReusableTimeField__dateInput {
    height: 41px;
    font-size: 13px !important;
  }

  .react-datepicker__calendar-icon {
    top: 10px !important;
  }
}
