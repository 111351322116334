.DialogCompanyCreateEdit__container {
  --width: 650px;
  --height: 800px;

  .EditCompanyContainer__bodyContainer {
    height: 740px;

    .Body__menuSpace {
      display: none;
    }
  }
}
