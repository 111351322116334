/* CreditCard.css */

.StripePaymentMethodCard__container {
  width: 320px;
  height: auto;
  border-radius: 15px;
  color: white;
  padding: 20px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
  margin-top: 12px;
  margin-right: 12px;
  vertical-align: top;
  background: linear-gradient(135deg, #1090ff 0%, #0c70cc 100%);

  &.amex {
    background: linear-gradient(135deg, #3b7bbf 0%, #28527a 100%);
  }
  &.visa {
    background: linear-gradient(135deg, #1a1f71 0%, #0a0c5a 100%);
  }
  &.mastercard {
    background: linear-gradient(135deg, #eb001b 0%, #f79e1b 100%);
  }
  &.discover {
    background: linear-gradient(135deg, #ff6000 0%, #cc4e00 100%);
  }

  &.jcb {
    background: linear-gradient(135deg, #0069aa 0%, #00497c 100%);
  }
}

.StripePaymentMethodCard__number {
  font-size: 18px;
  letter-spacing: 3px;
  font-weight: 600;
}

.StripePaymentMethodCard__name {
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 600;
}

.StripePaymentMethodCard__expiry--label {
  font-size: 12px;
}

.StripePaymentMethodCard__expiry--date {
  font-size: 16px;
}

.StripePaymentMethodCard__logo {
  position: absolute;
  top: 20px;
  right: 20px;
}

.StripePaymentMethodCard__billing-address {
  margin-top: 2px;
  font-size: 14px;
  line-height: 1.5;
}
