.CompanyTerminalsCard__container {
  width: 100%;
}

.CompanyTerminalsCard__terminalForm {
  border-top: 1px dashed #e5e5e5;
  margin-top: 8px;
}

.CompanyTerminalsCard__terminal {
  border-color: #e5e5e5 !important;
  border-style: dashed !important;

  &.isSelected {
    & > .description {
      .name {
        color: var(--ion-color-concord);
      }
    }
  }

  & > .description {
    .name {
      font-size: 14px;
      font-weight: 600;
    }

    .address {
      font-size: 12px;
      color: grey;
      font-style: italic;
    }
  }
}

.CompanyTerminalsCard__googleMap {
  margin-top: 8px;
  .mapElementContainer {
    height: 250px !important;
  }

  .MapView__containerElement {
    height: 250px !important;
  }
}
