@use "sass:math";

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
// @import '~loaders.css/src/animations/ball-scale-multiple';

@font-face {
  font-family: ProximaNova;
  src: url('fonts/Proxima\ Nova.woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: ProximaNova;
  src: url('fonts/ProximaNovaSemibold.otf');
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 10px;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  height: 100%;
  margin: 0;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  -webkit-text-size-adjust: 100%;
}

// .wrap-ticket-pdf {
//   height: 450px;
//   ion-content {
//     height: 400px;
//   }
// }

.wrap-material-invoice-2,
.wrap-delivery-invoice-2 {
  ion-content {
    height: 310px;
  }
}

.wrap-material-invoice-3,
.wrap-delivery-invoice-3 {
  ion-content {
    height: 205px;
  }
}

ul.pagination {
  font-size: 10px;
  display: table;
  margin-bottom: 0;

  li.page-item {
    display: table-cell;

    &:first-child {
      &.disabled a {
        background-color: lightgrey;
        color: black;
      }

      a {
        background-color: #1090ff;
        color: white;
      }
    }

    &:last-child {
      &.disabled a {
        background-color: lightgrey;
        color: black;
      }

      a {
        background-color: #1090ff;
        color: white;
      }
    }
  }
}

.image-magnify {
  overflow-y: visible !important;
}

.small-image-magnify {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.parsed_container {
  height: 100% !important;
  --padding-start: 1.5em;
  --padding-end: 1.5em;
  --padding-top: 1.5em;
  --padding-bottom: 1.5em;
}

.paddingLeft15 {
  padding-left: 15px !important;
}

* {
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: black; // !important;
}

::-moz-placeholder {
  color: black; // !important;
}

::-ms-placeholder {
  color: black; // !important;
}

::placeholder {
  color: black; // !important;
}

:root {
  --ion-font-family: ProximaNova !important;

  --ion-color-concord: #1090ff;
  --ion-color-concord-rgb: 16, 144, 255;
  --ion-color-concord-contrast: #fff;
  --ion-color-concord-contrast-rgb: 255, 255, 255;
  --ion-color-concord-shade: #0e7fe0;
  --ion-color-concord-tint: #289bff;
  --ion-color-concord-light: #e8f1ff;

  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  --ion-color-primary-light: #e8f1ff;

  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  --ion-color-secondary-light: #e8f1ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  --ion-color-tertiary-light: #e8f1ff;

  --ion-color-fleet: #6f767e;
  --ion-color-fleet-rgb: 111, 118, 126;
  --ion-color-fleet-contrast: #ffffff;
  --ion-color-fleet-contrast-rgb: 255, 255, 255;
  --ion-color-fleet-shade: #62686f;
  --ion-color-fleet-tint: #7d848b;

  --ion-color-driver: #6f767e;
  --ion-color-driver-rgb: 111, 118, 126;
  --ion-color-driver-contrast: #ffffff;
  --ion-color-driver-contrast-rgb: 255, 255, 255;
  --ion-color-driver-shade: #62686f;
  --ion-color-driver-tint: #7d848b;

  --ion-color-pink: #fc94af;
  --ion-color-pink-rgb: 16, 144, 255;
  --ion-color-pink-contrast: #fff;
  --ion-color-pink-contrast-rgb: 255, 255, 255;
  --ion-color-pink-shade: #ce85b1;
  --ion-color-pink-tint: #9c78a8;
  --ion-color-pink-light: #e8f1ff;

  --ion-color-slate: #66737f;
  --ion-color-slate-rgb: 102, 115, 127;
  --ion-color-slate-contrast: #e9edf1;
  --ion-color-slate-contrast-rgb: 255, 255, 255;
  --ion-color-slate-shade: #5a6570;
  --ion-color-slate-tint: #75818c;

  --ion-color-darkslate: #435151;
  --ion-color-darkslate-rgb: 67, 81, 81;
  --ion-color-darkslate-contrast: #fff;
  --ion-color-darkslate-contrast-rgb: 255, 255, 255;
  --ion-color-darkslate-shade: #3b4747;
  --ion-color-darkslate-tint: #566262;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-darkgreen: #198754;
  --ion-color-darkgreen-rgb: 48, 130, 58;
  --ion-color-darkgreen-contrast: #fff;
  --ion-color-darkgreen-contrast-rgb: 255, 255, 255;
  --ion-color-darkgreen-shade: #2a7233;
  --ion-color-darkgreen-tint: #458f4e;

  --ion-color-royalblue: #0037ff;
  --ion-color-royalblue-rgb: 0, 55, 255;
  --ion-color-royalblue-contrast: #fff;
  --ion-color-royalblue-contrast-rgb: 255, 255, 255;
  --ion-color-royalblue-shade: #0030e0;
  --ion-color-royalblue-tint: #1a4bff;

  --ion-color-crimson: #fe3a42;
  --ion-color-crimson-rgb: 199, 0, 0;
  --ion-color-crimson-contrast: #fff;
  --ion-color-crimson-contrast-rgb: 255, 255, 255;
  --ion-color-crimson-shade: #af0000;
  --ion-color-crimson-tint: #cd1a1a;
  --ion-color-crimson-light: #f5eded;

  --ion-color-danger: #fe3a42;
  --ion-color-danger-rgb: 199, 0, 0;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #af0000;
  --ion-color-danger-tint: #cd1a1a;
  --ion-color-danger-light: #f5eded;

  --ion-color-red: #fe3a42;
  --ion-color-red-rgb: 199, 0, 0;
  --ion-color-red-contrast: #fff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #af0000;
  --ion-color-red-tint: #cd1a1a;
  --ion-color-red-light: #f5eded;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-medium-light: #f5eded;

  --ion-color-lavender: #d0afde;
  --ion-color-lavender-rgb: 208, 175, 222;
  --ion-color-lavender-contrast: #000;
  --ion-color-lavender-contrast-rgb: 0, 0, 0;
  --ion-color-lavender-shade: #b79ac3;
  --ion-color-lavender-tint: #d5b7e1;

  --ion-color-teal: #009a9a;
  --ion-color-teal-rgb: 0, 154, 154;
  --ion-color-teal-contrast: #fff;
  --ion-color-teal-contrast-rgb: 255, 255, 255;
  --ion-color-teal-shade: #088;
  --ion-color-teal-tint: #1aa4a4;

  --ion-color-orange: #ffa200;
  --ion-color-orange-rgb: 255, 140, 0;
  --ion-color-orange-contrast: #000;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #e07b00;
  --ion-color-orange-tint: #f7e2c9;
  --ion-color-orange-light: #f5f3ee;

  --ion-color-ice: #d7ecff;
  --ion-color-ice-rgb: 215, 236, 255;
  --ion-color-ice-contrast: #000;
  --ion-color-ice-contrast-rgb: 0, 0, 0;
  --ion-color-ice-shade: #bdd0e0;
  --ion-color-ice-tint: #dbeeff;

  --ion-color-darkice: #a5d4ff;
  --ion-color-darkice-rgb: 165, 212, 255;
  --ion-color-darkice-contrast: #000;
  --ion-color-darkice-contrast-rgb: 0, 0, 0;
  --ion-color-darkice-shade: #91bbe0;
  --ion-color-darkice-tint: #aed8ff;

  --ion-color-lightpurple: #efeaff;
  --ion-color-lightpurple-rgb: 239, 234, 255;
  --ion-color-lightpurple-contrast: #000;
  --ion-color-lightpurple-contrast-rgb: 0, 0, 0;
  --ion-color-lightpurple-shade: #d2cee0;
  --ion-color-lightpurple-tint: #f1ecff;

  --ion-color-lightred: #ffe7e7;
  --ion-color-lightred-rgb: 255, 231, 231;
  --ion-color-lightred-contrast: #000;
  --ion-color-lightred-contrast-rgb: 0, 0, 0;
  --ion-color-lightred-shade: #e0cbcb;
  --ion-color-lightred-tint: #ffe9e9;

  --ion-color-lightblue: #e7f3ff;
  --ion-color-lightblue-rgb: 231, 243, 255;
  --ion-color-lightblue-contrast: #000;
  --ion-color-lightblue-contrast-rgb: 0, 0, 0;
  --ion-color-lightblue-shade: #cbd6e0;
  --ion-color-lightblue-tint: #e9f4ff;

  --ion-color-lightyellow: #fcfc50;
  --ion-color-lightyellow-rgb: 245, 245, 184;
  --ion-color-lightyellow-contrast: #000000;
  --ion-color-lightyellow-contrast-rgb: 0, 0, 0;
  --ion-color-lightyellow-shade: #d8d8a2;
  --ion-color-lightyellow-tint: #f6f6bf;

  --ion-color-cloudgrey: #6f767e;
  --ion-color-cloudgrey-rgb: 111, 118, 126;
  --ion-color-cloudgrey-contrast: #ffffff;
  --ion-color-cloudgrey-contrast-rgb: 255, 255, 255;
  --ion-color-cloudgrey-shade: #62686f;
  --ion-color-cloudgrey-tint: #7d848b;

  --ion-color-purple: #b09fe0;
  --ion-color-purple-rgb: 176, 159, 224;
  --ion-color-purple-contrast: #000000;
  --ion-color-purple-contrast-rgb: 0, 0, 0;
  --ion-color-purple-shade: #9b8cc5;
  --ion-color-purple-tint: #b8a9e3;

  --ion-color-snowgrey: #d0d5dd;
  --ion-color-snowgrey-rgb: 208, 213, 221;
  --ion-color-snowgrey-contrast: #000000;
  --ion-color-snowgrey-contrast-rgb: 0, 0, 0;
  --ion-color-snowgrey-shade: #b7bbc2;
  --ion-color-snowgrey-tint: #d5d9e0;

  --ion-color-dark: #000;
  --ion-color-dark-rgb: 208, 213, 221;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #000;
  --ion-color-dark-tint: #000;

  --ion-color-export: #7044ff;
  --ion-color-export-rgb: 208, 213, 221;
  --ion-color-export-contrast: #ffffff;
  --ion-color-export-contrast-rgb: 0, 0, 0;
  --ion-color-export-shade: #7044ff;
  --ion-color-export-tint: #7044ff;

  --ion-color-lightgreen: #50c878;
  --ion-color-lightgreen-rgb: 209, 239, 211;
  --ion-color-lightgreen-contrast: #000;
  --ion-color-lightgreen-contrast-rgb: 0, 0, 0;
  --ion-color-lightgreen-shade: #a5dda9;
  --ion-color-lightgreen-tint: #68c16d;

  --ion-color-buyer: rgb(85, 210, 85);
  --ion-color-buyer-rgb: 9, 188, 57;
  --ion-color-buyer-contrast: #000;
  --ion-color-buyer-contrast-rgb: 0, 0, 0;
  --ion-color-buyer-shade: #4c7f50;
  --ion-color-buyer-tint: #62a365;

  --ion-color-seller: #1090ff;
  --ion-color-seller-rgb: 16, 144, 255;
  --ion-color-seller-contrast: #fff;
  --ion-color-seller-contrast-rgb: 255, 255, 255;
  --ion-color-seller-shade: #0e7fe0;
  --ion-color-seller-tint: #289bff;
  --ion-color-seller-light: #e8f1ff;

  --highlight-text-background: #ffa0a0;
}

.ion-color-concord {
  --ion-color-base: var(--ion-color-concord);
  --ion-color-base-rgb: var(--ion-color-concord-rgb);
  --ion-color-contrast: var(--ion-color-concord-contrast);
  --ion-color-contrast-rgb: var(--ion-color-concord-contrast-rgb);
  --ion-color-shade: var(--ion-color-concord-shade);
  --ion-color-tint: var(--ion-color-concord-tint);
}

.ion-color-seller {
  --ion-color-base: var(--ion-color-seller);
  --ion-color-base-rgb: var(--ion-color-seller-rgb);
  --ion-color-contrast: var(--ion-color-seller-contrast);
  --ion-color-contrast-rgb: var(--ion-color-seller-contrast-rgb);
  --ion-color-shade: var(--ion-color-seller-shade);
  --ion-color-tint: var(--ion-color-seller-tint);
}

.ion-color-buyer {
  --ion-color-base: var(--ion-color-buyer);
  --ion-color-base-rgb: var(--ion-color-buyer-rgb);
  --ion-color-contrast: var(--ion-color-buyer-contrast);
  --ion-color-contrast-rgb: var(--ion-color-buyer-contrast-rgb);
  --ion-color-shade: var(--ion-color-buyer-shade);
  --ion-color-tint: var(--ion-color-buyer-tint);
}

.ion-color-fleet {
  --ion-color-base: var(--ion-color-fleet);
  --ion-color-base-rgb: var(--ion-color-fleet-rgb);
  --ion-color-contrast: var(--ion-color-fleet-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fleet-contrast-rgb);
  --ion-color-shade: var(--ion-color-fleet-shade);
  --ion-color-tint: var(--ion-color-fleet-tint);
}

.ion-color-driver {
  --ion-color-base: var(--ion-color-driver);
  --ion-color-base-rgb: var(--ion-color-driver-rgb);
  --ion-color-contrast: var(--ion-color-driver-contrast);
  --ion-color-contrast-rgb: var(--ion-color-driver-contrast-rgb);
  --ion-color-shade: var(--ion-color-driver-shade);
  --ion-color-tint: var(--ion-color-driver-tint);
}

.ion-color-lightgreen {
  --ion-color-base: var(--ion-color-lightgreen);
  --ion-color-base-rgb: var(--ion-color-lightgreen-rgb);
  --ion-color-contrast: var(--ion-color-lightgreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgreen-shade);
  --ion-color-tint: var(--ion-color-lightgreen-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-slate {
  --ion-color-base: var(--ion-color-slate);
  --ion-color-base-rgb: var(--ion-color-slate-rgb);
  --ion-color-contrast: var(--ion-color-slate-contrast);
  --ion-color-contrast-rgb: var(--ion-color-slate-contrast-rgb);
  --ion-color-shade: var(--ion-color-slate-shade);
  --ion-color-tint: var(--ion-color-slate-tint);
}

.ion-color-darkslate {
  --ion-color-base: var(--ion-color-darkslate);
  --ion-color-base-rgb: var(--ion-color-darkslate-rgb);
  --ion-color-contrast: var(--ion-color-darkslate-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkslate-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkslate-shade);
  --ion-color-tint: var(--ion-color-darkslate-tint);
}

.ion-color-darkgreen {
  --ion-color-base: var(--ion-color-darkgreen);
  --ion-color-base-rgb: var(--ion-color-darkgreen-rgb);
  --ion-color-contrast: var(--ion-color-darkgreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkgreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkgreen-shade);
  --ion-color-tint: var(--ion-color-darkgreen-tint);
}

.ion-color-royalblue {
  --ion-color-base: var(--ion-color-royalblue);
  --ion-color-base-rgb: var(--ion-color-royalblue-rgb);
  --ion-color-contrast: var(--ion-color-royalblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-royalblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-royalblue-shade);
  --ion-color-tint: var(--ion-color-royalblue-tint);
}

.ion-color-crimson {
  --ion-color-base: var(--ion-color-crimson);
  --ion-color-base-rgb: var(--ion-color-crimson-rgb);
  --ion-color-contrast: var(--ion-color-crimson-contrast);
  --ion-color-contrast-rgb: var(--ion-color-crimson-contrast-rgb);
  --ion-color-shade: var(--ion-color-crimson-shade);
  --ion-color-tint: var(--ion-color-crimson-tint);
}

.ion-color-lavender {
  --ion-color-base: var(--ion-color-lavender);
  --ion-color-base-rgb: var(--ion-color-lavender-rgb);
  --ion-color-contrast: var(--ion-color-lavender-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lavender-contrast-rgb);
  --ion-color-shade: var(--ion-color-lavender-shade);
  --ion-color-tint: var(--ion-color-lavender-tint);
}

.ion-color-teal {
  --ion-color-base: var(--ion-color-teal);
  --ion-color-base-rgb: var(--ion-color-teal-rgb);
  --ion-color-contrast: var(--ion-color-teal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal-shade);
  --ion-color-tint: var(--ion-color-teal-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-ice {
  --ion-color-base: var(--ion-color-ice);
  --ion-color-base-rgb: var(--ion-color-ice-rgb);
  --ion-color-contrast: var(--ion-color-ice-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ice-contrast-rgb);
  --ion-color-shade: var(--ion-color-ice-shade);
  --ion-color-tint: var(--ion-color-ice-tint);
}

.ion-color-darkice {
  --ion-color-base: var(--ion-color-darkice);
  --ion-color-base-rgb: var(--ion-color-darkice-rgb);
  --ion-color-contrast: var(--ion-color-darkice-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkice-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkice-shade);
  --ion-color-tint: var(--ion-color-darkice-tint);
}

.ion-color-lightpurple {
  --ion-color-base: var(--ion-color-lightpurple);
  --ion-color-base-rgb: var(--ion-color-lightpurple-rgb);
  --ion-color-contrast: var(--ion-color-lightpurple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightpurple-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightpurple-shade);
  --ion-color-tint: var(--ion-color-lightpurple-tint);
}

.ion-color-lightred {
  --ion-color-base: var(--ion-color-lightred);
  --ion-color-base-rgb: var(--ion-color-lightred-rgb);
  --ion-color-contrast: var(--ion-color-lightred-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightred-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightred-shade);
  --ion-color-tint: var(--ion-color-lightred-tint);
}

.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}

.ion-color-lightyellow {
  --ion-color-base: var(--ion-color-lightyellow);
  --ion-color-base-rgb: var(--ion-color-lightyellow-rgb);
  --ion-color-contrast: var(--ion-color-lightyellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightyellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightyellow-shade);
  --ion-color-tint: var(--ion-color-lightyellow-tint);
}

.ion-color-cloudgrey {
  --ion-color-base: var(--ion-color-cloudgrey);
  --ion-color-base-rgb: var(--ion-color-cloudgrey-rgb);
  --ion-color-contrast: var(--ion-color-cloudgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cloudgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-cloudgrey-shade);
  --ion-color-tint: var(--ion-color-cloudgrey-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-snowgrey {
  --ion-color-base: var(--ion-color-snowgrey);
  --ion-color-base-rgb: var(--ion-color-snowgrey-rgb);
  --ion-color-contrast: var(--ion-color-snowgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-snowgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-snowgrey-shade);
  --ion-color-tint: var(--ion-color-snowgrey-tint);
}

.ion-color-export {
  --ion-color-base: var(--ion-color-export);
  --ion-color-base-rgb: var(--ion-color-export-rgb);
  --ion-color-contrast: var(--ion-color-export-contrast);
  --ion-color-contrast-rgb: var(--ion-color-export-contrast-rgb);
  --ion-color-shade: var(--ion-color-export-shade);
  --ion-color-tint: var(--ion-color-export-tint);
}

.loading-wrapper {
  bottom: 0;
  height: 50px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 50px;
  z-index: 999;
}

.ion-page {
  margin-bottom: env(safe-area-inset-bottom) !important;
  // margin-top: env(safe-area-inset-top) !important;
}

.fleet-modal {
  .ion-page {
    overflow: auto;
  }
}

.fixed-prices-modal {
  .ion-page {
    overflow: auto;
  }
}

// LINK //

.link {
  display: block;
  font-size: 1.5rem;
  margin-top: 1.5vh;
  text-align: center;
  text-decoration: none;
}

// INPUT //

.item {
  .required {
    display: none;
  }

  &.item-has-focus {
    .required {
      color: red;
      display: inline;
    }
  }

  .input-label {
    &.floating {
      color: grey;
      font-size: 1.4rem;
    }
  }

  input,
  textarea {
    &.native-input,
    &.native-textarea {
      font-size: 1.5rem;
    }
  }
}

ion-card.blue-top {
  border-top: 4px solid #1090ff;
}

ion-card.red-top {
  border-top: 4px solid rgb(207, 13, 13);
}

ion-card.purple-top {
  border-top: 4px solid #6f1bd4;
}

//.xxlarge-popover .popover-content {
//  width: 600px;
//}

.xxlarge-popover::part(content) {
  width: 600px;
}

//.xlarge-popover .popover-content {
//  width: 500px;
//}

.xlarge-popover::part(content) {
  width: 500px;
}

//.large-popover .popover-content {
//  width: 330px;
//}

.large-popover::part(content) {
  width: 330px;
}

//.medium-popover .popover-content {
//  width: 300px;
//}

.medium-popover::part(content) {
  width: 300px;
}

.validation-error {
  margin-top: 0;

  span {
    font-size: 1.15rem;
  }
}

.custom-select {
  .alert-radio-label.sc-ion-alert-md {
    white-space: normal !important;
  }
}

.borderedCol {
  border: 1px solid black;
  text-align: center;
}

.bgPrimary {
  background-color: #1090ff;
  color: white;
}

.buyer_filters {
  ion-button {
    padding-bottom: 0;
    margin: -7px 0;
  }
}

.active_filter {
  background-color: #c3cafd !important;
  border-radius: 12px !important;
  font-weight: bold !important;
  color: black !important;
}

.active_filter_blue {
  background-color: #1090ff !important;
  border-radius: 12px !important;
  font-weight: bold !important;
  color: white !important;
}

.active_filter_red {
  background-color: rgb(207, 13, 13) !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  color: white !important;
}

.active_filter_purple {
  background-color: #6f1bd4 !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  color: white !important;
}

.wrap-select-option {
  &:last-child {
    margin-bottom: -4px;

    .btn-add-item {
      display: flex !important;
    }
  }
}

.css-syji7d-Group {
  padding-bottom: 0 !important;

  .wrap-select-option {
    .btn-add-item {
      display: none !important;
    }

    &:last-child {
      .select-options {
        padding-bottom: 5px;
        border-bottom: 1px solid black;
      }
    }
  }

  &:last-child {
    .wrap-select-option {
      &:last-child {
        margin-bottom: -4px;

        .btn-add-item {
          display: flex !important;
        }
      }
    }
  }
}

.fixed-price-date-input {
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 100;

  span {
    font-size: 16px;
  }

  input {
    font-size: 16px;
    min-width: 16px;
  }
}

.pill {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.react-datepicker__input-container {
  input {
    color: black;
    font-size: 12px;
  }
}

.chatoverlayPanel {
  left: 0;
  height: auto;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  z-index: 199;
  text-align: center;
}

.mobilechatOverlay {
  // position: fixed;
  left: 0;
  height: auto;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  z-index: 199;
  text-align: center;
}

.dark ion-card,
.dark .ion-page,
.dark .inner-scroll {
  background-color: black;
}

.dark {
  ion-content {
    background: black !important;
  }

  :host(.ion-content) {
    background: black !important;
  }
}

.bottomReaction {
  ion-icon {
    font-size: 25px;
  }
}

.customMultiSelect {
  .gray,
  span,
  input {
    color: black !important;
    font-size: 12px;
  }

  .dropdown-content {
    height: auto !important;
    z-index: 9999;
    overflow-y: scroll;
  }
}

.badge-checkmark {
  background-color: #00da6c;
}

.badge {
  font-size: 16px;
}

.badge-close {
  background-color: red;
}

.preview-container {
  text-align: center;
  vertical-align: middle;
}

.css-1yn2e29-InputColor {
  width: 150px !important;
}

.rawDataCard {
  max-height: 450px !important;
  overflow-y: scroll !important;
}

.evenRow {
  background-color: antiquewhite !important;
}

.oddRow {
  background-color: aliceblue !important;
}

.evenRow ion-label,
.oddRow ion-label {
  font-weight: bold !important;
}

.bold {
  font-weight: bold !important;
  font-size: larger;
}

.Toastify__toast-container {
  overflow: visible !important;
  z-index: 1000 !important;
}

.Toastify__close-button {
  align-self: center !important;
}

.Toastify__progress-bar--info {
  background: #3880ff;
}

.Toastify__progress-bar--success {
  background: #30bb50;
}

.popover-content.sc-ion-popover-md {
  width: auto;
  height: auto;
}

.imagePreviewPopup .popover-content.sc-ion-popover-md,
.PdfPreviewPopup .popover-content.sc-ion-popover-md {
  top: 12px !important;
  left: 10% !important;
  right: 10% !important;
  width: 80% !important;
  height: 80% !important;
}

//.PdfPreviewPopup .modal-wrapper {
//  top: 1% !important;
//  left: 10% !important;
//  right: 10% !important;
//  width: 80% !important;
//  height: 97% !important;
//  position: fixed;
//}

.PdfPreviewPopup::part(content) {
  top: 1% !important;
  left: 10% !important;
  right: 10% !important;
  width: 80% !important;
  height: 97% !important;
  position: fixed;
}

.PdfPreviewPopup .popover-viewport.sc-ion-popover-md {
  height: 100% !important;
}

iframe img {
  width: 100%;
}

.pdfFrame {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.pdfFrame iframe {
  height: 100%;
  width: 100%;
}

.PdfViewerDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.full-screen {
  .modal-wrapper {
    height: 100% !important;
    width: 100% !important;
  }
}

.full-screen::part(content) {
  height: 100% !important;
  width: 100% !important;
}

@media (max-width: 1000px) and (min-width: 0) {
  .react-datepicker {
    font-size: 1em !important;
  }
}

@media (max-width: 767px) {
  .react-datepicker-popper {
    left: -2.8em !important;
  }

  .hidden-when-sm {
    display: none;
  }

  .react-datepicker {
    font-size: 1.1em;
  }

  .datepickerclass {
    text-align: center;
    font-size: 1.3em !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.5em; //increase width from here.
    line-height: 1.5em; //increase hight from here.
    margin: 0.166em;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }

  .react-datepicker__header {
    padding-top: 0.8em;
  }

  .react-datepicker__month {
    margin: 0.4em 1em;
  }
}

@media (min-width: 700px) {
  .react-datepicker-popper {
    left: -3.1em !important;
  }

  .react-datepicker {
    font-size: 1.4em;
  }

  .react-datepicker__header {
    padding-top: 0.8em;
  }

  .react-datepicker__month {
    margin: 0.4em 1em;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.5em; //increase width from here.
    line-height: 1.5em; //increase hight from here.
    margin: 0.166em;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }

  .react-datepicker__input-container input {
    font-size: 15px;
    width: 7em;
  }

  .datepickerclass {
    text-align: center;
    flex: 0 0 calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%);
    font-size: 1.3em !important;
  }

  .datepickernotcentre {
    flex: 0 0 calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%);
  }
}

/*
.react-datepicker{
  font-size: 1.2rem;
}
*/

.react-datepicker-popper {
  z-index: 9999 !important;
}

.rightone {
  --vertical-align: middle;
  --padding-start: 10px;
  --padding-end: 10px;
  --background: #00bfff;

  float: right;
}

.ion-modal {
  --height: 50%;
  --width: 300px;
}

.searchbar-input {
  border: 0 !important;
  box-shadow: none !important;

  & input {
    padding-right: 10px !important;
  }
}

.order-time-picker {
  margin-top: 26px;
  margin-bottom: 10px;
}

.order-time-picker .react-time-picker__inputGroup {
  min-width: auto;
}

.order-time-picker .react-time-picker__inputGroup__hour {
  min-width: 20px;
}

.order-time-picker .react-time-picker__inputGroup__minute {
  min-width: 20px;
}

.drop-box {
  align-items: center;
  border: 2px dashed #dadada;
  text-align: center;
  color: #555;
  padding: 10px;
  margin: 4px 0px;
}

.update-document-poporver::part(content) {
  width: 300px;
  text-align: center;
}

.update-document-poporver .document-name-input {
  background-color: #dadada;
}

.order-today {
  border-left: 5px solid #dadada;
}

.order-date-filter {
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

// ion-item.ion-color-primary.orderSelectItem {
//   border-color: #FFF;
// }

// div.item-inner {
//   border-color: #FFF;
// }

// .ion-color-primary {
//   --ion-color-shade: var(--ion-color-primary-shade, #fff) !important
// }
.myCustomSelect {
  max-width: 100% !important;
}

.trashicon {
  z-index: 1000;
}

/*
Common
 */

.DropZone {
  background: var(--ion-color-ice);
  border-radius: 0.5rem;
  font-size: 0.9em;

  > .drop-box {
    border: none;
    height: 16rem;
    line-height: 16rem;
    padding: 0;
    cursor: pointer;

    > ion-icon {
      font-size: 1.75em !important;
      vertical-align: middle;
      margin: 0 1rem;
    }
  }
}

.subList {
  display: grid;
}

.itemRegion {
  grid-column: 2 / 13;
}

.itemMarkets {
  grid-column: 3 / 13;
}

.itemMinor_Markets {
  grid-column: 4 / 13;
}

.itemTerminal_Groups {
  grid-column: 5 / 13;
}

.itemTerminals {
  grid-column: 6 / 13;
}

.itemPlants {
  grid-column: 7 / 13;
}

.setuplabel {
  grid-column: 8 / 13;
}

.dataLinkRow {
  border-bottom: groove;
  border-top: groove;
}

.dataLinkCol {
  border-right: groove;
  border-left: groove;
  text-align: center;
}

.dataLinkLabel {
  font-size: 1.5em;
}

//.fullOverView .modal-wrapper {
//  position: absolute;
//  width: 90%;
//  height: 90%;
//}

.fullOverView::part(content) {
  position: absolute;
  width: 90%;
  height: 90%;
}

.ion-item-highlight {
  background-color: #ffff33 !important;
}

.lastLoadModal {
  --max-height: 224px;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  //re
  width: 11px;
  margin: -5px 0;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.tooltip {
  z-index: 99999 !important;
}

.InnerImageCentre {
  justify-content: center !important;
}

.tooltip-inner {
  font-size: 1.25rem;
}

.itemMatches {
  text-align: center !important;
}

.modal-filter {
  --width: 70%;
  --height: 50%;
}

.customCalendar {
  .react-date-picker__calendar {
    width: 310px !important;
    margin-left: -50px;
  }
}

.modal-mini-billline {
  --width: 60%;
  --height: 25%;
}

.modal-dropdown-billLine {
  --width: 60%;
  --height: 80%;
  position: 'absolute';
  top: 10px;
}

.dropdown-menu-button-documents {
  font-size: 20px;
  background-color: #fafafa;
  color: #1090ff;
}

.dropdown-menu-documents {
  font-size: 15px;
  width: 120px;
}

.dropdown-menu-icons-documents {
  vertical-align: text-top;
}

.right-click-menu {
  min-width: 160px;
  background-color: #fafafa;
  color: #111111;
  font-size: 15px;
  border: none;
  text-align: left;
}

.right-click-menu:hover {
  background-color: #dadada;
}

.dropdown-link {
  color: black !important;
  text-decoration: none;
}

.document-container {
  padding: 20px;

  .card {
    .header {
      height: 30px;
      background-color: #1090ff;
    }

    .title {
      font-size: 25px;
    }

    .text {
      font-size: 2.5vw;
      text-overflow: clip;
    }
  }

  .load-card {
    .load {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 5px;
      top: 3px;
    }

    .chip {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 25px;
      margin-right: 0px;
      margin-left: auto;
    }

    .badge {
      cursor: pointer;
      font-size: 15px;
      padding-top: 4px;
    }

    .icon {
      cursor: pointer;
      font-size: 20px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

//.dateModel .modal-wrapper {
//  height: 490px;

//  .ion-page {
//    margin-bottom: 0 !important;
//  }
//}

.dateModel::part(content) {
  height: 490px;

  .ion-page {
    margin-bottom: 0 !important;
  }
}

.dropDownMenu {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.document-expanded-row {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loads-container-pagination-drop {
  // margin-top: 6px;
  // margin-right: 2rem;
  // font-size: 12px;
  .dropdown-item {
    &.active {
      background-color: #2684ff;
      color: #ffffff;
    }
  }

  // @media (max-width: 767px) {
  //   margin: auto;
  // }
}

.loads-container-pagination-info {
  font-size: 12px;

  @media (max-width: 767px) {
    align-self: center;
    margin: auto;
  }
}

.container-filters-scroll {
  overflow-x: scroll;
}
.container-filters-scroll::-webkit-scrollbar {
  display: none;
}

.container-filters-ionlabel {
  font-weight: bold;
  font-size: small;
  margin-top: 2px;
}

.react-date-picker__calendar {
  z-index: 999 !important;
}

.icon-inner {
  pointer-events: none;
}

.loads-create-row {
  transition: 0.3s;
}

.loads-create-picker {
  margin-left: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  position: 'sticky';
  z-index: 2px;
  top: 0px;
  background-color: 'white';
  font-size: 16px;
}

.loads-create-image {
  width: 32px;
  height: 32px;
}

//.modal-full-height .modal-wrapper {
//  position: absolute;
//  width: 90%;
//  height: 100%;
//}

.modal-full-height::part(content) {
  position: absolute;
  width: 90%;
  height: 100%;
}

ion-searchbar {
  .searchbar-clear-icon {
    display: none !important;
  }
}

.load-index-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // min-width: 100%;
  column-gap: 20px;
}

@media screen and (max-width: 1690px) {
  .load-index-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // min-width: 100%;
    column-gap: 40px;
  }

  .loadCard-expanded-left {
    min-width: 51%;
  }

  .loadCard-expanded-right {
    min-width: 200px !important;
  }
}

@media screen and (max-width: 1296px) {
  .load-index-grid {
    display: grid;
    grid-template-columns: 1fr;
    // min-width: 576px;
    // margin-left: 25%;
  }

  .loadCard-expanded-right {
    min-width: 200px !important;
  }
}

@media screen and (max-width: 1080px) {
  .load-index-grid {
    // margin-left: 15%;
  }
}

@media screen and (max-width: 880px) {
  .load-index-grid {
    // margin-left: 10%;
  }
}

@media screen and (max-width: 744px) {
  .load-index-grid {
    margin-left: 0%;
  }
}

@media screen and (max-width: 744px) {
  .load-index-grid {
    // margin-left: 0%;
  }
}

@media screen and (max-width: 600px) {
  .load-index-grid {
    // min-width: 95% !important;
    // width: 378px !important;
  }

  .load-grid-card {
    // min-height: 157px;
  }

  .loadCard-icon {
    min-width: 24px !important;
    max-width: 24px !important;
    height: 24px !important;
  }

  .loadCard-seller-buyer {
    padding-top: 2px !important;
    padding-left: 7px !important;
    height: 57% !important;
  }

  .loadCard-terminal {
    padding-left: 8px !important;
  }

  .seller-buyer-text {
    font-size: 15.5px !important;
  }

  .terminal-text {
    font-size: 12px !important;
  }

  .seller-buyer-text.missing {
    font-size: 15.5px !important;
  }

  .terminal-text.missing {
    font-size: 12px !important;
  }

  .loadCard-viewButton-container {
    height: 28px !important;
    margin-left: 6px !important;
  }

  .row-text {
    font-size: 12px !important;
  }

  .row-text.missing {
    font-size: 12px !important;
  }

  .expanded-row-text {
    font-size: 13px !important;
  }

  .expanded-row-text.missing {
    font-size: 13px !important;
  }

  .loadCard-expanded-right {
    // min-width: 184px !important;
  }

  .loadCard-table-label-column {
    min-width: 24% !important;
  }

  .loadCard-table-amount-column {
    min-width: 37% !important;
  }

  .table-header-text {
    font-size: 13px !important;
    overflow: hidden !important;
    height: 20px !important;
  }

  .table-header-text.missing {
    font-size: 13px !important;
  }

  .loadCard-table-cell-text {
    font-size: 13px !important;
  }

  .expand-button {
    font-size: 15px !important;
  }

  .header-buttons-row {
    min-width: 184px !important;
  }
}

.load-grid-card {
  border: 1px solid black;
  border-radius: 5px;
  min-width: 40%;
  min-height: 250px;
  // margin-bottom: 30px;
}

.load-grid-card-expanded {
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 30px;
}

.load-details-card {
  .load-grid-card {
    border: 0px !important;
    border-radius: 0px !important;
    min-height: 300px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    padding-left: 7px;
  }
}

//.loadDetails-modal {
//  .modal-wrapper {
//    max-height: 302px !important;
//  }
//}

.loadDetails-modal::part(content) {
  max-height: 452px !important;
}

.loadCard-icon {
  margin-top: 12px;
  margin-left: 1px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
}

.loadCard-topLeft {
  min-width: 42%;
  height: 100%;
}

.loadCard-seller-buyer {
  padding-left: 10px;
  padding-top: 5px;
  height: 59%;
  min-width: 300px;
}

.loadCard-terminal {
  padding-left: 10px;
  padding-top: 0px;
  height: 40%;
}

.seller-buyer-text {
  font-size: 22.5px;
}

.terminal-text {
  font-size: 13.5px;
}

.seller-buyer-text.missing {
  color: #717e8e;
  font-size: 22.5px;
}

.terminal-text.missing {
  color: #717e8e;
  font-size: 13.5px;
}

.loadCard-topRight {
  width: 35%;
  height: 100%;
}

.loadCard-viewButton-container {
  height: 30px;
  min-width: 30px;
  margin-top: 8px;
  margin-left: 28px;

  :hover {
    cursor: pointer;
    background-color: #d7e5ff;
  }
}

.loadGrid-bottom {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  padding-top: 5px;
  justify-content: space-between;
  padding: 0 8px;
}

.loadCard-bottomLeft {
  min-width: 55%;
  height: 100%;
}

.loadCard-bottomRight {
  min-width: 20%;
  height: 80%;
  padding-left: 7px;
}

.loadCard-bottom-row {
  min-width: 100%;
  height: 21px;
  margin-bottom: 6px;
}

.row-text {
  font-size: 16px;
}

.row-text.missing {
  color: #717e8e;
  font-size: 16px;
}

.loadCard-expanded-body {
}

.loadCard-footer {
  min-width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}

.loadCard-footer-buffer {
  height: 26px;
  min-width: 56%;
}

.buttonIcon {
  height: 70%;
  margin-right: 3px;
}

.loadCard-button-container {
  min-width: 17%;
  margin-right: 10px;
}

.expand-button {
  font-size: 15px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  min-width: 100%;
  height: 20px !important;
  text-transform: none;
  background-color: #3880ff;
  color: white;
}

.expand-button.open {
  color: #3880ff;
  background-color: white;
  border-left: 1px solid #3880ff;
  border-top: 1px solid #3880ff;
  border-right: 1px solid #3880ff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.loadCard-expanded-left {
  height: 100%;
  padding-left: 8px;
}

.loadCard-expanded-right {
  padding: 8px;
  // min-width: 260px;
  display: flex;
  flex: 1;
}

.loadCard-expanded-leftColumn {
  height: 100%;
  min-width: 90%;
}

.loadCard-expanded-leftRow {
  height: 21px;
  margin-bottom: 6px;
  min-width: 100%;
}

.expanded-row-text {
  font-size: 16px;
}

.expanded-row-text.missing {
  color: #717e8e;
  font-size: 16px;
}

.loadCard-table {
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.loadCard-table-label-column {
  height: 100%;
  min-width: 20%;
  padding-left: 5px;
  padding-top: 8px;
}

.loadCard-table-amount-column {
  padding-top: 8px;
  padding-left: 2px;
  height: 100%;
  min-width: 20%;
}

.loadCard-table-blank-corner {
  height: 25px;
  min-width: 100%;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
}

.loadCard-table-label-cell {
  height: 25px;
  min-width: 100%;
  text-align: right;
}

.loadCard-table-amount-cell {
  height: 25px;
  min-width: 100%;
  text-align: center;
}

.table-header-text {
  height: 25px;
  overflow: hidden;
  font-size: 16px;
  text-decoration: underline;
  margin: 0 !important;
}

.table-header-text.missing {
  font-size: 14px;
  text-decoration: underline;
}

.loadCard-table-cell-text {
  font-size: 16px;
  margin: 0 !important;
}

.header-logo {
  height: 64px;
  width: auto;
  margin-left: 16px;
  margin-right: 4px;
  cursor: pointer;
}

.body-grid {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  margin-top: 0 !important;
}

.menu-column {
  position: absolute;
  top: 64px;
  height: calc(100% - 64px);
  width: 236px;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.03);
  z-index: 750;
}

.sub-menu-column {
  position: fixed;
  top: 115px;
  width: 265px;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.03);
  z-index: 750;
  margin-left: 10px;

  &.isMobile {
    position: relative;
    top: 0;
    width: auto;

    .Tabs__subMenuContainer {
      max-height: 200px;
    }
  }
}

.menu-column-space {
  height: 100%;
  width: 236px;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.03);
  z-index: -1;
}

.create-btn-container {
  height: 80px;
  width: 100%;
  padding-top: 16px;
  padding-left: 24px;
  background-color: white;
}

.create-btn {
  width: 184px;
  height: 44px;
  text-transform: none;
  font-size: 20px;
  font-weight: bold;
  --border-radius: 10px;
}

//.create-doc-modal {
//  .modal-wrapper {
//    // min-height: 600px;
//    height: 800px;
//    width: 716px;
//    top: 20px;
//    position: fixed;
//  }
//}

.create-doc-modal::part(content) {
  height: 800px;
  width: 716px;
  top: 20px;
  position: fixed;
}

.create-doc-modal-container {
  width: 716px;
  height: 500px;
  padding: 24px;

  .dropdown-menu {
    position: absolute !important;
    width: unset !important;
  }
}

.create-doc-title {
  margin-bottom: 16px;
  text-align: center;
  font-size: 28px;
}

.menu-options {
  padding-top: 0px;
  padding-left: 20px;
  // height: 376px;
  z-index: 998;
  overflow: auto;
}

.menuDropdown:hover {
  --box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05),
    inset 0px 1px 1px #ffffff !important;
  --background: #e8f1ff !important;
}

.hoverDropdown:hover {
  --background: #efefef !important;
}

.labelDropdown {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #6f767e !important;
  margin-bottom: 13px;
}

.labelDropdown-select {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1090ff !important;
  margin-bottom: 13px;
}

.menu-item {
  border-radius: 12px;
  min-width: 188px;
  height: 44px;
  margin-bottom: 4px;
}

.collapse-btn-container {
  margin-left: 160px;
  cursor: pointer;
  z-index: 999;
}

.submenu-text {
  font-size: 12px;
}

.submenu-profile-item {
  width: '100%';
  max-height: 48px !important;
  padding: 0;
  --border-radius: 12px;
  --border-bottom-left-radius: 12px !important;
  font-weight: 700 !important;

  &.--active {
    --background: #e3e1e5 !important;
  }

  &.--passive {
    color: #84818a !important;
  }

  &.--passive:hover {
    --background: #efefef !important;
  }
}

.submenu-item {
  width: 186px;
  max-height: 48px !important;
  margin-left: 20px;
  border-radius: 12px;
}

.activeTab {
  color: #1090ff !important;
}

.activeTab:hover {
  --background: #e8f1ff !important;
}

.passiveTab:hover {
  --background: #efefef !important;
}

.menu-footer {
  background-color: white;
  padding-left: 20px;
  width: 236px;
  border-radius: 2px;
  border-top: 2px solid #ebeaed;
}

.footer-row {
  width: 188px;
  height: 44px;
  margin-bottom: 4px;
}

.menu-column-mini {
  position: absolute;
  top: 64px;
  height: calc(100% - 64px);
  width: 96px;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.03);
  z-index: 750;
}

.menu-column-mini-space {
  height: 100%;
  width: 96px;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.03);
  z-index: -1;
}

.create-btn-container-mini {
  height: 84px;
  width: 100%;
  padding-top: 16px;
  padding-left: 24px;
  background-color: white;
}

.create-btn-mini {
  width: 48px;
  height: 48px;
  text-transform: none;
  font-size: 20px;
  font-weight: bold;
  --border-radius: 56px;
  margin: 0px !important;
}

.menu-options-mini {
  padding-top: 0px;
  padding-left: 24px;
  padding-bottom: 0;
  width: 100%;
  overflow: scroll;
}

.menu-icon-mini {
  font-size: 24px !important;
  position: absolute;
}

ion-icon.menu-icon-mini {
  pointer-events: none;
}

.submenu-arrow-container {
  padding-top: 16px;
  width: 24px;
  height: 48px;
  position: fixed;
  margin-left: 48px;
}

.submenu-arrow {
  font-size: 20px !important;
}

.pointer {
  cursor: pointer;
}

.menu-footer-mini {
  background-color: white;
  padding-left: 24px;
  width: 96px;
  border-top: 2px solid #ebeaed;
}

.footer-row-mini {
  width: 48px;
  height: 48px;
}

.menu-item-mini {
  border-radius: 12px;
  width: 48px;
  height: 48px;
  --padding-start: 12px !important;
}

.subtab-menu-mini {
  max-height: 352px;
  width: 164px;
  margin: auto;
  position: fixed;
  top: 164px;
  left: 92px;
  z-index: 999;
  overflow: scroll;
}

.subtab-menu-row {
  height: 24px;
  border-bottom: 1px solid #ebeaed;
  --background: #f7f7f7 !important;
}

.subtab-menu-row:hover {
  --background: #eaf2ff !important;
}

.subtab-menu-text {
  font-size: 14px !important;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

.access-table-container {
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 556px) {
    margin: 0;
  }
}

.access-scope-header {
  margin-left: 12px;
  background-color: white;
  border: 1px solid #1090ff;
  border-radius: 3px;
}

.loads-create-header {
  background-color: #1090ff;
  opacity: 0.1;
  border-radius: 0px 0px 20px 20px;
}

.loads-create-top-button {
  background: #4590ff;
  border-radius: 12px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 34px;
  margin-bottom: 34px;
}

.loads-create-margin {
  margin-left: 20px;
  margin-right: 20px;

  .empty-badge {
    vertical-align: text-bottom;
    background-color: #ff7f0f;
  }

  .text-loads {
    font-size: 18px;
    font-weight: 700;
  }

  .date-icon {
    font-size: 16px;
    vertical-align: sub;
  }
}

.load-capture-list {
  margin-top: 4px;

  .item {
    margin-top: 16px;

    .picture {
      border: 1px solid #ff7f0f;
    }

    .text {
      font-size: 16px;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: clip;

      .margin {
        margin-bottom: 0px;
      }

      .smaller {
        font-size: 14px;
        font-weight: 400;

        .clock {
          vertical-align: text-bottom;
          margin-right: 2px;
          font-size: 18px;
          pointer-events: none;
        }
      }
    }

    .ellipsis {
      vertical-align: sub;
      font-size: 18px;
      cursor: pointer;
    }

    .status {
      vertical-align: sub;
      font-size: 18px;
      pointer-events: none;
      margin-top: 22px;
    }
  }
}

.link-clean {
  text-decoration: none;
}

.square-16 {
  height: 16px;
  width: 16px;
}

.upload-doc-form {
  padding-left: 16px;
  width: 1204px;
  overflow: scroll;
}

.upload-doc-form-mini {
  padding-left: 16px;
  width: 1304px;
}

.document-card {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 508px;
  width: 424px;
  margin: 8px;
}

.document-card-leftA {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 508px;
  width: 424px;
  margin-left: 0;
  margin-right: auto;
}

.document-card-image-container {
  width: 100%;
  height: 264px;
}

.document-card-center-container {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  padding-left: 20px;
  border-right: 2.5px solid rgb(70, 73, 76);
  border-left: 2.5px solid rgb(70, 73, 76);
}

.document-card-details-container {
  width: 100%;
  height: 190px;
  padding-top: 12px;
  background: #fbfafc;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-left: 2.5px solid rgb(70, 73, 76);
  border-right: 2.5px solid rgb(70, 73, 76);
  border-bottom: 2.5px solid rgb(70, 73, 76);
}

.document-card-name-text {
  font-size: 20px;
  font-weight: bold;
  margin-left: 8px;
  padding-top: 8px;
  width: 65%;
  overflow: hidden;
}

.document-card-status-dot {
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  height: 8px;
  width: 8px;
}

.new-doc {
  background-color: #1090ff;
}

.used-doc {
  background-color: lightgrey;
}

.issue-doc {
  background-color: #fe3a42;
}

.document-card-status-text {
  border: 1px solid lightgrey;
  border-radius: 10px;
  font-size: 14px;
  height: 38px;
  margin-top: 4px;
  margin-left: 50px;
  padding-top: 8px;
  text-align: center;
  width: 18%;
}

.document-card-details-header {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  height: 40px;
  width: 100%;
  padding-left: 12px;
}

.document-card-seller-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.document-card-seller-name {
  font-size: 18px;
  font-weight: bold;
  padding-top: 8px;
  width: 80%;
}

.document-card-menu-btn {
  color: #1090ff;
  cursor: pointer;
}

.document-card-details-body {
  width: 63%;
  height: 100%;
  padding-top: 8px;
}

.document-card-icon {
  margin-right: 8px;
}

.document-card-text {
  font-size: 16px;
  color: #6f767e;
  width: 100%;
  height: 28px;
  margin-bottom: 0rem;
  padding-left: 16px;
}

.document-card-button-container {
  width: 37%;
  height: 100%;
}

.document-view-btn {
  width: 136px;
  height: 44px;
  text-transform: none;
  font-size: 15px;
  font-weight: bold;
  --border-radius: 10px;
  margin-left: 4px;
  margin-top: 25px;
}

.document-edit-load-invoice-btn {
  margin-top: 5px;
  width: 136px;
  height: 44px;
  text-transform: none;
  font-size: 15px;
  font-weight: bold;
  --border-radius: 10px;
  margin-left: 4px;
}

.embed-hider {
  min-height: 100%;
  min-width: 100%;
  z-index: 900;
  background-color: white;
  position: fixed;
}

.settingForm {
  top: 30px;
  position: relative;
  margin-left: 300px;

  .colorBox {
    height: 30px;
    width: 15px;
    border-radius: 4px;
  }

  .labelProfile {
    margin-left: 15px;
    font-weight: bold;
    white-space: break-spaces;
  }

  .profileButton {
    margin-top: 20px;
  }

  &.EditCompanyContainer__settingForm {
    margin-left: 0;
    position: static;
  }
}

.progress-container {
  width: 100%;
  height: 56px;
  margin-top: 7.25rem;
  margin-left: 10px;
}

.progress-whitespace {
  width: 25%;
}

.status-bar-container {
  height: 100%;
  padding-top: 4px;
  padding-left: 12px;
}

.segment-container {
  height: 40px;
  width: 32px;
  position: relative;
  z-index: 50;
}

.segment {
  border: 2px solid #1090ff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.segment.approved {
  border: 2px solid #30bb50;
}

.segment.rejected {
  border: 2px solid #ff6961;
}

.segment.exported {
  border: 2px solid #7044ff;
}

.segment-interior {
  border: 1px solid white;
  background-color: #1090ff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.segment-interior.inverse {
  background-color: white;
}

.segment-interior.gray {
  background-color: gray;
}

.segment-interior.approved {
  background-color: #30bb50;
}

.segment-interior.rejected {
  background-color: #ff6961;
}

.segment-interior.exported {
  background-color: #7044ff;
}

.segment-number {
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.segment-number.inverse {
  color: #1090ff;
}

.segment-bar {
  border-top: 2px solid #1090ff;
  border-bottom: 2px solid #1090ff;
  width: 84px;
  height: 16px;
  margin-top: 9px;
  margin-left: -3px;
  margin-right: -3px;
  position: static;
  z-index: 25;
}

.segment-bar-progress-1 {
  background-color: #1090ff;
  height: 100%;

  animation-name: segmentProgress;
  animation-iteration-count: 1;
  animation-duration: 2s;
}

.segment-bar-progress-2 {
  background-color: #1090ff;
  height: 100%;

  animation-name: segmentProgressDelay1;
  animation-iteration-count: 1;
  animation-duration: 3s;
}

.segment-bar-progress-3 {
  background-color: #1090ff;
  height: 100%;

  animation-name: segmentProgressDelay2;
  animation-iteration-count: 1;
  animation-duration: 4s;
}

.segment-bar-progress-4 {
  background-color: #1090ff;
  height: 100%;

  animation-name: segmentProgressDelay4;
  animation-iteration-count: 1;
  animation-duration: 5s;
}

@keyframes segmentProgress {
  0% {
    width: 0%;
  }

  50% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes segmentProgressDelay1 {
  0% {
    width: 0%;
  }

  66% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes segmentProgressDelay2 {
  0% {
    width: 0%;
  }

  75% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes segmentProgressDelay4 {
  0% {
    width: 0%;
  }

  80% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.segment-label {
  color: #6f767e;
  font-size: 13px;
  text-align: left;
  display: block;
  width: 72px;
  margin-left: -12px;
}

.segment-label.completed {
  color: black;
}

.my-company-body {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  margin-right: 20px;
  margin-left: 20px;
  // padding-bottom: 16px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.my-company-header-top-row {
  width: 100%;
  padding-bottom: 8px;

  @include media-breakpoint-up(md) {
    padding: 16px 24px 0;
  }
}

.my-company-add-cancel-btn {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

//.my-company-header-left {}

.my-company-btf-stps-container {
  margin-top: 8px;
  width: 100%;

  @include media-breakpoint-down(xl) {
    margin-top: 0;
    padding-top: 0 !important;
  }
}

.my-company-header-right {
  text-align: right;
}

.my-company-header-title {
  color: black;
  font-size: 19px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
}

.my-company-title {
  cursor: pointer;
}

.my-company-title:hover {
  background-color: #f9fafb;
}

.my-company-header-chip {
  height: 24px;
  margin-left: 8px;
  cursor: default;
}

.my-company-header-chip-text {
  font-size: 12px;
}

.my-company-header-description {
  color: #6f767e;
  font-size: 14px;
  text-transform: none;
}

.my-company-btn-secondary {
  height: 40px;
  font-size: 15px;
  text-transform: none;
  display: inline-flex !important;
  --border-radius: 12px;
}

.my-company-btn-secondary.filters-btn {
  margin-top: 0px;
  margin-bottom: 0px;
  vertical-align: top;
  margin-right: 8px;
}

.my-company-btn-secondary.sort-btn {
  border: 2px solid #d0d5dd;
  border-radius: 12px;
}

.my-company-btn-primary {
  height: 40px;
  min-width: 140px;
  margin-right: 0px;
  font-size: 15px;
  letter-spacing: 0px;
  text-transform: none;
  --border-radius: 12px;

  &.isMobile {
    max-width: 80px;
    min-width: 80px;
  }
}

.my-company-filter-container {
  padding: 0 4px;

  @include media-breakpoint-up(md) {
    padding: 0 16px;
  }

  ion-grid {
    padding: 0 !important;
  }
}

.my-company-filter-search-container {
  display: flex;
  align-items: center;
  // margin-bottom: 12px;

  @include media-breakpoint-down(xl) {
    padding-bottom: 16px !important;
  }
}

.my-company-breadcrumbs {
  font-size: 15px;
  margin-top: 16px;
  padding-left: 44px;
}

.my-company-breadcrumb {
  a {
    margin-left: 12px;
  }
}

.my-company-breadcrumb-end {
  margin-left: 12px;
  color: #1090ff !important;

  a {
    margin-left: 12px;
    cursor: default;
  }
}

.my-company-filters-row {
  position: relative;
  z-index: 5;
  // height: 52px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.my-company-menuDropdown {
  background-color: white;
  border: 1px solid black;
  width: 176px;
  position: absolute;
  z-index: 100;
  margin-left: 24px;
  margin-top: -36px;
}

.my-company-menuDropdown-item {
  width: 100%;
  height: 21px;
  font-size: 14px;
  padding-left: 8px;
  margin-bottom: 0px;
}

.my-company-menuDropdown-item:hover {
  background-color: #d7ecff;
}

.ion-modal-sort {
  --height: 50%;
  --width: 350px;
}

.ion-modal-matchInvoice {
  --height: 50%;
  --width: 65%;
}

.table-pill {
  display: inline-block;
  opacity: 0.5;
  background: var(--ion-color-ice);
  color: white;
  max-width: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem 1rem;
  border-radius: 100vw;
  font-weight: 400;

  &.--invisible {
    visibility: hidden;
  }

  &.--caution {
    background: var(--ion-color-orange);
  }

  &.--primary {
    background: var(--ion-color-concord);
  }

  &.--success,
  &.--inactive,
  &.--failure,
  &.--process,
  &.--export,
  &.--brand,
  &.--noCollection,
  &.--error,
  &.--locked {
    color: var(--ion-color-slate);

    span {
      display: inline-block;
      vertical-align: middle;
      font: 800 normal 36px/1rem monospace;
      max-width: 24px;
    }
  }

  &.--inactive {
    background: var(--ion-color-slate-contrast);

    span {
      color: var(--ion-color-slate-tint);
      text-shadow: 0 0 10px var(--ion-color-slate);
    }
  }

  &.--success {
    background: #d9ffd9;

    span {
      color: var(--ion-color-darkgreen-tint);
      text-shadow: 0 0 10px var(--ion-color-darkgreen);
    }
  }

  &.--export {
    background: #ebe5f5;

    span {
      color: var(--ion-color-export-tint);
      text-shadow: 0 0 10px var(--ion-color-export);
    }
  }

  &.--failure {
    background: #ffd4e3;

    span {
      color: var(--ion-color-crimson-tint);
      text-shadow: 0 0 10px var(--ion-color-crimson);
    }
  }

  &.--noCollection {
    background: #ffeca0;

    span {
      color: #ffc240;
      text-shadow: 0 0 10px #ebb747;
    }
  }

  &.--locked {
    background: var(--ion-color-fleet);

    span {
      color: var(--ion-color-fleet);
      text-shadow: 0 0 10px var(--ion-color-fleet);
    }
  }

  &.--process {
    background: var(--ion-color-ice);

    span {
      color: var(--ion-color-concord-tint);
      text-shadow: 0 0 10px var(--ion-color-concord);
    }
  }

  &.--brand {
    background: #d1d1d1;
    color: #545454;

    span {
      color: rgb(145, 145, 145);
      text-shadow: 0 0 10px #757575;
    }
  }

  &.--error {
    background: #f45855;
    color: #ffffff;

    span {
      color: #c62828;
      text-shadow: 0 0 10px #b64141;
    }
  }

  &.--full-width {
    max-width: 100%;
  }
}

.my-company-form {
  padding-left: 24px;
}

.my-company-form-field {
  width: 35%;
  height: 72px;
  margin-bottom: 12px;
}

.my-company-select-field {
  width: 65%;
  height: 44px;
  margin-top: 24px;
  border-left: 2px solid #d0d5dd;
}

.my-company-form-label {
  font-size: 14px;
  color: #344054;
}

.my-company-color-picker {
  display: block !important;
  margin-top: 6px !important;
}

.seller-product-radio-group {
  display: inline;
  margin-left: 4px;
  margin-right: 20px;
  height: 24px;
}

.seller-product-radio-input {
  display: inline;
  margin-right: 4px;
  border: 0px;
  width: 14px;
  height: 14px;
}

.seller-product-radio-label {
  font-size: 13px;
  color: #344054;
  vertical-align: top;
}

.my-company-seller-label {
  font-size: 16px;
  text-transform: capitalize;
  margin-left: 2px;
  height: 32px;
}

.my-company-form-input {
  display: block;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  height: 32px;
  width: 100%;
  margin-right: 24px;
  margin-top: 6px;
  font-size: 14px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  input::placeholder {
    color: #bebebe !important;
  }

  &.error {
    border: 1px solid red;
  }
}

.my-company-form-input-error {
  margin-left: 6px;
  margin-top: 4px;
  display: inline-block;
  color: red;
  font-size: 11px;
}

.my-company-submit-button {
  background-color: #1090ff;
  color: white;
  border-radius: 12px;
  margin-top: 20px;
  padding: 8px 16px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.onboarding {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-size: cover;
  background-image: url('https://images.squarespace-cdn.com/content/v1/5d52c42c454f1000011a5b54/1565705646584-05V7ZYBGZVMKPG2N8FQ1/Mack%2BDriver%2B028.jpg?format=2500w');

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  .overall-progress {
    width: 232px;
    background-color: white;
    //height: 243px;
    border-radius: 0px 0px 0px 8px;
    justify-self: end;
    margin-bottom: auto;

    .icons {
      font-size: 24px;

      .vl {
        margin-left: 28px;
        border-left: 2px solid #344054;
        height: 34px;
      }
    }

    .text {
      font-size: 14px;
      font-weight: 500;

      .spacing {
        padding: 2px 0px 1px 0px;
        margin-bottom: 33px;

        .top {
          margin-top: 14px;
        }
      }

      .current {
        font-weight: 700;
      }
    }
  }

  .mmmodal {
    max-width: 500px;
    min-width: 400px;
    border-radius: 8px;
    background-color: white;
    margin-top: 32px;
    margin-bottom: auto;
    overflow: auto;
    max-height: calc(100vh - 150px);

    .no-padding {
      padding: 0;
    }

    .ppprogress {
      margin: 0px 6px;

      .barr {
        margin-top: 8px;
      }

      .icon {
        float: right;
        vertical-align: top;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .header {
      font-size: 24px;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 8px;
      margin-top: 16px;
    }

    .form {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 32px;
      font-size: 14px;

      .text-example {
        color: gray;
      }

      .text-field {
        font-size: 16px;
        border-radius: 8px;
        margin-bottom: 16px;
      }

      .card {
        margin-bottom: 8px;
        border-radius: 8px;
      }

      .buttons {
        margin-top: 32px;
        margin-bottom: 32px;
      }

      .bottom-16 {
        margin-bottom: 16px;
      }
    }

    .onboarding-row {
      font-size: 16px;
      margin-right: 40px;
      margin-left: 40px;
      margin-bottom: 8px;
    }
  }
}

.driver-avatar {
  color: white;
  font-size: 16px;
  padding: 4px;
  border-radius: 50%;
  white-space: nowrap;
}

.reference-modal {
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
}

.company-avatar {
  height: 35px;
  width: 35px;
  border-radius: 30px;
}

.company-card {
  margin: 8px 0px 8px 0px;
  border-radius: 8px;
  overflow: hidden;

  .avatar {
    margin: 0px;
    width: 64px;
    height: 64px;
    border: 0px;
  }

  .icon {
    text-align: center;
    font-size: 32px;
  }

  .text {
    font-size: 14px;
    padding: 0px;
    margin: 0px;

    .larger {
      font-size: 16px;
      font-weight: 500;
    }

    .no-padding {
      padding: 0px;
      margin: 0px;
      overflow-y: hidden;
    }
  }
}

.load-form-field-badge {
  margin-left: 10px;
  padding: 7px 0px 7px 10px !important;
  background: lightgrey !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

.terminal-card {
  .text {
    font-size: 14px;

    .larger {
      font-size: 16px;
      font-weight: 500;
    }

    .no-bottom {
      margin-bottom: 0px;
    }
  }
}

.edit-button:hover {
  background-color: #e9ecef;
}

.document-preview-container {
  position: relative;
}

.embed-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.context-btn:hover {
  background-color: #fff;
}

.placeholder-nopacity {
  --placeholder-opacity: 1;
}

.driver-select {
  --padding-left: 20px;
  --padding-right: 0px;
}

.safe-area-inset-top {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

.ion-modal-mobile {
  --height: 60%;
  --width: 300px;
}

.rdrDateRangePickerWrapper {
  &.mobile-view {
    display: flex;
    // min-height: 600px;
    // justify-content: space-around;
    // flex-direction: column-reverse;

    .rdrDefinedRangesWrapper {
      width: 100%;
      display: none;
    }

    .rdrCalendarWrapper {
      width: 100%;

      .rdrMonths {
        align-self: center;
      }
    }
  }
}

.print-load-row {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  font-size: 15px;
}

.print-load-card {
  min-width: 240px;
  min-height: 120px;
  border: 1px solid grey;
  padding-left: 10px;
  border-radius: 8px;
}

.scan-ticket {
  .sticky {
    position: sticky;
    top: 0.5rem;
    z-index: 1;
    margin: 2rem;
  }
}

.cost-code-body {
  padding-left: 24px;
  padding-right: 24px;
}

.cost-code-row {
  border: 1px solid #ebeaed;
  background-color: #fcfcfc;
  border-radius: 5px;
  height: 44px;
  padding-left: 12px;
  margin-bottom: 1px;
}

.second-row {
  margin-left: 44px;
  height: 40px;
}

.third-row {
  margin-left: 92px;
  height: 36px;
  cursor: default;
}

.cost-code-add-row {
  margin-bottom: 1px;
  background-color: white;
  border: 0px;
}

.cost-code-icon-container {
  color: white;
  background-color: #1090ff;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  margin-top: 13px;
  padding-top: 1px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.cc-md-icon-container {
  margin-top: 11px;
}

.cc-check-icon-container {
  cursor: pointer;
  color: white;
  background-color: #30bb50;
  margin-left: 8px;
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  margin-top: 14px;
  padding-top: 1px;
  height: 16px;
  width: 16px;
}

.md-icon-row {
  margin-top: 12px !important;
}

.sm-icon-row {
  margin-top: 10px !important;
}

.lg-grid-row {
  margin-top: 8px !important;
}

.md-grid-row {
  margin-top: 6px !important;
}

.sm-grid-row {
  margin-top: 5px !important;
}

.sm-input-row {
  font-size: 13px !important;
  height: 26px !important;
}

.cost-code-icon {
  height: 100%;
  width: 100%;
}

.cc-inline-form {
  padding-left: 4px;
  background-color: white;
  border: 0px;
  border-left: 1px solid #ebeaed;
}

.cc-inline-form-input {
  font-size: 15px;
  width: 44px;
  height: 28px;
  border: 1px solid #cecece;
  border-radius: 4px;
}

.cc-inline-form-input::placeholder {
  font-size: 12px;
}

.cc-name-input {
  width: 172px !important;
}

.lg-text-container {
  margin-left: 28px;
}

.md-text-container {
  margin-left: 20px;
}

.cost-code-row-text {
  line-height: 44px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 16px;
}

.cc-second-row-text {
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
}

.cc-third-row-text {
  line-height: 36px;
  font-size: 12px;
  cursor: pointer;
}

.cc-child-count {
  text-align: center;
  font-size: 14px;
  height: 20px;
  width: 26px;
  border-radius: 39%;
  color: white;
  margin-top: 11px;
}

.cc-md-child-count {
  font-size: 13px;
  height: 18px;
  margin-left: 16px;
}

.cc-sm-child-count {
  font-size: 12px;
  height: 16px;
  margin-left: 16px;
  margin-top: 9px;
}

.cc-action-button {
  cursor: pointer;
  background-color: white;
  height: 18px;
  width: 18px;
  margin-top: 11px;
  margin-right: 12px;
}

.sm-action-btn {
  margin-top: 9px;
}

.fleets-create-order-input {
  margin-bottom: 4px;
  min-width: 100%;
  height: 56px;
  padding-left: 12px;

  &.--flex {
    display: flex;
  }
}

.fleets-create-datetime {
  margin-bottom: 4px;
  width: 100%;
  height: 40px;
  padding-left: 12px;
}

.fleets-create-datetime-input {
  height: 28px;
  font-size: 14px;
  border: 1px solid rgb(213, 217, 217);
  border-radius: 4px;
  padding-left: 4px;
}

.fleet-order-dropdown-container {
  display: block;
  height: 0px;
  box-shadow: rgba(213, 217, 217, 0.5) 0px 2px 5px 0px;
  border-radius: 8px;
}

.fleet-order-status-container {
  display: block;
  min-width: 140px;
  height: 44px;
  box-shadow: rgba(213, 217, 217, 0.5) 0px 2px 5px 0px;
  border-radius: 8px;
}

.fleet-order-dropdown {
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  padding-left: 4px;
  border-radius: 8px;
}

.fleet-order-status-dropdown {
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  padding-left: 4px;
  border-radius: 8px;
}

.fleet-order-btn {
  width: 100%;
}

.fleet-create-input-label {
  font-size: 15px;
  min-width: 60px;
  font-weight: bold;
  margin-bottom: 3px;
  margin-right: 8px;
}

.fleet-create-default-input {
  font-size: 14px;
  width: 60px;
  padding-left: 4px;
  border: 1px solid rgb(213, 217, 217);
  border-radius: 4px;
}

.select-search-modal {
  max-height: 90%;
  overflow: hidden !important;
}

.select-search-modal-list {
  margin-top: 4px;
  overflow: scroll;
  max-height: 63vh;
}

.select-search-modal-item {
  border-bottom: 1px solid rgb(213, 217, 217);
  padding-left: 3%;
  padding-right: 3%;
  height: 40px;
}

.order-modal-item-label {
  width: 85%;
  display: inline-block;
  font-size: 15px;
  line-height: 40px;
  overflow: hidden;
  cursor: pointer;
}

.order-modal-item-input {
  width: 16px;
  height: 16px;
  margin-top: 12px;
  margin-left: 8px;
  display: inline-block;
  cursor: pointer;
}

.export {
  border-radius: 10px !important;
}

.location-search-input {
  width: 100%;
  border: 0;
}

.autocomplete-dropdown-container {
  position: fixed;
  width: 100%;
  background-color: white;
  margin: 0;
  font-size: 16px;
}

.EditCompanyForm__timezoneDropdown {
  --width: 500px !important;
}

.item-input .sc-ion-label-md-h {
  max-width: 350px;
}

.DataSortTool__reorderCheckbox {
  &.savingData {
    border-bottom: none !important;
  }
}

.start-times-colors {
  .blue-option {
    background-color: #0dd1e8;
    --color-hover: #0dd1e8;
  }

  .purple-option {
    background-color: #5260ff;
    --color-hover: #6370ff;
  }

  .green-option {
    background-color: #0fdc60;
    --color-hover: #42d77d;
  }

  .yellow-option {
    background-color: #ffce00;
    --color-hover: #ffca22;
  }

  .red-option {
    background-color: #f04141;
    --color-hover: #ed576b;
  }

  .grey-option {
    background-color: #989aa2;
    --color-hover: #9d9fa6;
  }

  .pink-option {
    background-color: var(--ion-color-pink);
    --color-hover: var(--ion-color-pink);
  }

  .darkgreen-option {
    background-color: var(--ion-color-darkgreen);
    --color-hover: var(--ion-color-darkgreen);
  }

  .orange-option {
    background-color: var(--ion-color-orange);
    --color-hover: var(--ion-color-orange);
  }

  .action-sheet-cancel {
    --button-color: #eb445a;
    --button-color-hover: #eb445a;
  }
}

.CompanyFilterHeader__exportButton {
  // background-color: white;
  border: 2px solid var(--ion-color-export);
  border-radius: 10px;
  margin-left: 8px !important;
}

// custom react-places-autocomplete
// see issue: https://stackoverflow.com/questions/58980475/react-places-autocomplete-pushing-down-the-other-components-below
.autocomplete-dropdown-container {
  position: absolute;
  z-index: 10000;
}

.seller-buyer-form {
  max-width: 400px;
  min-width: 350px;
  border-radius: 8px;
  background-color: white;
  margin-top: 32px;
  margin-bottom: auto;

  .no-padding {
    padding: 0;
  }

  .ppprogress {
    margin: 0px 6px;

    .barr {
      margin-top: 8px;
    }

    .icon {
      float: right;
      vertical-align: top;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .header {
    font-size: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 8px;
    margin-top: 32px;
  }

  .form {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    font-size: 14px;

    .text-example {
      color: gray;
    }

    .text-field {
      font-size: 16px;
      border-radius: 8px;
      margin-bottom: 16px;
    }

    .card {
      margin-bottom: 8px;
      border-radius: 8px;
    }

    .buttons {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .bottom-16 {
      margin-bottom: 16px;
    }
  }

  .onboarding-row {
    font-size: 16px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 8px;
  }
}

.CompanyProductGroupsContainer__alertDelete {
  .alert-wrapper {
    max-width: 1000px !important;
  }
}

ion-icon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

.LoadTableEditableRow__container {
  .FormatOptionSellerLabel__label {
    width: 75%;
  }
}

.Profile__colorSelector {
  width: 184px !important;
  margin-left: 14px;
}

.editable-table-row-select-cell {
  justify-content: center;
}

.CompanyFilterHeader__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 12px;

  @include media-breakpoint-down(lg) {
    display: block;
  }

  .ContainerSearchBar__container,
  .documents-sortby-container,
  .documents-container-clear,
  .ContainerFilter__exportContainer,
  .ContainerFilter__filterButtonModal {
    display: inline-block !important;
    vertical-align: middle !important;
  }
}

.CompanyFilterHeader__subTab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CompanyFilterHeader__dataSortTool {
  margin-right: 8px;

  .Filter__icon {
    padding-bottom: 8px;
    font-size: 32px;
  }

  .popup-check-box {
    right: initial;
  }
}

.popup-check-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 40px;
  background-color: #fff;
  height: auto;
  width: 210px;
  left: 0;
  color: black;

  &.--width {
    width: 220px;
  }

  &.--long {
    width: 290px;
  }
}

.mainFilters-check-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  height: auto;
  width: 210px;
  color: black;

  &.--width {
    width: 220px;
  }

  &.--long {
    width: 290px;
  }
}

.ContainerFilter__companyFilterHeader {
  overflow: hidden;

  &.container-filters-scroll {
    margin-top: 0 !important;
  }
}

.loading-wrapper.ion-overlay-wrapper {
  --height: 60px;
  --width: 60px;
}

.LoadGridCard__companyAvatar {
  padding: 4px;
}

.LoadFormSelect__questionIcon {
  display: inline-block;
  vertical-align: middle;
  font-size: 26px;
}

.CompanyFilterHeader__searchBar {
  margin-right: 8px;

  .CompanyFilterHeader__searchBarParent {
    width: 100%;
  }
}

.LoadIndexTableDatePickerCell__buttonLink {
  button {
    &:hover {
      a {
        color: white;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.FleetMapContainer__mapButton {
  font-size: 14px !important;
  margin-bottom: 8px;
  margin-top: 8px;

  &:not(:first-child) {
    margin-left: 8px;
  }
}

.ParsedLoadContainer__splitContainer {
  top: 45px !important;
}

.ParsedLoadContainer__menu {
  // box-shadow: none;
  padding: 0;

  .RightClickMenu__buttonContainer {
    border: none;

    .right-click-menu {
      background-color: white;

      &:hover {
        background-color: #e5e5e5;
      }
    }
  }
}

.LoadIndexTableRow__expandRowContainer {
  height: 50px;
  font-size: 12.5px;
  text-align: center;

  .react-date-picker {
    margin-top: 10px;
  }
}

.LoadIndexTableRow__statusCell {
  opacity: 1 !important;
  max-width: 15rem;
  font-size: 12px;
  display: flex;
  align-items: center;

  .LoadIndexTableRow__dotStatus {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
  }

  &.waiting {
    background-color: #d1d1d1;
    color: #545454;

    .LoadIndexTableRow__dotStatus {
      background-color: rgb(145, 145, 145);
      box-shadow: 0 0 10px #757575;
    }
  }

  &.done {
    background-color: #d9ffd9;
    color: #454545;

    .LoadIndexTableRow__dotStatus {
      background-color: var(--ion-color-darkgreen-tint);
      box-shadow: 0 0 10px var(--ion-color-darkgreen);
    }
  }

  &.not-sent {
    background-color: #ffd4e3;
    color: #454545;

    .LoadIndexTableRow__dotStatus {
      background-color: var(--ion-color-crimson-tint);
      box-shadow: 0 0 10px var(--ion-color-crimson);
    }
  }

  &.no-collection {
    background-color: #ffeca0;
    color: #454545;

    .LoadIndexTableRow__dotStatus {
      background-color: #ffc240;
      box-shadow: 0 0 10px #ebb747;
    }
  }

  &.updated {
    background-color: var(--ion-color-ice);
    color: #454545;

    .LoadIndexTableRow__dotStatus {
      background-color: var(--ion-color-concord-tint);
      box-shadow: 0 0 10px var(--ion-color-concord);
    }
  }
}

.LoadIndexTableRow__menuActions {
  vertical-align: middle;
  cursor: pointer;
  color: var(--ion-color-concord);
  font-size: 20px;
}

.CustomForm__productGroup {
  color: grey;
  width: 100%;
}

.TypeButton__popover {
  --width: 450px;
}

.TypeButton__popoverItem {
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }

  &:hover {
    --background: #f8f8f8;
  }
}

.TypeButton__button {
  ion-label {
    opacity: 1 !important;
  }
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}

.shimmer-div {
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  animation: shimmer 2.5s normal;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.dropdown-menu {
  position: fixed !important;
}
