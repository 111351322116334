.ParserDataTooltipContent__root {
  font-size: 12px;

  .ParserDataTooltipContent__item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.ParserDataTooltipContent__itemValue {
  cursor: pointer;
  &:hover {
    background-color: rgb(251, 242, 230);
  }
}
