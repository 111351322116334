.CompanyTable__container {
  font-size: 14px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);
  margin: 16px;

  @media (max-width: 600px) {
    margin: 0;

    .CompanyTable__header {
      padding: 8px 8px 0 !important;

      .CompanyTable__wrapTabsSection {
        padding-bottom: 4px !important;
      }
    }

    .CompanyTable__filterSection {
      margin: 0 !important;
    }
  }

  .CompanyTable__header {
    padding: 16px 24px 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .CompanyTable__headerTitleContainer {
      .CompanyTable__headerTitle {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        @media (max-width: 570px) {
          display: block;

          .CompanyTable__countData {
            margin-left: 0 !important;
            margin-top: 8px !important;
          }
        }

        .CompanyTable__wrapMainTitle {
          cursor: pointer;

          .CompanyTable__mainTitle {
            font-size: 19px;
          }

          .CompanyTable__titleDropdownIcon {
            margin-left: 8px;
            transform: rotate(90deg);
          }
        }

        .CompanyTable__countData {
          height: 24px;
          margin-left: 8px;
          cursor: default;
        }
      }

      .CompanyTable__headerDescription {
        color: #6f767e;
        font-size: 14px;
        text-transform: none;
      }
    }

    .CompanyTable__headerButtonContainer {
      .CompanyTable__headerButton {
        height: 40px;
        min-width: 140px;
        margin-right: 0;
        font-size: 15px;
        letter-spacing: 0;
        text-transform: none;
        --border-radius: 12px;

        &.isMobile {
          min-width: 60px;
          max-width: 60px;
        }
      }
      .CompanyTable__headerSmallButton {
        height: 30px;
        min-width: 120px;
        margin-right: 0;
        font-size: 13px;
        letter-spacing: 0;
        text-transform: none;
        --border-radius: 12px;

        &.isMobile {
          min-width: 40px;
          max-width: 40px;
        }
      }
    }
  }

  .DataGrid__bodyContainer {
    color: #6f767e;
  }

  .CompanyTable__wrapTabsSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 990px) {
      justify-content: center;
      padding-bottom: 16px;
    }

    .CompanyTable__tabsSection {
      display: flex;
      justify-content: flex-end;
    }
  }

  .CompanyTable__filterSection {
    margin: 0 12px;
    display: flex;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .CompanyTable__dataGridContainer {
    // &.DataGrid__container {
    //   border-radius: 10px;
    // }

    // .DataGrid__headerContainer {
    //   border-bottom: none !important;

    //   .DataGrid__header {
    //     border-bottom: none !important;

    //     .DataGrid__headerItem {
    //       height: 56px;
    //     }
    //   }
    // }

    // .DataGrid__row {
    //   border: none !important;
    // }
  }
}

.CompanyTable__contextMenu {
  .ContextMenu__menuItem {
    max-height: 420px;
    overflow: auto;
  }
}
