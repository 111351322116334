@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.TerminalContainer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;

  .ConcordFormLayout__label {
    display: none;
  }

  .addressInput {
    width: 100%;

    .ConcordFormDropdownV2__control {
      min-height: 40px !important;
      border: none;
      box-shadow: none;
    }

    .ConcordFormDropdownV2__input {
      opacity: 1 !important;
    }

    .ConcordFormDropdownV2__indicators {
      display: none !important;
    }
  }
}

.TerminalContainer__addressInput {
  font-size: 14px;
  height: 35px;
  padding: 4px 8px;
  outline: none;
}

.TerminalContainer__mapContainer {
  .MapView__containerElement {
    height: calc(100vh - 135px);
  }
  .mapElementContainer {
    height: 100% !important;
  }
}

.DialogTerminalDetails__container {
  .modal-body {
    padding: 0;
  }

  .TerminalContainer__header {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MapView__containerElement {
    height: calc(100vh - 90px);
  }
}
