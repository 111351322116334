.CompanyItemCard__container {
  border: 1px solid #e5e5e5;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  border-color: #e5e5e5;
  position: relative;

  &.selected {
    border-color: var(--bs-primary);
  }

  .CompanyItemCard__closeIcon {
    position: absolute;
    top: -10px;
    right: -6px;
  }

  .CompanyItemCard__indexBadge {
    position: absolute;
    top: -4px;
    left: -4px;
    font-size: 8px;
  }
}
