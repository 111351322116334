.RTTableContainer__container {
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.RTTableContainer__container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.RTTableContainer__container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
