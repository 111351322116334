.ConcordFormRadio__container {
  display: flex;
  align-items: center;
  height: 100%;
}

.ConcordFormRadio__label {
  margin-right: 8px;
}

.ConcordFormRadio__optionContainer {
  display: flex;
  align-items: center;
}

.ConcordFormRadio__option {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.ConcordFormRadio__optionRadio {
  margin-right: 8px;
}

.ConcordFormRadio__optionLabel {
  font-size: 14px;
}

.Storybook__ConcordFormRadio__root {
  align-items: initial;

  .ConcordFormRadio__optionContainer {
    display: block;
  }
}
