.SelectSearchDropdown__dropdownIndicator {
  display: flex;
  align-items: center;
}

.SelectSearchDropdown__dropdownToogle {
  text-decoration: none !important;
  // width: 100%;
}

.SelectSearchDropdown__dropdownIndicatorIcon {
  cursor: pointer;
  &.collapsed {
    transform: rotate(180deg);
  }
  .react-select__indicator {
    padding: 0 4px 0 0;
  }
}

.SelectSearchDropdown__editIcon {
  font-size: 14px;
  margin-top: 4px;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}
