.TextFieldEditInline__input,
.TextFieldEditInline__contentText {
  padding: 0 4px;
  width: 100%;
  height: 100%;
}

.TextFieldEditInline__form {
  height: 100%;
}

.TextFieldEditInline__input {
  border: none;
  outline: none;
  background: transparent;
  height: 100%;
  color: #1090ff;

  &:focus {
    background-color: white;
    border: 1px solid #1090ff;
    &:hover {
      background-color: white;
      border: none;
    }
  }
}

.TextFieldEditInline__contentText {
  display: flex;
  align-items: center;
}
