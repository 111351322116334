.PdfPageSelector__container {
  display: flex;
  align-items: center;
}

.PdfPageSelector__controlPage {
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  .icon {
    height: 13px;
  }

  .PdfPageSelector__controlIcon {
    cursor: pointer;
    font-size: 13px;
  }
}
