@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.DocumentView__controlPdfContainer {
  text-align: center;
  display: flex;
  align-items: center;

  .ZoomButton__container {
    margin-left: 12px;
  }

  .PdfPageSelector__container {
    margin-left: 8px;
  }

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
  // justify-content: space-between;
}

.DocumentView__divider {
  width: 2px;
  height: 14px;
  background-color: rgb(119, 119, 119);
  margin-left: 12px;
}

.DocumentView__extraButtons {
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.DocumentView__wrapImageViewer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
