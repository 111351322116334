.Header__container {
  z-index: 999;
}

.Header__searcIcon {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #747474;
}

.Header__internetButton {
  display: inline-block;
  position: relative;
  margin-left: 4px;

  &.hasError {
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: red;
      top: -2px;
      right: -2px;
      border-radius: 50%;
    }
  }
}

.Header__popover {
  &::part(content) {
    min-width: 285px;
  }
}
