.ReusableTimeField__datePicker {
  display: block;
  width: 100%;
  position: relative;

  .react-datepicker__calendar-icon {
    position: absolute;
    top: 2px;
    right: 0;
  }
}

.ReusableTimeField__dateInput {
  font-size: 14px !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  max-height: 41px;
}

.ReusableTimeField__popperClassName {
  max-width: 230px;

  .react-datepicker__day-names,
  .react-datepicker__month {
    font-size: 13px;
  }
  .react-datepicker__header {
    padding: 8px !important;
    border-bottom-width: 0;
    background-color: white;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 14px;
    margin: 4px;
  }

  .react-datepicker__month-container {
    float: none !important;
  }

  .react-datepicker__children-container {
    margin: 12px 0;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 140px;
    display: block;
    font-size: 13px;
    color: var(--ion-color-concord);
  }

  .react-datepicker--time-only {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ConcordFormDropdownV2__value-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .wrapChildren {
      width: 100%;
    }
  }

  .ReusableTimeField__timeInputContainer {
    position: relative;
    margin-top: 8px;

    .ReusableTimeField__timeInput {
      width: 100% !important;
      border: 1px solid #e5e5e5;
      padding: 4px;
      color: #000000;
    }

    .ReusableTimeField__searchButton {
      position: absolute;
      right: 8px;
      top: 3px;
    }
  }
}
