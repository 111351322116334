.ConcordFormAddressInput__container {
  .ConcordFormDropdownV2__input {
    opacity: 1 !important;
  }

  .timeZoneOptions {
    width: unset !important;
    .ConcordFormDropdownV2__input-container {
      position: absolute;
      right: 0;
    }
  }
}

.AddressInput__option {
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #e5e5e5;
  }
}

.ConcordFormAddressInput__map {
  padding: 8px;
  border: 1px dashed #999999;
  border-radius: 4px;

  .MapView__containerElement {
    height: 300px;
  }
}
