.CheckSignatoryForm__container {
  border: 1px solid #e5e5e5;
  padding: 8px;

  &.isShowBanner {
    padding: 0;
    border: none;
  }

  .formBody {
    display: block;

    &.isHidden {
      display: none;
    }

    .headerContainer {
      display: flex;
      align-items: center;

      .designateSignerDropdown {
        display: flex;
        align-items: center;

        .ConcordFormLayout__label {
          font-weight: 600;
          margin-right: 8px;
          min-width: 115px;
        }

        .ConcordFormDropdownV2__control {
          min-width: 150px;
        }
      }

      .otherSignersDropdown {
        width: 250px;
        margin-left: 12px;

        &:hover {
          border: none;
        }
      }
    }
  }
}

.CheckSignatoryForm__banner {
  font-size: 14px;
  display: none;
  align-items: center;
  margin-bottom: 0 !important;

  &.isShowBanner {
    display: block;
  }

  .changeButton {
    margin-left: 8px;
    vertical-align: middle;

    ion-icon {
      vertical-align: middle;
      margin-bottom: 4px;
    }
  }

  .CompanyAvatar__container {
    // margin-left: 8px;
  }

  .fullName {
    margin-left: 4px;
  }
}

.CheckSignatoryForm__loading {
  .item {
    margin-bottom: 4px;
  }
}

.CheckSignatoryForm__tooltip {
  .tooltip-inner {
    max-width: 500px;
  }
}
