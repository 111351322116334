.TagsMenu__root {
  .szh-menu {
    background-color: white;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1) !important;
  }

  .szh-menu__item {
    font-size: 14px;
  }
}

.TagsMenu__searchBar {
  margin: 8px;
}

.TagsMenu__tagList {
  min-height: 200px;
  max-height: 250px;
  overflow: auto;
}
