.ZoomButton__container {
  display: flex;
  align-items: center;
  // justify-content: space-evenly;
}

.ZoomButton__controlPage {
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  .icon {
    height: 13px;
  }

  .ZoomButton__controlIcon {
    cursor: pointer;
    font-size: 13px;
  }
}
