.SectionEmailParsers__container {
  padding: 8px 0;
}

.SectionEmailParsers__header {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-bottom: 8px;
  padding: 8px;
  padding-bottom: 0;
  width: 600px;
  height: 45px;

  .SectionEmailParsers__searchBar,
  .SectionEmailParsers__titleContainer,
  .SectionEmailParsers__createButton {
    display: inline-block;
    vertical-align: middle;
  }

  .SectionEmailParsers__searchBar {
    // margin: 0 12px;
    transition: width 0.25s ease-in-out;
    width: 180px;

    &.isFocusingSearchBar {
      width: 275px;
      margin: 0 !important;
    }
  }

  .SectionEmailParsers__titleContainer {
    font-size: 16px;
    margin-right: 8px;
    position: relative;

    .SectionEmailParsers__title {
      margin-right: 4px;
    }

    .SectionEmailParsers__badgeTotal {
      font-size: 10px;
    }

    .SectionEmailParsers__badgeTotal,
    .SectionEmailParsers__title {
      display: inline-block;
      vertical-align: middle;
      // position: absolute;
      // right: 0;
      // top: 0;
    }
  }

  .SectionEmailParsers__createButton {
    margin: 0;
    margin-left: 24px;

    &.isFocusingSearchBar {
      display: none;
    }

    &.hideSearchBar {
      position: absolute;
      right: 10px;
    }
  }
}

.SectionEmailParsers__body {
  max-height: 410px;
  overflow: auto;
}

.SectionEmailParsers__confirmButton {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;

  &:hover {
    background: transparent;
    border: none;
    padding: 0;
  }
}

.SectionEmailParsers__createIcon {
  transition: all 0.175s linear;
  &.close {
    transform: rotate(-45deg);
  }
}

.SectionEmailParsers__hideFormParser {
  display: none;
}
