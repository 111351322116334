.CardsForm__container {
  margin: 0 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 8px;
}

.CardsForm__form {
  border: 1px solid #e5e5e5;

  &.ownersForm {
    margin-bottom: 8px;
  }
}

.CardsForm__addressFormContainer {
  padding: 0;
}

.CardsForm__addressForm {
  padding: 0;

  .ConcordFormStructure__buttonContainer {
    padding: 0 4px;
  }
}

.CardsForm__ownersSection {
  .headerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;

    .createIcon {
      font-size: 22px;
      margin-left: 8px;
      display: inline-flex;
    }
  }
}

.CardsForm__optBadge {
  margin-left: 4px;
}
