.ConcordFormLayout__container {
  font-size: 14px;

  &.hasError {
    .form-label {
      color: red;
    }
  }

  .form-control {
    font-size: 14px;
  }
  .form-control-color {
    width: 100%;
  }

  .spinner {
    margin-left: 8px;
    width: 1.5rem;
    height: 1.5rem;
    border-width: 2px;
  }

  .hintText {
    font-size: 12px;
    color: var(--ion-color-fleet);
    margin-bottom: 4px;
  }
}

.ConcordFormLayout__asterisk {
  color: red;
  vertical-align: middle;
}

.ConcordFormLayout__label {
  span {
    vertical-align: middle;
  }
}

.ConcordFormLayout__createIcon {
  font-size: 22px;
  margin-left: 8px;
  display: inline-flex;
}
