.KeyValueFormFieldLayout__formContainer {
  min-height: 38px;
  padding: 8px 0;
  &.disabled {
    opacity: 0.85;
    pointer-events: none;
    cursor: progress;
  }
}

.KeyValueFormFieldLayout__label {
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  // font-size: 14px;

  &.viewOnly {
    pointer-events: none;
  }
}

.KeyValueFormFieldLayout__inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.viewOnly {
    pointer-events: none;
  }
}

.KeyValueFormFieldLayout__icon {
  font-size: 18px;
  cursor: pointer;
  color: var(--ion-color-concord);
  font-weight: 600;
  margin-left: 8px;

  &.delete {
    color: red;
  }
}

.KeyValueFormFieldLayout__input {
  height: 38px;
  font-size: 14px !important;
  border-radius: 4px;
  width: 100% !important;

  &:hover {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  &.viewOnly {
    pointer-events: none;
  }
}
