.TerminalCustomOption__root {
  display: flex;
  align-items: center;

  .TerminalCustomOption__icons {
    // display: flex;
    // align-items: center;
    // margin-right: 8px;
    // font-size: 16px;
    // font-weight: 600;

    // .icon {
    //   &:not(:first-child) {
    //     margin-left: 6px;
    //   }
    // }
  }
}
