.RTTableFooterActions__container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  &.isCollapsed {
    background-color: transparent;
    z-index: 3;
    width: initial;
  }

  .RTTableFooterActions__collapseExpand,
  .RTTableFooterActions__buttonActions {
    height: 100%;
  }

  .RTTableFooterActions__collapseExpand {
    .RTTableFooterActions__collapseExpandIcon {
      transition: all 0.2s ease-in-out;

      &.isOpenButtons {
        transform: rotate(180deg);
      }
    }
  }

  .RTTableFooterActions__buttonActions {
    overflow: auto;
    position: relative;
    width: 100%;
    padding-bottom: 0;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .RTTableFooterActions__toolbarSelectedRows {
      margin-top: 4px;
      margin-left: 4px;
      transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

      position: absolute;
      z-index: 999999;
      top: 0;
      left: 0;
      width: 100%;

      &.isCollapsed {
        left: -3000px;
        background: transparent;
      }
    }
  }

  .RTTableFooterActions__buttonActions::-webkit-scrollbar {
    display: none;
  }

  .RTTableFooterActions__collapseExpand {
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    opacity: 0.456;

    &:hover {
      opacity: 1;
    }
  }
}
