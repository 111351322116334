.OrderCardOnMap__container {
  position: absolute;
  z-index: 99999;
  left: 12px;
  bottom: 30px;
  background-color: white;
  padding: 16px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 14px;

  .chip {
    background-color: var(--ion-color-concord);
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
  }

  .body {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 8px;

    .progress-bar {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      .truckName {
        font-size: 16px;
      }

      .percentage {
        font-size: 14px;
        color: var(--ion-color-fleet);
      }
    }

    .loadInfo {
      margin-left: 12px;

      .loadBuyerTerminalName {
        font-size: 16px;
        font-weight: 600;
        padding: 4px 0;
      }

      .loadBuyerName {
        padding-bottom: 4px;
      }
    }
  }

  .footer {
    padding: 0 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .order {
      font-size: 16px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
