.RTTableHeadCellGroupHandle__container {
  cursor: grab;
  margin: 0 -0.1rem;
  padding: 2px;
  transition: all 150ms ease-in-out;

  &:active {
    cursor: 'grabbing';
  }
  &:hover {
    background-color: transparent;
    opacity: 1;
  }
}

.RTTableHeadCellGroupHandle__icon {
  // margin-bottom: 2px;
}
