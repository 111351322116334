.DateRangePicker__dialog {
  .modal-dialog {
    max-width: 650px;
  }

  .modal-body {
    padding: 0;
  }

  .rdrDateRangePickerWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.DateRangePicker__notSetMessage {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  right: 0;
  font-size: 22px;
  background-color: rgba(195, 195, 195, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.175);
}

.DateRangePicker__notsetCheckbox {
  .form-check-label {
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 12.5px;
    }
  }
}
