.NumberFieldEditInline__input,
.TextFieldEditInline__contentText {
  padding: 0 4px;
  width: 100%;
  height: 100%;
  color: #1090ff;
}

.NumberFieldEditInline__form {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.NumberFieldEditInline__input {
  border: none;
  outline: none;
  background: transparent;
  height: 100%;
  width: 100%;

  &:focus {
    background-color: white;
    border: 1px solid #1090ff;
    &:hover {
      background-color: white;
      border: none;
    }
  }
}

.NumberFieldEditInline__contentText {
  display: flex;
  align-items: center;
}

.missing-field {
  color: red !important;
}

.greyed-field {
  color: #a7a7a7 !important;
}
