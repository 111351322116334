.DriverFleetList__container {
  margin: 10px 10px 8px;
  border: 1px solid #e5e5e5;
  padding: 8px;
  border-radius: 4px;

  .driverFleetList {
    max-height: 412px;
    overflow: auto;
  }

  .searchBar {
    margin-bottom: 8px;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-check-label {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.DriverFleetList__driverCard {
  .indexBadge {
    left: 0 !important;
  }
}
