.PdfSplitting__pageGroupInput {
  display: inline-block;
  font-size: 14px;
  padding-right: 8px;
}

.PdfSplitting__pageGroupInputLabel,
.PdfSplitting__pageGroupInputField {
  display: inline-block !important;
}

.PdfSplitting__pageGroupInputField {
  width: 50px !important;
  font-size: 14px !important;
  outline: none !important;
  box-shadow: none !important;

  &.error {
    border-color: red !important;
  }
}

.PdfSplitting__pageGroupInputLabel {
  margin-right: 8px;
}

.PdfSplitting__pageGroupSubmitButton {
  font-size: 14px !important;
}

.PdfSplitting__form {
  margin-bottom: 6px;
}
