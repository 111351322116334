.ConcordFormMultipleDropdownSelector__dropdown-indicator {
  padding: 0 !important;
  margin-right: 4px;
}

.ConcordFormMultipleDropdownSelector__clear-indicator {
  padding: 0 !important;
}

.ConcordFormMultipleDropdownSelector__indicator-separator {
  display: none;
}

.ConcordFormMultipleDropdownSelector__control {
  min-height: 30.5px !important;
  // height: 30.5px !important;
}

.ConcordFormMultipleDropdownSelector__value-container {
  padding: 0 !important;
  padding-left: 4px !important;
}

.ConcordFormMultipleDropdownSelector__menu {
  font-size: 13px;
}
