.ActionButtons__container {
  justify-content: center;
}

.ActionButtons__button {
  height: 30px;
  width: 35px;

  &.submit {
    width: auto;
  }
}
