.mapCol {
  .GoogleMapView__containerElement {
    max-width: 300px;
    height: 20vh !important;
    .mapElementContainer {
      height: 20vh !important;
    }
  }
}

.RouteCard__mapContainer {
  .MapView__containerElement {
    height: 220px;
  }
}
