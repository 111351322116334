.DriverCard__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px;
  padding-right: 8px;
  position: relative;
  min-width: 135px;
  background-color: white;
  border: 1px solid #e5e5e5;

  .closeIcon {
    position: absolute;
    top: -7px;
    right: -6px;
    cursor: pointer;
  }

  .indexBadge {
    position: absolute;
    top: -7px;
    left: -8px;
    width: 16px;
    height: 16px;
    background: var(--ion-color-warning);
    text-align: center;
    color: black;
    border-radius: 20px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bodyContainer {
    .terminalSection,
    .truckNumber {
      display: inline-block !important;
      vertical-align: middle;
      cursor: pointer;
    }

    .terminalSection {
      padding-top: 2px;

      .CompanyAvatar__initialName {
        font-size: 10px;
      }
    }
    .driverAvatar {
      display: inline-flex;
      vertical-align: middle;
      cursor: pointer;
      align-items: center;
    }

    .driverFullName {
      margin: 0 4px;
      color: rgb(102, 102, 102);
      font-size: 14px;
      font-weight: 600;
    }

    .statusBadge {
      display: inline-block;
      vertical-align: middle;
    }

    .terminalAvatarContainer {
      padding-left: 4px;
      padding-right: 4px;
      .terminalAvatar {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        margin-right: 4px;

        .CompanyAvatar__initialName {
          font-size: 12px;
        }
      }
    }
  }
}

.DriverCard__terminalCreateButton {
  height: auto;
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.DriverCard__truckSelector {
  &:hover {
    border: none;
    background-color: transparent;
  }

  .ConcordFormDropdownV2__input-container {
    position: absolute;
  }

  .ConcordFormDropdownV2__value-container {
    padding-left: 0 !important;
  }

  .ConcordFormDropdownV2__multi-value {
    display: none;
  }

  &.DropdownWithCustomChildren__container {
    display: inline-block;
    vertical-align: middle;
    width: initial !important;
  }
}

.DriverCard__truckTerminalAvatar {
  .CompanyAvatar__initialName {
    font-size: 10px;
  }
}

.DriverCard__avatar {
  .CompanyAvatar__initialName {
    font-size: 11px;
  }
}
