.CompanyFormatOptionLabel__container {
  display: flex;
  align-items: center;

  .CompanyFormatOptionLabel__label {
    padding-left: 12px;
    display: inline-block;
    vertical-align: middle;
  }
}
