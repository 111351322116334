.RTGrabHandleButton__container {
  cursor: grab;
  margin: 0 -0.1rem;
  padding: 2px;
  transition: all 150ms ease-in-out;

  &:active {
    cursor: 'grabbing';
  }
  &:hover {
    background-color: transparent;
    opacity: 1;
  }
}

.RTGrabHandleButton__icon {
  width: 18px;
  height: 16px;
  margin-bottom: 2px;
}
