.RecommendedOptions__root {
  font-size: 13px;
  min-width: 300px !important;

  .RecommendedOptions__body {
    padding: 8px;

    .RecommendedOptions__wrapOption {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-child) {
        margin-top: 8px;
      }

      .RecommendedOptions__option {
        &:hover {
          color: rgb(150, 150, 150);
        }
      }
    }
  }
}
