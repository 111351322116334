.KeyValueList__wrapContainer {
  max-height: 456px;
  overflow: auto;
  background-color: white;
}

.KeyValueList__container {
  // height: 100%;
  padding-top: 0;
}

.KeyValueList__form {
  font-size: 14px;
  padding: 12px;
  padding-top: 0;
}

.KeyValueList__fieldContainer {
  position: relative;
}

.KeyValueList__input {
  margin-right: 1.75rem;
}

.KeyValueList__confirmIcon {
  font-size: 15px;
  position: absolute;
  right: -8px;
  top: 55%;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.KeyValueList__searchBar {
  padding: 8px;
  padding-bottom: 0;
  padding-top: 12px;
  height: 45px !important;
  // width: 100%;
}

.KeyValueList__searchBarRoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 0;
}

.KeyValueList__viewHash {
  font-size: 14px;
}
