.CheckoutForm__container {
  .CheckoutForm__tabContainer {
    font-size: 14px;
    margin-top: 12px;
  }

  .tab-content {
    margin-top: 20px;
  }
}
