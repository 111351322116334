$greyColor: #999;
$lightGreyColor: #999;
$mercuryColor: #e8e8e8;
$signaturePad-shadow: 0 1px 4px rgba(0, 0, 0, 0.27),
  0 0 40px rgba(0, 0, 0, 0.08) inset;

.charges-table {
  padding: 0;
  text-align: left;
}

.load-section {
  font-size: 12px;

  .item-label {
    p {
      color: $greyColor;
      margin-top: 0.5rem;
    }
  }
}

.view-map-container {
  display: flex;
  margin: auto;

  a {
    align-self: center;
    font-size: 1.1em;
  }
}

.action-item {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.action-icon {
  align-self: flex-end;
}

.shipping-actions {
  margin-top: 5vh;
}

.shipping-checks-wrapper {
  margin: 0.5vh 0;

  ion-chip {
    margin-left: 0;
  }
}

.map-link {
  font-size: 1.4rem;
}

.truck_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.signature-pad {
  border: 4px solid $mercuryColor;
  box-shadow: $signaturePad-shadow;
  display: flex;
  flex-direction: column;
  height: 50vh;
  position: relative;
  width: 100%;
}

.signature-pad--body {
  flex: 1;
  position: relative;
}

.signature-pad--body canvas {
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  left: 0;
  position: absolute;
  top: 0;
}

// NOTE MODAL

.note-card-container {
  margin: 4vh 0;
}

.note-add {
  height: 8vh;
  position: fixed;
  width: 100%;
  z-index: 999;
}

// NOTE CARD

.note-card {
  .note-card--wrapper {
    padding-bottom: 0.5vh;
  }

  .note-card--header {
    margin-bottom: 3vh;

    img {
      border-radius: 100%;
      display: inline;
      height: auto;
      margin-right: 5vw;
      width: 10vw;
    }

    .author {
      align-content: center;
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .note-card--footer {
    margin-top: 2vh;
  }

  .time-ago {
    color: $lightGreyColor;
    font-size: 1.35rem;
  }
}

.geofence-link {
  background-color: #d63434;
  margin: 4px;
  color: #fff;
  padding: 6px 7px;
  border-radius: 20px;
  display: inline-block;
  text-decoration: none;
}
