@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.DocumentItem__container {
  @include media-breakpoint-up(md) {
    margin: 4px !important;
  }

  margin: 4px !important;
}

.DocumentItem__headerContainer {
  padding: 0;

  embed {
    height: 265px;
    width: 100%;
  }
}

.DocumentItem__pdfContainer {
  padding: 8px;
}

.DocumentItem__headerTitleContainer {
  padding: 16px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .DocumentItem__headerStatus {}

.DocumentItem__dotStatus {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
}

.DocumentItem__contentContainer {
  padding: 16px;
  padding-top: 0;

  @include media-breakpoint-up(md) {
    padding: 8px;
  }
}

.DocumentItem__contentHeader {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown {
    width: auto !important;
  }

  .dropdown-menu.show {
    width: 250px !important;
  }
}

.DocumentItem__menuIcon {
  font-size: 20px;
  cursor: pointer;
}

.DocumentItem__userLink {
  font-size: 14px;
  font-weight: 600;
  margin-left: 6px;
}

.DocumentItem__contentInfoContainer {
  // @include media-breakpoint-up(md) {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DocumentItem__contentIcon {
  margin-right: 8px;
}

.DocumentItem__viewDocument {
  text-transform: capitalize;
  font-weight: bold;
  --padding-start: 8px;
  --padding-end: 8px;
  width: 100%;
  // margin: 0;
}

.DocumentItem__menuItem {
  &.delete {
    color: red;
  }

  &.export {
    color: var(--ion-color-export);
  }
}

.highlighted {
  background-color: rgba(102, 181, 250, 0.3);
}

.DocumentItem__contentLabelWrapper {
  display: flex;
  align-items: center;

  .dropdown {
    width: auto !important;
  }
}
