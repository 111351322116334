.DialogPaymentsForm__formContainer {
  margin-top: 0 !important;
  max-width: initial !important;
}

.DialogPaymentsForm__tableFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  border-top: 1px dashed #e8e8e8;
  padding: 8px;
}

.DialogPaymentsForm__amountContainer {
  margin: 8px 0;
  font-size: 14px;
}

.DialogTicketView__container {
}

.PaymentDataAsPlainText__container {
  margin-bottom: 12px;

  .itemContainer {
    display: inline-block;
    margin-right: 6px;
    vertical-align: middle;
    font-size: 13px;

    .label {
      font-weight: 600;
    }

    .separateDot {
      width: 2px;
      height: 14px;
      background-color: #dadada;
      display: inline-block;
      margin-left: 4px;
      vertical-align: middle;
      margin-bottom: 4px;
    }
  }

  .imageContainer {
    margin-top: 8px;

    img {
      object-fit: cover;
    }
  }

  .editButton {
    vertical-align: middle;
  }
}

.PaymentInvoicesTable__totalPaymentCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.greaterThanZero {
    background-color: #9fccf3;
  }
}
