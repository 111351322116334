.RTCellActionMenu__item {
  font-size: 13px;
  padding: 4px 8px;

  &.subMenu {
    padding: 0;
  }
}

.RTCellActionMenu__itemIcon {
  margin-right: 4px;
}
