.BundleItemCard__container {
  padding: 4px 0;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    border-top: 1px solid #d3d2d2;
  }

  .dragIcon {
    font-size: 28px;
    margin-right: 8px;
    height: 100%;
  }

  .itemLine {
    position: relative;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    height: 24px;

    &:not(:first-child) {
      padding-left: 12px;

      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 1.5px;
        height: 16px;
        background-color: #b3b0b0;
      }
    }

    &.dropdown {
      display: inline-flex;
      align-items: center;

      .wrapChildren {
        font-size: 13px;
      }
    }

    .value {
      cursor: pointer;
    }

    .noValue {
      min-width: 50px;
      border: 1px solid #e5e5e5;
      border-radius: 2px;
      height: 23.5px;
    }
  }

  .itemLineIcon {
    margin-left: 8px;
  }

  .formContainer {
    display: inline-block;

    .inputContainer {
      display: inline-block;
      margin-right: 4px;

      input {
        width: 50px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
      }
    }

    .icon {
      vertical-align: middle;
    }

    .loadingSpinnerForm {
      width: 10px;
      height: 10px;
      vertical-align: middle;
    }
  }
}
