.CompanyForm__addressCheckbox {
  display: block;

  ion-checkbox {
    vertical-align: middle;
  }
}

.CompanyCompanyForm__container {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 8px;
}
