.DialogOffDayForm__container {
  .modal-dialog {
    max-width: 650px;
  }

  .modal-body {
    padding: 0;
  }

  .dateRangeContainer {
    width: 100%;

    .rdrDefinedRangesWrapper {
      display: none;
    }
    .rdrDateRangeWrapper {
      width: 100%;
      border-right: 1px solid #e5e5e5;
    }
    .rdrMonth {
      width: 100%;
    }
  }

  .iconItem {
    padding: 0;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      font-size: 14px;
    }
  }
}
