.react-select__option {
  font-size: 1.4rem !important;
}
.react-select__menu {
  margin: 0.2px !important;
  position: static !important;
}

.react-select-load__option {
  font-size: 1.4rem !important;
}
.react-select-load__menu {
  margin: 0.2px !important;
  position: static !important;
}
.react-select-load__menu-list {
  max-height: 210px !important;
}
