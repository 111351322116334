.DialogEmailContent__wrapDialog {
  --width: 1000px;
}

.DialogEmailContent__container {
  height: calc(100% - 35px);
}

.DialogEmailContent__headerContainer,
.DialogEmailContent__bodyContainer {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 8px;
}

.DialogEmailContent__headerContainer {
  font-size: 15px;
  margin-top: 8px;
}

.DialogEmailContent__itemContainer {
  &:not(:first-child) {
    margin-top: 6px;
  }
}

.DialogEmailContent__itemBlock {
  display: inline-block;

  &:not(:first-child) {
    margin-left: 8px;
    position: relative;

    .DialogEmailContent__label {
      margin-left: 8px;
    }

    &::before {
      content: '';
      position: absolute;
      border-left: 2px solid #d9d9d9;
      left: 0;
      top: 3px;
      width: 20px;
      height: 15px;
    }
  }
}

.DialogEmailContent__label {
  color: #797979;
}

.DialogEmailContent__subject {
  font-weight: 600;
}

.DialogEmailContent__bodyContainer {
  font-size: 15px;
  height: 315px;
  overflow: auto;
}

.DialogEmailContent__bodyContent {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  resize: none;
}

.DialogEmailContent__sellerRegex,
.PopoverSelection__sellerRegex {
  color: var(--ion-color-concord);
}

.DialogEmailContent__sellerRegex {
  .DialogEmailContent__buyerRegex {
    color: orange;
  }

  .DialogEmailContent__docTypeRegex {
    color: orange;
  }
}

.DialogEmailContent__buyerRegex {
  .DialogEmailContent__sellerRegex {
    color: orange;
  }

  .DialogEmailContent__docTypeRegex {
    color: orange;
  }
}

.DialogEmailContent__docTypeRegex {
  .DialogEmailContent__sellerRegex {
    color: orange;
  }

  .DialogEmailContent__buyerRegex {
    color: orange;
  }
}

.DialogEmailContent__buyerRegex,
.PopoverSelection__buyerRegex {
  color: lightgreen;
}

.DialogEmailContent__buyerRegex {
  .DialogEmailContent__docTypeRegex {
    color: orange;
  }
}

.PopoverSelection__docTypeRegex,
.DialogEmailContent__docTypeRegex {
  color: red;
}

.DialogEmailContent__sellerDropdown {
  display: inline-block;
  margin-left: 8px;
  .SelectSearchDropdown__dropdownToogle {
    display: inline-block;
  }

  .DialogEmailContent__sellerCol {
    display: inline-block;

    .CompanyAvatar__wrapInitialName {
      font-size: 12px;
    }
  }

  .DropdownSellerForm__container {
    width: 247px;
  }
}

.DocumentEmailContent__dialog {
  display: flex;
  align-items: center;

  & > .ConcordFormLayout__container {
    margin-left: 8px;
  }
}
