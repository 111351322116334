.OrderFormDialog__container {
  .dialogTitle {
    display: flex;
    align-items: center;

    .item {
      margin-right: 12px;
    }
  }
}

.OrderFormDialog__body {
}

.OrderFormDialog__tabContainer {
  position: relative;
  .CommonTab__root {
    max-width: calc(100% - 40px);

    .nav-item {
      min-width: 150px;
    }
  }

  .addButton {
    position: absolute;
    right: 0;
    top: 2px;
  }
}
