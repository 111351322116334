.CalculationVarsSection__container {
  padding-right: 0;
  padding-left: 0;
  border-top: 1px dashed #c0bcbc;

  .FloatingSelectV2_text {
    font-size: 13px;
  }
}

.CalculationVarsSection__tagInput {
  .token-input-container {
    height: 30.5px;

    .token-input-autosized-wrapper {
      input {
        height: 22px !important;
      }
    }

    .token-input-token--editable {
      height: 21px !important;
    }
  }
}

.CalculationVarsSection__accordion {
  .accordion-body {
    padding: 4px;
  }
}
