.ToggleSection__container {
  .tabHeader {
    font-size: 16px;
    padding: 12px 0;
    font-weight: 600;
    color: #505050;

    .headerIcon {
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
    }
  }

  .childrenSection {
    display: none;
    margin-left: 18px;

    &.isOpenSection {
      display: block;
    }
  }
}

.ToggleSection__iconTooltip {
  .tooltip-inner {
    text-align: left;
    max-width: 400px;
  }
}

.ToggleSection__spinner {
  display: inline-block;
  vertical-align: middle;
}
