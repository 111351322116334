.ReusableTimeRange__container {
  border: 1px solid #ccc !important;
  display: flex;
  align-items: center;
  .ReusableTimeField__dateInput {
    border: none !important;
    box-shadow: none !important;
    text-align: center;
  }
}

.ReusableTimeRangeInFilter__container {
  .dropdown-menu {
    border: none;
  }
}
