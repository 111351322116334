.ButtonDropdown__labelContainer {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  min-width: 80px;
}

.ButtonDropdown__dropdownIcon {
  cursor: pointer;
  margin-left: 4px;
  color: #939393;
}
