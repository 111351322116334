.Storybook__AddressTextBox__container {
  max-width: 200px;

  .Storybook__AddressTextBox__input {
    padding: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 4px;
    font-size: 12px;
  }
}
