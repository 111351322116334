.SalesProposalCard__container {
  display: inline-block;
  border: 1px solid #e5e5e5;
  padding: 6px;
  border-radius: 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  min-width: 200px;
}

.SalesProposalCard__input {
  width: 80px;
  border: none;
  border: 1px solid #e5e5e5 !important;
  outline: none;
  border-radius: 4px;
  padding: 2px;
}
