.EditableIonRow {
  .BillLinesAddButton {
    font-weight: 800;
    font-size: 0.8em;
    margin: 0;
    padding: 0;
    width: 100%;
    color: var(--ion-color-concord);
  }
  .BillLinesInput {
    width: 80px;
  }
  .productName {
    width: 100%;
  }
  .ActionButton {
    display: flex;
    align-items: center;
    width: 38px;
    height: 28px;
  }
  .NumberButton {
    padding: 0 !important;
    margin: 0 !important;
  }
  .BillLinesColumn {
    display: 'none' !important;
  }
  .ion-card {
    justify-content: 'flex-start';
    align-items: 'center';
  }
}

.BillLinesTable
  ion-grid
  ion-row.EditableIonRow
  ion-card
  ion-col:nth-of-type(9) {
  max-width: 15rem !important;
  justify-content: flex-end;
  .product-col {
    overflow: auto;
  }
}

.EditableIonRow__cell {
  padding: 0 4px !important;
  &.highLightCell {
    background-color: #c1e2ff;
    border-right: 1px solid #1090ff;
    border-left: 1px solid #1090ff;
  }
}

.EditableTableRow__matchedIcon {
  font-size: 16px;
  margin-left: 6px;
  margin-top: 2px;
  padding-left: 0;
  margin-bottom: 4px;
  cursor: default;

  &.unmatch {
    color: rgb(255, 40, 40);
  }
}

.EditableTableRow__showIndex {
  padding: 0px !important;
  margin-right: 8px;
  color: rgb(0, 0, 0);
  font-weight: 600px;
}

.EditableTableRow__datePicker {
  border: none;
  background: transparent;
  color: var(--ion-color-concord) !important;
  font-size: 13px !important;
}
