input {
  // border:none;
  background: transparent;
  border: none;
  border-bottom: 1px solid lightgray;
}

.TextToggleInput__cellInput {
  border: none !important;
  width: 100%;
}
