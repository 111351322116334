.PaymentCard__container {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding: 8px;
  border-radius: 4px;

  .headerContainer {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }

  .bodyContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 600;

    .amount {
      color: var(--ion-color-fleet);
    }

    .paydate {
      color: var(--ion-color-orange);
    }

    .transactionId {
      display: inline-block;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }

  .footerContainer {
    margin-top: 8px;
    padding-top: 4px;
    border-top: 1px solid #f3f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
