.TimeSlotCard__container {
  position: relative;
  padding: 8px 4px;
  &:not(:first-child) {
    border-top: 1px dashed #e5e5e5;
  }

  .leftColorBadge {
    width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
