.ToggleSection__container {
  .headerToggleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .label {
      font-size: 14px;
      position: relative;
      font-weight: 600;
      display: flex;
      align-items: center;

      .labelIcon {
        display: inline-block;
        height: 14px;
        margin-right: 4px;
      }
    }
  }

  .bodyContainer {
    margin-bottom: 8px;
  }
}

.ToggleSection__icon {
  font-size: 16px;
  margin-left: 4px;
  display: inline-block;
  height: 18px;
}
