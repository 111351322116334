.ToolbarSelectedRows__root {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100% - 10px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ToolbarSelectedRows__root::-webkit-scrollbar {
  display: none;
}
