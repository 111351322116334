.ConcordFormColorPicker_root {
  display: flex;

  .ConcordFormColorPicker_wrapInput {
    margin-left: 8px;
  }
}

.Storybook__ConcordFormColorPicker__root {
  .ConcordFormLayout__label {
    color: blue;
  }
}
