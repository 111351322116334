.DocumentPicker__addDocument {
  margin-left: 8px;
}

.DocumentPicker__uploadContainer {
  width: 100%;
  padding: 8px 16px;
}

.DocumentPicker__customViewContainer {
  padding: 8px;
  width: 300px;
  .body-grid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .DocumentReview__customViewImage {
    height: 30px;
    width: 40px;
  }
}

.Storybook__DocumentPicker__root {
  .StorageFilesPicker__root {
    flex-direction: column !important;
    align-items: initial !important;
  }
}

.DocumentPicker__documentPreview {
  white-space: nowrap;
  font-size: 0;
  overflow: auto;
}
