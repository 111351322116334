.DriverCompanySign__container {
  .listItems {
    max-height: 220px;
    min-height: 220px;
    overflow: auto;

    .list-group-item {
      display: flex;
      align-items: center;

      .form-check-input {
        margin-right: 8px;
      }
    }
  }
}
