.DialogUserGroupForm__form {
  padding-left: 0 !important;
  .my-company-form-field,
  .my-company-select-field {
    width: initial;
  }

  .my-company-submit-button {
    width: 100%;
  }
}
