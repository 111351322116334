.DialogSplitInvoice__container {
  --width: 1100px;
  --height: 535px;
}

.DialogSplitInvoice__controlPdf {
  // height: 60%;
}

.DialogSplitInvoice__viewPdfs {
  border-left: 1px solid #e5e5e5;
  padding-left: 8px;
  max-height: 465px;
  overflow: auto;

  .PdfViewer__pdfContainer {
    max-height: 260px;
    min-height: 260px;
  }
}

.DialogSplitInvoice__pdfItem {
  margin: 0;
  cursor: pointer;
  border: 2px solid #cfcfcf;
  width: 300px;

  margin-right: 8px;

  &:hover {
    border-color: #707070;
  }

  &.selected {
    border-color: lightgreen;
  }
}

.DialogSplitInvoice__toggleOriginalDoc {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e5e5;

  button {
    font-size: 14px;
  }
}
