.PasswordField__container {
}

.PasswordField__inputContainer {
  position: relative;

  .eyeIcon {
    cursor: pointer;
    position: absolute;
    right: 9px;
    top: 4px;
    color: var(--ion-color-medium);
    opacity: 0.75;

    &.isShowPw {
      opacity: 1;
    }
  }

  .form-control {
    padding-right: 28px;
  }
}
