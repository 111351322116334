.CompanyUserSubTab__profileContainer {
  display: flex;
  align-items: center;

  .CompanyAvatar__container {
    display: block;
  }

  .CompanyUserSubTab__profileInfo {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
}
