.RTTableHeadCellSortLabel__sortIcon {
  margin-bottom: 2px;
}

.RTTableHeadCellSortLabel__syncIcon {
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}
