@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.desktop-image-viewer div {
  margin-top: 10px;
}

.ImageViewer__loadButlerBlock {
  height: auto !important;
  @include media-breakpoint-down(md) {
    &.hidePdf {
      .DocumentLabelerContent__fieldsPanelContainer {
        @include media-breakpoint-down(md) {
          width: 100%;
          max-height: calc(100vh - 150px);
        }
      }
    }

    .DocumentLabelerContent__content {
      flex-direction: column;
      width: auto;
    }

    .DocumentLabelerContent__fieldsPanelContainer {
      width: 100%;
    }

    .FieldsPanel__fieldsContainer {
      max-height: calc(100vh - 450px);
    }
  }
}

// .ImageViewer__loadButlerBlock {
//   height: auto;

//   @include media-breakpoint-down(md) {
//     height: calc(100vh - 60px)
//   }

//   &.hidePdf {
//     .DocumentLabelerContent__fieldsPanelContainer {
//       @include media-breakpoint-down(md) {
//         width: 100%;
//         max-height: calc(100vh - 150px);
//       }
//     }
//   }

//   .DocumentLabelerContent__content {
//     @include media-breakpoint-down(md) {
//       flex-direction: column;
//       width: auto;
//     }
//   }

//   .DocumentLabelerContent__documentDisplayerContainer {
//     padding: 0;
//   }

//   .DocumentLabelerContent__fieldsPanelContainer {
//     @include media-breakpoint-down(md) {
//       width: 100%;
//       max-height: calc(100vh - 400px);
//     }
//   }
// }
