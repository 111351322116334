.CommonTab__root {
  font-size: 14px;
  margin-bottom: 8px !important;

  .nav-link {
    padding: 0;
    &.active {
      background-color: #e5e5e5 !important;
    }
  }
}

.CommonTab__labelTabContainer {
  display: flex;
  align-items: center;
  padding: 4px 8px;

  ion-badge {
    margin-left: 8px;
  }
}
