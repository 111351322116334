.CompanyTableEmails__toolbarRoot {
  margin-bottom: 8px;
  margin-left: 8px;
}

.CompanyTableEmails__createButton {
  margin-left: 8px;
}

.CompanyTableEmails__dataGrid {
  .DataGrid__row {
    border-bottom: none !important;
  }
}
