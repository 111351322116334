.RTTablePagination__container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  justify-self: flex-end;
  position: relative;
  padding: 12px;
  z-index: 2;
  border-top: 1px solid #e0e0e0;
}

.RTTablePagination__paginationList {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
}

.RTTablePagination__paginationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  text-align: center;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 12px;
  margin-right: 4px;

  &:hover {
    cursor: pointer;
    background-color: #e5e5e5;
    transition: background-color 0.1s linear;
  }

  &.isActive {
    border-color: var(--ion-color-concord);
    background-color: var(--ion-color-concord);
    color: white;
  }

  &.isThreedot {
    border: none;
    background-color: transparent;
    align-items: flex-end;
  }
}

.RTTablePagination__perPage {
  font-size: 14px;

  label {
    margin-right: 4px;
    color: #64748b;
    font-weight: 600;
  }

  select {
    border: none;
    outline: none;
  }
}
