.DialogTerminalForm__mapView {
  .MapView__containerElement,
  .mapElementContainer {
    height: 300px !important;
  }
}

.TerminalForm__mapContainer {
  .MapView__containerElement {
    height: 300px !important;
  }
}
