.ContainerFilter__container {
  display: inline-flex;
  // border: solid 2px #1090ff;
  padding: 1px;
  border-radius: 12px;

  &.active {
    border-color: var(--ion-color-lightgreen);
  }
}

.ContainerFilter__filterOverlay {
  .szh-menu {
    padding: 12px;
    background-color: #262626;
    color: white;
    opacity: 0.9;
  }
}

.ContainerFilter__wrapFilterItem {
  max-width: 300px;
  // max-height: 300px;
  overflow: auto;
}
