.OrderTrackingStatusCard__container {
  height: 100%;
  padding: 4px;
  border-radius: 4px;
  margin-left: 4px;
  margin-bottom: 2px;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #dbdbdb;
  position: relative;

  .CompanyAvatar__initialName {
    font-size: 10px;
  }

  &.isHighlighted {
    background-color: #cde8ff;
  }
}

.OrderTrackingStatusCard__rightIcons {
  display: flex;
  position: absolute;
  right: 5px;
}

.OrderTrackingStatusCard__loadingOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.85;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 999;
}
