.TextField__formContainer {
  font-size: 14px;
  height: 36px;
  margin: 4px;

  &.disabled {
    opacity: 0.85;
    pointer-events: none;
    cursor: progress;
  }
}

.TextField__input {
  height: 32px;
  font-size: 14px !important;
  border-radius: 4px;
  width: 100% !important;
  border: none !important;
  padding-right: 55px !important;

  &.error {
    // border-color: red;
    border: 1px solid red !important;
  }

  &:hover {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

.TextField__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.TextField__inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TextField__icon {
  font-size: 18px;
  cursor: pointer;
  color: var(--ion-color-concord);
  font-weight: 600;
  margin-left: 8px;

  &.delete {
    color: red;
  }
}

.SectionEmailParsers__countMatched {
  margin-left: 8px;
}

.TextField__wrapInput {
  width: 100%;
  height: 100%;
}
