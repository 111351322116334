$background-color: #f6f6f6;

.PopupReenableLocationPermission__container {
  position: absolute;
  top: 15px;
  left: 100px;
  z-index: 99999;
  max-width: 300px;
  font-size: 15px;
  padding: 16px;
  background: $background-color;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &::before {
    content: '';
    position: absolute;
    left: 18px;
    top: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 9px 9px;
    border-color: transparent transparent $background-color transparent;
    z-index: 9998;
  }

  .PopupReenableLocationPermission__title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
  }

  .PopupReenableLocationPermission__description {
    .PopupReenableLocationPermission__infoIcon {
      margin: 0px 3px;
      margin-bottom: 2px;
      font-size: 18px;
    }
  }

  .PopupReenableLocationPermission__buttons {
    margin-top: 8px;
  }
}
