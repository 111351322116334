.NoteButton__tooltip {
  padding: 0;
  opacity: 1;

  .popover-arrow {
    display: none !important;
  }

  .rc-tooltip-inner {
    background-color: transparent;
    padding: 0;
  }

  .buttonContainer {
    position: relative;

    .badge {
      position: absolute;
    }
  }
}

.NoteButton__overlay {
  max-height: 400px;
  min-width: 250px;
  max-width: 380px;
  overflow: auto;

  .popover-body {
    padding-top: 0;
    padding-bottom: 0;

    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.NoteButton__container {
  position: relative;
  vertical-align: middle;

  .badgeButton {
    position: absolute;
    background: var(--ion-color-danger);
    padding: 0 4px;
    font-size: 10px;
    border-radius: 5px;
    color: white;
    right: 1px;
    top: 0;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
