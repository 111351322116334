.FilterModalPopup__searchBar {
  margin: 16px 0 8px 8px;
}

.FilterModalPopup__container {
  .modal-content {
    max-width: 350px;
  }

  .modal-dialog {
    justify-content: center;
  }

  .modal-body {
    min-height: 400px;
  }
}
