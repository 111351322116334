.DialogLoadPfdViewer__container {
  --width: 800px;
}

.DialogLoadPfdViewer__content {
  padding: 0 !important;
}

.DialogLoadPfdViewer__imageContainer {
  width: 100%;

  div {
    width: 100%;
  }
}
