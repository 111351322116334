.ScheduleCardOnMap__container {
  position: absolute;
  z-index: 99999;
  left: 12px;
  bottom: 30px;
  background-color: white;
  padding: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 14px;

  .loadStatus {
    text-align: right;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin-top: 12px;

    &:hover {
      opacity: 0.875;
    }

    .chip {
      background-color: var(--ion-color-concord);
      padding: 8px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
    }
  }

  .body {
    display: flex;
    align-items: center;

    .progressBarContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .progress-bar {
        width: 80px;
        height: 80px;
        border-radius: 50%;

        .truckName {
          font-size: 16px;
        }

        .percentage {
          font-size: 14px;
          color: var(--ion-color-fleet);
        }
      }
    }

    .loadInfo {
      margin-left: 12px;

      .loadBuyerTerminalName {
        font-size: 16px;
        font-weight: 600;
        padding: 4px 0;
      }

      .loadBuyerName {
        margin-bottom: 8px;
        margin-top: 8px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .footer {
    //margin-top: 16px;
    padding: 16px 0 0;
    //border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    .order {
      vertical-align: middle;
    }
  }
}
