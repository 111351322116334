.ConcordFormStructure__buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ion-button {
    width: 100%;
  }
}
