.KeyValuePairItem__container {
  display: flex;
  align-items: center;
  min-height: 27.5px;

  .itemLabel {
    font-size: 13px;
    margin-right: 4px;
    font-weight: 600;
  }

  .itemValue {
    font-size: 13px;

    &.checkbox {
      height: 14px;
    }

    .dropdownSelector {
      .wrapChildren {
        font-size: 13px;
      }

      .ConcordFormDropdownV2__value-container {
        padding-left: 0 !important;
      }
    }
  }

  .textInputContainer {
    .textInput {
      vertical-align: middle;
      display: inline-block;

      input {
        border: none;
        outline: none;

        &:focus {
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }

    .textIcon {
      vertical-align: middle;
      display: inline-block;
      height: 13px;
      margin-right: 2px;
    }
  }
}
