.SchedulesTable__cell {
  padding: 0 8px !important;
}

.SchedulesTable__row {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: var(--ion-color-medium);
    z-index: 100;
  }

  &.secondary::after {
    background-color: var(--bs-secondary);
  }

  &.info::after {
    background-color: var(--bs-info);
  }

  &.primary::after {
    background-color: var(--bs-primary);
  }

  &.success::after {
    background-color: var(--bs-success);
  }

  &.warning::after {
    background-color: var(--bs-warning);
  }

  &.dark::after {
    background-color: var(--bs-dark);
  }

  &.danger::after {
    background-color: var(--bs-danger);
  }
}

.SchedulesTable__container {
  .sellerTerminalCell {
    .item {
      display: flex;
      align-items: center;

      .text {
        font-size: 13px;
        color: var(--ion-color-medium);
        font-weight: 600;
        margin-right: 6px;
      }
    }
  }
}

.SchedulesTable__confirmation {
  .modal-dialog {
    width: 350px !important;
  }
}

.moreThanOneHourLate {
  background-color: black !important;
  color: red;
}
.moreThan30MinutesLate {
  background-color: red !important;
  color: white;
}
.moreThan15MinutesLate {
  background-color: orange !important;
  color: black;
}
.moreThan5MinutesLate {
  background-color: yellow !important;
  color: black;
}
.between10MinsEarlyTo5MinutesLate {
  background-color: green !important;
  color: black;
}
.earlier10Minutes {
  background-color: white !important;
  color: black;
}

.SchedulesTable__tooltip {
  .tooltip-inner {
    text-align: left;
  }
}

.SchedulesTable__actionButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  ion-button {
    --padding-start: 0px;
    --padding-end: 0px;
  }
}

.SchedulesTable__overlayExtras {
  padding: 0;
  opacity: 1;

  .popover-arrow {
    display: none !important;
  }

  .rc-tooltip-inner {
    background-color: transparent;
    padding: 0;
  }
}

.SchedulesTable__popoverExtras {
  .popover-body {
    font-size: 13px;
  }
}

.SchedulesTable_sellerCells {
  height: auto !important;
}
