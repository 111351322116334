.dashboard-base-view {
  min-height: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background: #f9f9f9;
}
.dashboard-base-view-row {
  margin-top: 10px;
}
.dashboard-base-view-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 105px;
  margin-top: 12px !important;
}
.dashboard-base-view-lg-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 275px;
  margin-top: 16px !important;

  &:last-child {
    margin-bottom: 16px;
  }
}

.feature-card {
  border: 1px solid #cecece;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: white;
  border-radius: 10px;
}
.clickable-card {
  cursor: pointer;
}
.clickable-card:hover {
  background: #d5e4ff;
}
.feature-card-header {
  top: 0px;
  height: 25px;
  min-width: 100%;
  // background-color: #3880ff;
  // background: #3880ff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: -1px;
  padding-left: 7px;
  padding-top: 7px;
}
.feature-card-body {
  padding: 15px;
}
.feature-card-label {
  font-size: 1.6rem;
  text-align: left;
}
.feature-card-font {
  font-size: 3rem;
  text-align: center;
  color: #3880ff;
}
.feature-card-footer {
  width: 100%;
  height: 10%;
  text-align: right;
  border-top: 1px solid #cecece;
  padding-right: 5px;
  padding-top: 5px;
  bottom: 0px;
}

.graph-card {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: white;
  border-radius: 10px;
}
.graph-card-header {
  top: 0px;
  height: 25px;
  background-color: #3880ff;
  background: #3880ff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: -1px;
}
.graph-card-label {
  font-size: 2.2rem;
  text-align: left;
  margin-top: 15px;
  padding-left: 15px;
}
.graph-card-footer {
  width: 100%;
  height: 10%;
  text-align: right;
  border-top: 1px solid #cecece;
  padding-right: 5px;
  padding-top: 5px;
  bottom: 0px;
}
