@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

// Removed the borders from the containing div and put styled borders on the children elements inside
// Rules for the input field while it's empty

.searchBar-component {
  border: 0px solid transparent !important;

  button {
    background-color: #3880ff !important;
    border-left: solid 1px #3880ff !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 38px;
  }
  .react-search-field-input {
    border-bottom: 1px solid #cecece !important;
    border-left: 1px solid #cecece !important;
    border-top: 1px solid #cecece !important;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    max-width: 30rem;

    &:focus {
      border: 2px solid #3880ff !important;
    }
    &::placeholder {
      color: #bebebe !important;
    }
  }
  svg {
    fill: white !important;
  }
}

// Rules for when input field has content inside for both focus on the input and not focused
.searchBar-component-has-text {
  border: 0px solid transparent !important;
  button {
    background-color: #30bb50 !important;
    border-left: solid 1px #30bb50 !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 38px;
  }
  .react-search-field-input {
    border: solid 2px #30bb50 !important;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    max-width: 30rem;
    color: black !important;

    &:focus {
      border: 2px solid #3880ff !important;
    }
    &:focus + button {
      background-color: #3880ff !important;
      border-left: solid 1px #3880ff !important;
    }
  }
  svg {
    fill: white !important;
  }
  @media (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    min-width: 230px;
  }
}

.CompanyFilterHeader {
  margin-right: 8px;
  height: 40px !important;
  // width: 50%;

  @include media-breakpoint-down(md) {
    width: 60%;
  }

  button {
    height: 40px !important;
  }

  input {
    height: 40px !important;
    width: 100% !important;
    max-width: none !important;
  }
}

.CompanyHomeContainer {
  button {
    height: 40px !important;
  }
  input {
    max-width: 60rem !important;
    height: 40px !important;
  }
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}
