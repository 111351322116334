.SellerTerminalProdCard__container {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-bottom: 6px;
  cursor: pointer;

  &.isSelected {
    border-color: #f15523;
    background-color: transparent !important;
    box-shadow: rgba(246, 123, 123, 0.15) 1.95px 1.95px 2.6px;
  }
}
