.CompanyCard__container {
  width: 100%;

  .arrowIcon {
    transition: all 0.115s ease-in-out;
    transform: rotate(90deg);

    &.isExpanded {
      transform: rotate(-90deg);
    }
  }

  .form-check-input {
    width: 14px;
    height: 14px;
  }
}
