.GraphChartOrderTrackingDetailDialog_root {
  .modal-wrapper {
    height: 70%;
    width: 60%;
  }

  .optionsHeader__container {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .stackCheckbox {
      margin-left: 12px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  .schedulesDataGrid {
    .DataGridCell__container {
      background-color: transparent !important;
    }

    .DataGird__wrapExpandIcon {
      color: black !important;
    }

    .scheduleForm {
      color: black;
      padding: 8px;
    }
  }
}
