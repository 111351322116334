.ColumnsButton__container {
  .DataSortTool__reorderWrap {
    width: 280px;
    border: 1px solid #dedede;
    z-index: 999;
  }

  .DataSortTool__reorderCheckbox {
    border: 1px solid rgba(0, 0, 0, 0.15);
    min-width: 250px;
    background-color: white;
    display: block;
  }

  .DataSorTool__savingData {
    width: 278px;
  }
}
