@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

.DriverPersonalDetailsTab__userNameContainer {
  display: flex;
  align-items: flex-end;

  .ConcordFormLayout__container {
    width: 80%;
    margin-right: 8px;
  }
}

.DialogWorkerForm__container {
  .modal-dialog {
    min-width: 650px;

    @include media-breakpoint-down(md) {
      min-width: 350px;
    }
  }
}
