.RTHorizontalScroll__container {
  position: relative;
  overflow: auto;

  .RTHorizontalScroll__forwardHorizontalScroll,
  .RTHorizontalScroll__backHorizontalScroll {
    z-index: 999;
    top: 0;
    opacity: 0.4;

    &:hover {
      opacity: 0.85;
    }
  }

  .RTHorizontalScroll__forwardHorizontalScroll {
    position: absolute;
    right: 0;
  }

  .RTHorizontalScroll__backHorizontalScroll {
    position: absolute;
    left: 0;
  }
}
