.ConcordFormCheckbox__container {
  display: flex;
  align-items: center;
}

.ConcordFormCheckbox__label {
  margin-right: 8px;
}

.Storybook__ConcordFormCheckbox__root {
  .ConcordFormCheckbox__label {
    color: blueviolet;
  }
}
