.RTEditCellSingleSelectField__container {
  cursor: auto;
  .ConcordFormDropdownV2__control {
    border: 0;
    background-color: transparent !important;
    height: 100% !important;
    box-shadow: none;
  }

  .ConcordFormDropdownV2__value-container {
    padding-left: 0 !important;
  }
}
