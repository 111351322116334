.CompanyMenuItem__container {
  display: flex;
  padding: 8px;
  min-height: 120px;
  border-radius: 12px;
  position: relative;

  &:hover {
    background-color: #d6ecff;
    cursor: pointer;
  }
}

.CompanyMenuItem__icon {
  ion-icon {
    color: var(--ion-color-concord);
    font-size: 20px;
  }
}

.CompanyMenuItem__titleContainer {
  margin-left: 16px;
}

.CompanyMenuItem__label {
  font-size: 16px;
  color: rgb(46, 44, 52);
}

.CompanyMenuItem__description {
  font-size: 14px;
  color: rgb(132, 129, 138);
}

.CompanyMenuItem__plusButton {
  position: absolute;
  top: 0;
  right: 0;
}
