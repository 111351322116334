.CustomColorOption__optionContainer {
  justify-content: space-between;

  &.isCustomColor {
    background-color: var(--bs-blue);
    color: white;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: white;
      border: 1px solid var(--bs-blue);
    }

    .CustomColorOption__optionLabel {
      width: 100%;
    }
  }
}

.CustomColorMenu__container {
  min-width: 390px;
}

.CustomColorMenuList__container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.ColorSelector__colorPicker {
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 0;
      }
    }
  }

  .sketch-picker {
    width: auto !important;
  }
}
