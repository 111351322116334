.PaymentMethodsContainer__formContainer {
  max-width: 650px;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 8px;
}

.PaymentMethodsContainer__emailForm {
  border: 1px solid #e5e5e5;
}

.PaymentMethodsContainer__listContainer {
  margin-top: 12px;
}

.PaymentMethodsContainer__toggle {
  &.isHiddenLabel {
    .headerContainer {
      display: none;
    }
  }
}
