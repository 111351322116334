.DriverFleetModal__root {
  --height: 400px;
  --width: 300px;
}

.DriverFleetModal__list {
  min-height: 250px;
  max-height: 250px;
  overflow: auto;
}
