.DownloadCard__root {
  &.card {
    border: none;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5e5;
    }
  }

  .DownloadCard__body {
    // color: var(--ion-color-crimson)
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .DownloadCard__leftSection {
      display: flex;
      align-items: center;

      .DownloadCard__icon {
        font-size: 20px;
      }

      .DownloadCard__fileName {
        font-size: 14px;
        margin-left: 8px;
        display: inline-block;
        width: 180px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
}
