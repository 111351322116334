.SensorCard__container {
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  max-height: 135px;

  &.isLocation {
    max-height: 300px;

    .MapView__containerElement {
      height: 150px;
    }
  }

  .headerContainer {
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      height: 24px;
    }

    .titleContainer {
      padding: 4px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;

      .timestamp {
        font-size: 14px;
      }
    }
  }

  .bodyContainer {
    margin-top: 16px;

    .valueContainer {
      display: flex;
      align-items: center;
      .value {
        font-size: 24px;
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        font-weight: 600;
        display: flex;
        align-items: center;
      }

      .unit {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        opacity: 0.75;
      }
    }

    .timestamp {
      margin-left: 8px;
      font-size: 14px;
    }
  }
}
