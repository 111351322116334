
.PaymentsContainer_body {
}

.PaymentsContainer_toolbar {
	margin: 10px;
}

.PaymentsContainer_bodyToolbar {
	margin: 0 8px 8px;
  display: flex;
  align-items: center;
}
