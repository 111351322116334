.WeightCard__container {
  padding: 8px;
  border-radius: 4px;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
}

.WeightCard__rank {
  top: -8px;
  left: -6px;
  position: absolute;
  padding: 4px !important;
}
