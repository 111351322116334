.EarningCodeForm__dropdown {
  .ConcordFormDropdownV2__menu {
    max-height: 140px;
  }
}

.EarningCodeForm__earningCodeDropdown {
  .ConcordFormDropdownV2__menu {
    max-height: 120px;
  }
}
