.RTTableFooterActionButtons__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100% - 10px);

  .DropdownWithCustomChildren__container {
    width: initial;
  }

  .ConcordFormDropdownV2__value {
    padding-left: 0;
  }

  .ConcordFormDropdownV2__value-container {
    padding-left: 0 !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.RTTableFooterActionButtons__container::-webkit-scrollbar {
  display: none;
}

.RTTableFooterActionButtons__button {
  font-size: 13px !important;
  border-radius: 8px !important;
}

.RTTableFooterActionButtons__dropdown {
  margin-left: 2px;
}
